import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import ProductInformation from './components/ProductInformation';
import Suggestions from '../../components/Suggestions';
import Footer from '../../components/Footer';

import { Container, Content, Spinner } from './styles';

const Product = () => {
  let { id } = useParams();
  const history = useHistory();
  const [productInfo, setProductInfo] = useState({});
  const [chosenColor, setChosenColor] = useState('');
  const [chosenSize, setChosenSize] = useState('');
  const [sizesAvailable, setSizesAvailable] = useState([]);
  const [externalIdAvailable, setExternalIdAvailable] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchProduct() {
    setLoading(true);

    api
      .get(`/products/${id}`)
      .then((res) => {
        setProductInfo(res.data);
        fillInitialInformations(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response?.data?.message || error.toString());
        history.goBack();
      });
  }

  function fillInitialInformations(product) {
    if (product.color) {
      setChosenColor(product.color);
    }

    if (product.size) {
      setChosenSize(product.size);
      calculateSizes(product);
    }
  }

  const calculateSizes = (product) => {
    const arraySize = [];
    arraySize.push(product.size);
    const arrayExternalId = [];
    arrayExternalId.push(product.external_id);

    if (product?.variations) {
      for (let productVariation of product.variations) {
        if (
          productVariation.color === product.color &&
          !arrayExternalId.includes(productVariation.external_id)
        ) {
          arraySize.push(productVariation.size);
          arrayExternalId.push(productVariation.external_id);
        }
      }
    }

    setSizesAvailable(arraySize);
    setExternalIdAvailable(arrayExternalId);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchProduct();
  }, [id]);

  const renderProduct = () => {
    if (loading) {
      return <Spinner />;
    }

    return (
      <ProductInformation
        externalId={id}
        productInfo={productInfo}
        chosenColor={chosenColor}
        setChosenColor={setChosenColor}
        chosenSize={chosenSize}
        setChosenSize={setChosenSize}
        sizesAvailable={sizesAvailable}
        externalIdAvailable={externalIdAvailable}
      />
    );
  };

  return (
    <Container>
      <Header />
      <Content>{renderProduct()}</Content>
      {/* <Suggestions /> */}
      <Footer />
    </Container>
  );
};

export default Product;
