import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import { toast } from 'react-toastify';
import Input from '../../components/Input';
import ButtonEditList from './components/ButtonEditList';
import Categories from './components/Categories';
import LoadModal from '../../components/LoadModal';

import pencil from '../../assets/input/pencil.png';

import { Container, Title, Row, Form, Tooltip } from './styles';

const CreateListModal = ({ fetchList, setVisible, selectedList }) => {
  const modalRef = useRef();
  const history = useHistory();
  const [name, setName] = useState(selectedList.name);
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [description, setDescription] = useState(selectedList.description);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [listIsPublic, setListIsPublic] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (!modalRef.current.contains(e.target)) {
      const itemSelected = e.target.matches(
        'div.ant-select-item-option-content',
      );

      if (!itemSelected) {
        setVisible(false);
      }

      return;
    }
  };

  async function fetchCategories() {
    await api.get('/categories').then((res) => {
      setCategories(res.data);
    });
  }

  async function handleSubmit() {
    if (!name) {
      setErrorMessage('Digite corretamente o nome da lista');
      return;
    }

    if (!description) {
      setErrorMessage('Digite a descrição da lista');
      return;
    }

    setErrorMessage('');
    setShowLoadModal(true);

    await api
      .put(`/lists/${selectedList.id}`, {
        name,
        description,
        category_id: selectedList.category_id,
        is_added: selectedList.is_added,
        is_public: listIsPublic,
      })
      .then(() => {
        fetchList();
        setShowLoadModal(false);
        setVisible(false);
        toast.info('Lista editada!');
      })
      .catch((error) => {
        handleError(error);
      });
  }

  const handleError = async (error) => {
    let message;
    if (error.response?.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    setShowLoadModal(false);
    setErrorMessage(message);
  };

  useEffect(() => {
    setListIsPublic(selectedList.is_public);
    fetchCategories();
  }, []);

  const renderErrorMessage = () => {
    if (errorMessage) {
      return <Tooltip>{errorMessage}</Tooltip>;
    }
  };

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal />;
    }
  };

  return (
    <Container ref={modalRef}>
      <Title>Edite sua lista</Title>
      <Row>
        {/* <Card /> */}
        <Form>
          <label>Nome</label>
          <Input
            value={name}
            onInput={(event) => setName(event.target.value)}
            iconRight={pencil}
          />
          <label style={{ marginTop: '10px' }}>Descrição</label>
          <Input
            value={description}
            onInput={(event) => setDescription(event.target.value)}
            iconRight={pencil}
          />
          <label style={{ marginTop: '10px', marginBottom: '-20px' }}>
            Categoria
          </label>
          <Categories
            selectedList={selectedList}
            categories={categories}
            setCategoryId={setCategoryId}
          />
          <label style={{ marginTop: '10px' }}>Tornar a lista pública?</label>
          <br />
          <div
            onChange={(event) =>
              setListIsPublic(event.target.value === 'true' ? true : false)
            }
          >
            <input
              type="radio"
              id="true"
              name="list-status"
              value="true"
              defaultChecked={selectedList.is_public}
            />
            <label for="true">Sim</label>
            <br />
            <input
              type="radio"
              id="false"
              name="list-status"
              value="false"
              defaultChecked={!selectedList.is_public}
            />
            <label for="false">Não</label>
          </div>
          <br />
        </Form>
      </Row>
      {renderErrorMessage()}
      <ButtonEditList onClick={() => handleSubmit()} />
      {renderLoadModal()}
    </Container>
  );
};

export default CreateListModal;
