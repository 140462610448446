import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  margin-top: 140px;

  @media (max-width: 1096px) {
    margin-top: 180px;
  }

  @media (max-width: 540px) {
    margin-top: 80px;
  }
`;

export const Content = styled.div`
  max-width: 1336px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  > img {
    height: 350px;
  }

  @media (max-width: 52.5rem) {
    > img {
      display: none;
    }
  }
`;

export const Column = styled.div`
  margin-left: 40px;
  display: flex;
  flex-direction: column;

  > p:nth-of-type(1) {
    font-family: 'Izmir-Bold';
    font-size: 3.5rem;
    color: #2f78fe;
  }

  > p:nth-of-type(2) {
    font-family: 'Izmir-Bold';
    font-size: 3.5rem;
    color: #2f78fe;
  }

  > p:nth-of-type(3) {
    font-family: 'Izmir-Bold';
    font-size: 3.5rem;
    color: #2f78fe;

  }

  > p:nth-of-type(4) {
    font-family: 'Izmir-Regular';
    font-size: 2.5rem;
    color: #727272;
    margin-top: 20px;
    margin-top: -30px;
  }

  > p:nth-of-type(5) {
    font-family: 'Izmir-Regular';
    font-size: 2.5rem;
    color: #727272;
    margin-top: 60px;
  }

  @media (max-width: 58.75rem) {
    > p:nth-of-type(5) {
      margin-top: 30px;
    }
  }

  @media (max-width: 52.5rem) {
    margin-left: 0px;
    text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  > div:nth-of-type(1) {
    margin-right: 20px;
  }

  @media (max-width: 58.75rem) {
    flex-direction: column;

    > div:nth-of-type(1) {
      margin-right: 0px;
    }

    > div:nth-of-type(2) {
      margin-top: 10px;
    }
  }

  @media (max-width: 52.5rem) {
    align-items: center;
  }
`;
