import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import api from '../../../../services/api';

import SideMenu from '../SideMenu';
import Input from '../Input';
import FilterBox from '../FilterBox';

import menu from '../../../../assets/header/menu.png';
import cart from '../../../../assets/header/cart.png';
import favorites from '../../../../assets/header/favorites.png';
import logo from '../../../../assets/header/logo.png';
import notifications from '../../../../assets/header/notifications.png';
import arrow_down from '../../../../assets/header/arrow_down.png';
import UserNotLoggedModalWrap from '../../../../components/UserNotLoggedModalWrap';
import UserNotLoggedMessage from '../../../../components/UserNotLoggedModalWrap/UserNotLoggedMessage';
import ModalWrap from '../../../../components/ModalWrap';

import {
  Container,
  IconMenu,
  Logo,
  Column,
  Text,
  Options,
  Item,
  SideBar,
  LinkPage,
  Modal
} from './styles';

const Content = () => {
  const history = useHistory();
  const user = useSelector((state) => state.User);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [categories, setCategories] = useState([]);
  const [moreCategories, setMoreCategories] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [isModalCategories, setIsModalCategories] = useState(false);

  function handleOnClick(filter) {
    if (filter.length !== currentFilter.length) {
      setCurrentFilter(filter);
      if (!showFilter) {
        setShowFilter(true);
      }
    } else {
      setCurrentFilter([]);
      setShowFilter(false);
    }
  }

  async function fetchCategories() {
    await api
      .get('/categories/highlights')
      .then((res) => {
        setCategories(res.data);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function fetchMoreCategories() {
    await api
      .get('/categories/not-highlights')
      .then((res) => {
        setMoreCategories(res.data);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchMoreCategories();
  }, []);

  const priceRange = [
    'Todos os preços',
    'Até R$ 60',
    'R$ 60 a R$ 180',
    'R$ 180 a R$ 300',
    'R$ 300 a R$ 500',
    'Mais de R$ 500',
  ];

  const sortBy = [
    'Só produtos',
    'Só listas',
    'Produtos e Listas',
    'Destaques',
    'Preço: Baixo para Alto',
    'Preço: Alto para Baixo',
    'Mais Recentes',
    'Mais vendidos',
    'Em Oferta',
  ];

  const brand = [
    'Todas',
    'Nike Sports',
    'Adidas',
    'The North Face',
    'Olympikus',
  ];

  const freight = ['Todos', 'Frete Grátis'];

  const renderTextForLoginOrSignUp = () => {
    if (user.accessToken.trim() === '' || user.accessToken.trim() === '') {
      return (
        <p>
          <LinkPage to="/login">Entre</LinkPage> ou{' '}
          <LinkPage to="/criar-conta">cadastre-se</LinkPage>
        </p>
      );
    }
  };

  const renderCategories = () => {
    const listCategories = categories.map((item) => (
      <Item onClick={() => history.push(`/listas-da-categoria/${item.id}`)}>
        <div>{item.name}</div>
      </Item>
    ));

    return listCategories;
  };

  function checkUserLoggedModal() {
    if (isModal) {
      return (
        <UserNotLoggedModalWrap toggleModal={() => setIsModal(false)}>
          <UserNotLoggedMessage />
        </UserNotLoggedModalWrap>
      );
    }
  }

  function renderModal() {
    if (isModalCategories) {
      return (
        <ModalWrap toggleModal={() => setIsModalCategories(false)}>
          <Modal>
            {moreCategories.map((item) => (
              <h3 onClick={() => history.push(`/listas-da-categoria/${item.id}`)}>
                {item.name}
              </h3>
            ))}
          </Modal>
        </ModalWrap>
      );
    }
  }

  return (
    <Container>
      <div>
        <IconMenu onClick={() => setShowSideMenu(!showSideMenu)} src={menu} />
        <Logo src={logo} onClick={() => history.push('/')} />
        <Input />
        <Column>
          <Text>Bem-vindo(a)</Text>
          {renderTextForLoginOrSignUp()}
        </Column>
        <Options>
          {user.accessToken ? (
            <LinkPage to="/favoritos">
              <img src={favorites} />
            </LinkPage>
          ) : (
            <LinkPage onClick={() => setIsModal(true)}>
              <img src={favorites} />
            </LinkPage>
          )}
          <LinkPage to="/carrinho">
            <img src={cart} />
          </LinkPage>
          {/*<LinkPage to="/notifications">
            <img src={notifications} />
          </LinkPage>*/}
        </Options>
      </div>
      <div>
        {/* <Item onClick={() => handleOnClick(priceRange)}>
          <p>Faixa de preço</p>
          <img src={arrow_down} />
        </Item> */}
        {renderCategories()}
        {/* <Item onClick={() => handleOnClick(sortBy)}>
          <p>Classificar por:</p>
          <img src={arrow_down} />
        </Item>
        <Item onClick={() => handleOnClick(brand)}>
          <p>Marca</p>
          <img src={arrow_down} />
        </Item>
        <Item onClick={() => handleOnClick(freight)}>
          <p>Frete</p>
          <img src={arrow_down} />
        </Item> */}
      </div>
      <p onClick={() => setIsModalCategories(true)} className="moreCategories" style={{display: 'table', margin: '10px auto 0 auto'}}>Mais categorias</p>
      <SideMenu showSideMenu={showSideMenu} setShowSideMenu={setShowSideMenu} />
      <FilterBox
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        data={currentFilter}
      />
      {checkUserLoggedModal()}
      {renderModal()}
    </Container>
  );
};

export default Content;
