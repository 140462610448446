import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 440px;
  height: 40px;
  background-color: #f5f5f5;
  border-radius: 6px;
  margin-top: 30px;
  margin-left: 20px;
  padding-right: 20px;

  @media (max-width: 72.75rem) {
    width: 340px;
  }

  @media (max-width: 33.75rem) {
    width: 280px;
  }

  @media (max-width: 29.25rem) {
    width: 230px;
  }

  @media (max-width: 27.5rem) {
    margin-left: 0px;
  }
`;

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #fff;
`;

export const Image = styled.img`
  height: 20px;
  margin: 0 auto;
`;

export const TextInput = styled.input`
  margin-left: 10px;
  width: 100%;
  height: 80%;
  font-size: 2.6rem;
  border-style: none;
  outline: none;
  background-color: #f5f5f5;
  font-family: 'Izmir-Regular';
  color: #c5c5c5;
  &::placeholder {
    color: #c5c5c5;
  }
`;
