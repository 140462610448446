import Card from './Card';

import { Container, Divider } from './styles';

const ListCardVertical = ({ offer = false, data }) => {
  const renderProducts = () => {
    const products = data.map((product) => (
      <>
        <Card data={product} offer={offer} />
        <Divider />
      </>
    ));

    return products;
  };

  return <Container>{renderProducts()}</Container>;
};

export default ListCardVertical;
