import styled from 'styled-components';
import { FaHeart } from 'react-icons/fa';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`;

export const Clothe = styled.div`
  position: relative;
  height: 180px;
  width: 180px;
  padding: 10px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 12px 0px lightgrey;

  > div:nth-of-type(1) {
    /* > p:nth-of-type(1) {
      font-family: 'Izmir-Bold';
      font-size: 2rem;
    } */

    > p:nth-of-type(1) {
      font-family: 'Izmir-Black';
      font-size: 3rem;
      cursor: pointer;
    }

    > p:nth-of-type(2) {
      font-family: 'Izmir-Medium';
      font-size: 1.6rem;
      color: #707070;
      margin-top: 4px;
    }
  }

  > img {
    position: absolute;
    height: 140px;
    top: 110px;
    right: -10px;
  }

  @media (max-width: 68.5rem) {
    height: 220px;
    width: 190px;

    > img {
      height: 120px;
      top: 124px;
      right: -4px;
    }
  }

  @media (max-width: 51.25rem) {
    height: 200px;
    width: 170px;

    > img {
      height: 100px;
      top: 110px;
      right: -4px;
    }
  }

  @media (max-width: 34rem) {
    height: 190px;
    width: 160px;

    > img {
      height: 90px;
      top: 120px;
    }
  }
`;

export const SelectedFavoriteHeart = styled(FaHeart)`
  position: absolute;
  top: 20px;
  right: 20px;
  color: #06e406;
  font-size: 4rem;

  @media (max-width: 34rem) {
    right: 10px;
  }
`;

export const AddButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  bottom: -10px;
  left: -8px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: ${(props) => {
    if (props.userHasAdded) {
      return '#b1b1b1';
    }
    return '#2f78fe';
  }};
  cursor: pointer;

  > div {
    color: #fff;
    font-size: 6rem;
  }

  @media (max-width: 68.5rem) {
    height: 70px;
    width: 70px;
  }

  @media (max-width: 34rem) {
    height: 60px;
    width: 60px;
  }
`;

export const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: 0;
  background-color: #fff;
  color: #1e6dff;
  font-size: 3rem;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  border-color: #1e6dff;
`;

export const ShareButton = styled.button`
  position: absolute;
  bottom: -80px;
  left: 20px;
  padding: 14px 20px 14px 20px;
  border-radius: 6px;
  background-color: #2f78fe;
  font-size: 2rem;
  font-family: 'Izmir-Bold';
  color: #fff;
  border: 0;
  outline: 0;
`;

export const ButtonShareOptions = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: -140px;
  left: 20px;

  > button {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #b1b1b1;
    margin: 0px 4px;
    border: 0;
    outline: 0;

    > img {
      display: block;
      height: 20px;
      margin: 8px auto;
    }
  }

  > button:nth-of-type(1) {
    background-color: #06e406;
  }
`;

export const AddBtn = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 40px;
  left: 50px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #2f78fe;
  outline: 0;
  border: 0;

  > p {
    color: #fff;
    font-size: 4rem;
    margin-top: 6px;
  }

  > span {
    position: absolute;
    bottom: -28px;
    left: 0px;
    font-family: 'Poppins-Medium';
    font-size: 1.2rem;
    color: #7a7777;
  }

  @media (max-width: 68.5rem) {
    height: 70px;
    width: 70px;
  }

  @media (max-width: 34rem) {
    height: 60px;
    width: 60px;
  }
`;

export const RemoveButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 40px;
  left: 100px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #c5c5c5;
  outline: 0;
  border: 0;

  > p {
    color: #fff;
    font-size: 4rem;
    margin-top: 6px;
  }

  > span {
    position: absolute;
    bottom: -28px;
    left: 0px;
    font-family: 'Poppins-Medium';
    font-size: 1.2rem;
    color: #7a7777;
  }

  @media (max-width: 68.5rem) {
    height: 70px;
    width: 70px;
  }

  @media (max-width: 34rem) {
    height: 60px;
    width: 60px;
  }
`;
