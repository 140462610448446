import { useHistory } from 'react-router-dom';

import { Container, List, Item } from './styles';

const Color = ({ productInfo, setChosenColor, chosenColor }) => {
  const history = useHistory();

  const handleClickItem = (item) => {
    history.push(`/produto/${item.external_id}`);
  };

  const renderListSize = () => {
    if (productInfo?.variations) {
      const listSizeItens = productInfo.variations.map((item) => (
        <Item onClick={() => handleClickItem(item)}>
          <img src={item.image_url} alt="Foto da variação do produto" />
        </Item>
      ));

      return <List>{listSizeItens}</List>;
    }
  };

  return (
    chosenColor !== '' && (
      <Container>
        <p>
          <span>Cor selecionada: {chosenColor}</span>
        </p>
        {renderListSize()}
      </Container>
    )
  );
};

export default Color;
