import styled from 'styled-components';

export const Container = styled.div`
  > p {
    font-family: 'Izmir-Medium';
    color: #707070;
    font-size: 1.8rem;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  > button:nth-of-type(1) {
    margin-right: 5px;
  }

  > button:nth-of-type(2) {
    margin-right: 5px;
  }

  @media (max-width: 32.5rem) {
    > button {
      height: 34px;
      width: 34px;
    }
  }

  @media (max-width: 26.875rem) {
    > button {
      height: 28px;
      width: 28px;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-color: ${(props) => (props.chosenSize ? '#1E6DFF' : '#c5c5c5')};
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  font-size: 2.5rem;
  color: ${(props) => (props.chosenSize ? '#1E6DFF' : '#7a7777')};
  outline: 0;
  background-color: #fff;
`;
