import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../../../services/api';

import Card from './components/Card';
import CreateListModal from '../../../../components/CreateListModal';
import UserNotLoggedModalWrap from '../../../../components/UserNotLoggedModalWrap';
import UserNotLoggedMessage from '../../../../components/UserNotLoggedModalWrap/UserNotLoggedMessage';

import { Container, Row, Informations, Button } from './styles';

const ListDetails = ({
  data,
  userHasAdded,
  listId,
  fetchRebuildList,
  handleRemoveProduct,
  listDetails,
}) => {
  const user = useSelector((state) => state.User);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showCreateListModal, setShowCreateListModal] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const handleAddListToCart = async () => {
    // dispatch({
    //   type: 'listz-web/user/add-product-cart',
    //   payload: data,
    // });

    // history.push('/carrinho');

    const handleError = async (error) => {
      let message;
      if (error.response?.data?.message) {
        message = error.response.data.message;
      } else {
        message = error.toString();
      }

      toast.error(message);
    };

    const productArr = {
      name: listDetails.name,
      external_id: data.external_id,
      quantity: 1,
      unit_price: 0,
      item_url: data.image_url || '',
      image_url: data.image_url || '',
      is_available: data.is_available || false,
    };

    await api
      .post(`/items`, { products: [productArr] })
      .then((res) => {
        toast.success('Item adicionado ao carrinho!');
      })
      .catch((err) => {
        handleError(err);
      });
  };

  function renderCreateListModal() {
    if (showCreateListModal) {
      return <CreateListModal setVisible={setShowCreateListModal} />;
    }
  }

  const renderColors = () => {
    if (data.color) {
      if (data.color.includes(',')) {
        return <p>Cores: {data.color}</p>;
      }
      return <p>Cor: {data.color}</p>;
    }
  };

  const renderSize = () => {
    if (data.size) {
      if (data.size.includes(',')) {
        return <p>Tamanhos: {data.size}</p>;
      }
      return <p>Tamanho: {data.size}</p>;
    }
  };

  const checkUserLoggedCart = () => {
    if (user.accessToken) {
      setShowCreateListModal(true);
    } else {
      setIsModal(true);
    }
  };

  const checkUserLoggedAddList = (external_id) => {
    if (user.accessToken) {
      history.push(`/escolha-uma-lista/${external_id}`);
    } else {
      setIsModal(true);
    }
  };

  const checkUserLoggedFavorite = () => {
    if (user.accessToken) {
      return;
    } else {
      setIsModal(true);
    }
  };

  function checkUserLoggedModal() {
    if (isModal) {
      return (
        <UserNotLoggedModalWrap toggleModal={() => setIsModal(false)}>
          <UserNotLoggedMessage />
        </UserNotLoggedModalWrap>
      );
    }
  }

  const renderButtons = () => {
    if (data?.is_available) {
      return (
        <>
          <Button
            onClick={() => handleAddListToCart()}
            text="Adicionar ao carrinho"
          />
          <Button
            style={{ marginTop: '20px' }}
            onClick={() => checkUserLoggedAddList(data.external_id)}
            text="Adicionar a uma lista"
          />
        </>
      );
    }
  };

  const renderRemoveButton = () => {
    if (userHasAdded) {
      return (
        <Button
          style={{ marginTop: '20px' }}
          onClick={() => handleRemoveProduct(listId, data.id)}
          text="Remover produto da lista"
        />
      );
    }
  };

  return (
    <Container>
      <Row>
        <Informations>
          <p onClick={() => history.push(`/produto/${data.external_id}`)}>
            {data.title}
          </p>
          {renderColors()}
          {renderSize()}
          {renderButtons()}
          {renderRemoveButton()}
        </Informations>
        <Card
          data={data}
          listDetails={listDetails}
          setShowCreateListModal={setShowCreateListModal}
          userHasAdded={userHasAdded}
          listId={listId}
          fetchRebuildList={fetchRebuildList}
          checkUserLoggedAddList={checkUserLoggedAddList}
          checkUserLoggedFavorite={checkUserLoggedFavorite}
        />
      </Row>
      {renderCreateListModal()}
      {checkUserLoggedModal()}
    </Container>
  );
};

export default ListDetails;
