import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 10px;
  border-radius: 6px;
  background-color: #fff;
  width: 160px;
  height: 40px;

  .ant-select {
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    outline: 0;
    font-family: 'Izmir-Regular';
    width: 100%;
    font-size: 1.4rem;
    color: #4a4747;
  }

  .ant-select-selector {
    border: 0 !important;
    border-radius: 40px !important;
    box-shadow: 0 !important;
  }

  .ant-select-selection-item {
    margin-top: 5px;
  }

  .ant-select-arrow {
    color: #07e407;
    font-size: 1.6rem;
    width: 30px;
    margin-top: -3px;
  }
`;
