import Combobox from './components/Combobox';
import Input from '../Input';

import { Container, Content } from './styles';

const Search = ({ setCombobox }) => {
  return (
    <Container>
      <Content>
        <div>
          <p>Minhas Listas</p>
          <Combobox setCombobox={setCombobox} />
        </div>
        {/* <Input /> */}
      </Content>
    </Container>
  );
};

export default Search;
