import { useState, useCallback, useRef } from 'react';
import Card from './Card';
import { useSelector } from 'react-redux';
import useListSearch from './useListSearch';
import loadingGif from '../../assets/search/loading.gif';
import { LoadingOutlined } from '@ant-design/icons';

import { Container, ContainerNotFound } from './styles';

const ListCardVertical = ({ offer = false }) => {
  const searchResults = useSelector((state) => state.Search.search);
  const [pageNumber, setPageNumber] = useState(1);

  const { lists, hasMore, loading, error } = useListSearch(
    searchResults[0],
    pageNumber,
  );

  const searchProducts = lists;

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  const renderCards = () => {
    return searchProducts.map((product, index) => {
      if (product.price > 0) {
        if (searchProducts.length === index + 1) {
          return (
            <Card
              lastBookElementRef={lastBookElementRef}
              key={index}
              product={product}
            />
          );
        } else {
          return <Card key={index} product={product} />;
        }
      }
    });
  };

  return (
    <>
      {searchProducts?.length !== 0 ? (
        <Container>
          {renderCards()}
          {loading && <img src={loadingGif} width="300" />}
          {error && <h1>Não foi possível carregar os produtos</h1>}
        </Container>
      ) : (
        <ContainerNotFound>
          <span>
            <h1>{loading ? <LoadingOutlined /> : 'Nenhum produto encontrado'}</h1>
          </span>
        </ContainerNotFound>
      )}
    </>
  );
};

export default ListCardVertical;
