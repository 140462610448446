import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #2f78fe;
  padding: 20px;

  height: 200px;

  > img:nth-of-type(1) {
    position: absolute;
    left: 0px;
  }

  > img:nth-of-type(2) {
    position: absolute;
    right: 0px;
  }

  @media (max-width: 46.625rem) {
    > img {
      display: none;
    }
  }

  @media (max-width: 32.5rem) {
    height: 120px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    height: 30px;
  }

  > p {
    font-family: 'Izmir-Medium';
    font-size: 3.5rem;
    color: #fff;
    margin: 10px;
  }
`;
