import { Container, Row, Button } from './styles';

const ButtonCreateList = (props) => {
  return (
    <Container {...props}>
      <Row>
        <Button>+</Button>
        <span>Criar Lista</span>
      </Row>
    </Container>
  );
};

export default ButtonCreateList;
