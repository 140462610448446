import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../../services/api';
import FavoriteIcon from '../../../../components/Favorite';

import copyToClipboard from '../../../../utils/copyToClipboard';

import Button from '../../../../components/Button';

import Color from './components/Color';
import Size from './components/Size';

import share from '../../../../assets/product/share.png';
import jacket from '../../../../assets/product/jaqueta.png';

import UserNotLoggedModalWrap from '../../../../components/UserNotLoggedModalWrap';
import UserNotLoggedMessage from '../../../../components/UserNotLoggedModalWrap/UserNotLoggedMessage';

import {
  Container,
  Information,
  Name,
  Brand,
  Divider,
  Row,
  Price,
  Options,
  Clothe,
} from './styles';

const ProductInformation = ({
  externalId,
  productInfo,
  chosenColor,
  setChosenColor,
  chosenSize,
  setChosenSize,
  sizesAvailable,
  externalIdAvailable,
}) => {
  const user = useSelector((state) => state.User);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState(false);

  const formatPrice = () => {
    const price = productInfo.price.toFixed(2).replace('.', ',');

    return `R$ ${price}`;
  };

  const handleAddListToCart = async () => {
    // dispatch({
    //   type: 'listz-web/user/add-product-cart',
    //   payload: productInfo,
    // });

    // history.push('/carrinho');

    const handleError = async (error) => {
      let message;
      if (error.response?.data?.message) {
        message = error.response.data.message;
      } else {
        message = error.toString();
      }

      toast.error(message);
    };

    const productArr = {
      name: productInfo.title,
      external_id: productInfo.external_id,
      quantity: 1,
      unit_price: productInfo.price,
      item_url: productInfo.item_url,
      image_url: productInfo.image_url,
      is_available: productInfo.is_available,
    }

    await api
    .post(`/items`, { products: [productArr]})
    .then((res) => {
      toast.success('Item adicionado ao carrinho!');
    })
    .catch((err) => {
      handleError(err);
    });
  };

  const renderPrice = () => {
    if (!productInfo?.is_available) {
      return (
        <Price>
          <p>Produto indisponível</p>
        </Price>
      );
    }

    if (productInfo?.price) {
      return (
        <Price>
          <p>Valor total</p>
          <p>{formatPrice(productInfo.price)}</p>
        </Price>
      );
    }
  };

  const checkUserLoggedAddList = (external_id) => {
    if (user.accessToken) {
      history.push(`/escolha-uma-lista/${external_id}`);
    } else {
      setIsModal(true);
    }
  };

  function checkUserLoggedModal() {
    if (isModal) {
      return (
        <UserNotLoggedModalWrap toggleModal={() => setIsModal(false)}>
          <UserNotLoggedMessage />
        </UserNotLoggedModalWrap>
      );
    }
  }

  const renderAddCartButton = () => {
    if (productInfo?.is_available) {
      return (
        <Button
          text="Adicionar ao carrinho"
          onClick={() => handleAddListToCart()}
        />
      );
    }
  };

  const renderAddListButton = () => {
      return (
        <Button
          onClick={() => checkUserLoggedAddList(productInfo.external_id)}
          text="Adicionar a lista"
        />
      );
  };

  return (
    <Container>
      <Information>
        <Name>{productInfo?.title}</Name>
        {/* <Brand>Modelo -FXR</Brand>
        <p>
          Quente e confortável, esta jaqueta te ajuda a aproveitar o ski nas
          melhores condições.
        </p>
        <p>
          A impermeabilidade desta jaqueta foi reforçada com costuras principais
          estanques, permitindo manter-se seco em caso de chuva ou neve.
        </p> */}
        <Divider />
        <Row>
          {renderPrice()}
          <Options>
            {/* <div>-</div>
            <div>+</div> */}
            <FavoriteIcon data={productInfo} type="products" />
            <div>
              <img
                src={share}
                onClick={() =>
                  copyToClipboard(
                    `https://listz.com.br/#/produto/${productInfo.external_id}`,
                  )
                }
              />
            </div>
          </Options>
        </Row>
        <Divider />
        <Color
          chosenColor={chosenColor}
          setChosenColor={setChosenColor}
          productInfo={productInfo}
        />
        <Size
          chosenSize={chosenSize}
          setChosenSize={setChosenSize}
          productInfo={productInfo}
          sizesAvailable={sizesAvailable}
          externalIdAvailable={externalIdAvailable}
        />
        {renderAddCartButton()}
        {renderAddListButton()}
      </Information>
      <Clothe>
        <Row>
          {/* <Color /> */}
          <img src={productInfo.image_url} alt="Jaqueta" />
        </Row>
      </Clothe>
      {checkUserLoggedModal()}
    </Container>
  );
};

export default ProductInformation;
