import styled from 'styled-components';
import { default as ButtonGeneric } from '../../../../components/Button';

export const Container = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center; */
  position: relative;
  width: 100%;
  background-color: #2f78fe;
  padding: 20px;

  height: 200px;

  margin-bottom: 180px;

  > div:nth-of-type(1) {
    display: block;
    max-width: 800px;
    margin: 0 auto;

    > p {
      font-family: 'Izmir-Medium';
      color: #fff;
      font-size: 2rem;
      text-align: center;
      margin-bottom: -13px;
    }
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      color: #fff;
      margin-top: 50px;
    }
  }

  > img:nth-of-type(1) {
    position: absolute;
    left: 0px;
    top: -20px;
  }

  > img:nth-of-type(2) {
    position: absolute;
    right: 0px;
    top: -20px;
  }

  @media (max-width: 440px) {
    > div:nth-of-type(1) {
      max-width: 300px;
    }
  }

  @media (max-width: 63.75rem) {
    > img {
      display: none;
    }
  }

  @media (max-width: 33.75rem) {
    height: 260px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div:nth-of-type(2) {
    > p {
      font-family: 'Izmir-Medium';
      font-size: 2rem;
      color: #fff;

      max-width: 200px;
    }
  }

  @media (max-width: 33.75rem) {
    flex-direction: column;

    > div:nth-of-type(2) {
      margin-left: 20px;
      display: flex;
      align-items: center;
    }
  }
`;

export const ContainerNotFound = styled.div`
  position: relative;
  width: 100%;
  background-color: #2f78fe;
  padding: 20px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      color: #fff;
      font-weight: bold;
    }
  }

  > img:nth-of-type(1) {
    position: absolute;
    left: 0px;
    top: -20px;
  }

  > img:nth-of-type(2) {
    position: absolute;
    right: 0px;
    top: -20px;
  }

  @media (max-width: 63.75rem) {
    > img {
      display: none;
    }
  }

  @media (max-width: 33.75rem) {
    height: 260px;
  }
`;

export const Button = styled(ButtonGeneric)`
  background-color: #06e406;
  border-radius: 20px;
  width: 180px;
  margin-top: 20px;
`;
