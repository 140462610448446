import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import cpfMask from '../../utils/cpfMask';
import nameValidation from '../../utils/nameValidation';

import Input from '../../components/Input';
import Button from '../../components/Button';
import CardInformation from '../../components/CardInformation';
import LoadModal from '../../components/LoadModal';

import emailValidation from '../../utils/emailValidation';
import cpfValidation from '../../utils/cpfValidation';
import api from '../../services/api';

import logo from '../../assets/login/logo.png';
import background1 from '../../assets/signUp/background1.png';
import background2 from '../../assets/signUp/background2.png';
import user from '../../assets/input/user.png';
import lock from '../../assets/input/lock.png';
import email from '../../assets/input/email.png';
import offer from '../../assets/cardInformation/offer.png';
import wallet from '../../assets/cardInformation/wallet.png';

import { Container, Form, Row, Tooltip } from './styles';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    cpf: '',
    username: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);

  const removeFormatCPF = (cpf) => {
    cpf = cpf.replaceAll('.', '');
    cpf = cpf.replaceAll('-', '');
    return cpf;
  };

  const handleCreateUser = async (event) => {
    event.preventDefault();

    if (
      !userData.name.trim() ||
      !userData.email.trim() ||
      !userData.cpf.trim() ||
      !userData.username.trim() ||
      !userData.password.trim()
    ) {
      setErrorMessage('Preencha os campos corretamente');
      return;
    }

    if (!nameValidation(userData.name)) {
      setErrorMessage('Digite seu nome corretamente');
      return;
    }

    if (!emailValidation(userData.email)) {
      setErrorMessage('Digite seu e-mail corretamente');
      return;
    }

    if (!cpfValidation(userData.cpf)) {
      console.log(userData.cpf);
      setErrorMessage('Digite seu CPF corretamente');
      return;
    }

    setErrorMessage('');

    setShowLoadModal(true);

    await api
      .post('/users', {
        name: userData.name,
        email: userData.email,
        cpf: userData.cpf,
        username: userData.username,
        password: userData.password,
      })
      .then((response) => {
        dispatch({
          type: 'listz-web/user/get-user-data',
          payload: response.data,
        });
        history.push('/');
        setShowLoadModal(false);
      })
      .catch((error) => handleError(error));
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    setShowLoadModal(false);
    setErrorMessage(message);
  };

  const renderErrorMessage = () => {
    if (errorMessage) {
      return <Tooltip>{errorMessage}</Tooltip>;
    }
  };

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal />;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Form onSubmit={(event) => handleCreateUser(event)}>
        <div>
          <h1>
            Cadastre-se para <br />
            Receber nossos mimos!
          </h1>

          <p>Informações de cadastro</p>

          <Input
            containerStyle={{ marginTop: '4vh' }}
            icon={user}
            alt="User Icon"
            placeholder="Nome..."
            type="text"
            onInput={(event) =>
              setUserData({ ...userData, name: event.target.value })
            }
          />
          <Input
            containerStyle={{ marginTop: '1.2vh' }}
            icon={email}
            alt="Email Icon"
            placeholder="Email..."
            type="email"
            onInput={(event) =>
              setUserData({ ...userData, email: event.target.value })
            }
          />
          <Input
            containerStyle={{ marginTop: '1.2vh' }}
            icon={user}
            alt="User Icon"
            placeholder="CPF..."
            type="text"
            value={userData.formattedCpf}
            onInput={(event) =>
              setUserData({
                ...userData,
                cpf: removeFormatCPF(event.target.value),
                formattedCpf: cpfMask(event.target.value),
              })
            }
          />
          <Input
            containerStyle={{ marginTop: '1.2vh' }}
            icon={user}
            alt="User Icon"
            placeholder="Nome de usuário..."
            type="text"
            onInput={(event) =>
              setUserData({ ...userData, username: event.target.value })
            }
          />
          <Input
            containerStyle={{ marginTop: '1.2vh' }}
            icon={lock}
            alt="Lock Icon"
            placeholder="Senha..."
            type="password"
            onInput={(event) =>
              setUserData({ ...userData, password: event.target.value })
            }
          />
          {renderErrorMessage()}
          <Button
            type="submit"
            containerStyle={{ marginTop: '4vh' }}
            text="Próximo"
          />
        </div>
      </Form>
      <img src={logo} />
      <img src={background1} />
      <img src={background2} />
      <Row>
        <CardInformation
          containerStyle={{ backgroundColor: '#E2ECFF' }}
          icon={offer}
          title="Ofertas especiais"
          description="Tenha aceso a ofertas especiais e produtos selecionados com os melhores preços."
        />
        <CardInformation
          containerStyle={{ marginLeft: 20, backgroundColor: '#E0FFE0' }}
          icon={wallet}
          title="CashBack"
          description="Compre e receba uma parte do seu dinheiro de volta."
        />
      </Row>
      {renderLoadModal()}
    </Container>
  );
};

export default Login;
