import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import cpfMask from '../../utils/cpfMask';
import nameValidation from '../../utils/nameValidation';

import api from '../../services/api';
import emailValidation from '../../utils/emailValidation';
import cpfValidation from '../../utils/cpfValidation';

import Header from '../../components/Header';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import MenuProfile from '../../components/MenuProfile';
import Input from '../../components/Input';
import LoadModal from '../../components/LoadModal';
import Combobox from './Combobox';

import photoUserGeneric from '../../assets/sideMenu/photo-user.png';
import pencil from '../../assets/input/pencil.png';

import {
  Container,
  Content,
  Row,
  Column,
  Name,
  UpdateProfilePhoto,
  PersonalInformation,
  Label,
  BankData,
  Form,
  Tooltip,
  ProfilePhoto,
} from './styles';

const EditProfile = () => {
  let inputUpdateAvatarPhoto = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.User);

  const [showLoadModal, setShowLoadModal] = useState(false);
  const [errorMessageUploadPhoto, setErrorMessageUploadPhoto] = useState('');
  const [errorMessagePersonalData, setErrorMessagePersonalData] = useState('');
  const [errorMessageBankForm, setErrorMessageBankForm] = useState('');
  const [userAvatarUrl, setUserAvatarUrl] = useState(user.avatar_url);
  const [userData, setUserData] = useState({});
  const [userBankData, setUserBankData] = useState({});
  const [bankCodes, setBankCodes] = useState([]);

  const removeFormatCPF = (cpf) => {
    cpf = cpf.replaceAll('.', '');
    cpf = cpf.replaceAll('-', '');
    return cpf;
  };

  const fetchBankCodes = () => {
    api.get('/bank-codes').then((response) => {
      setBankCodes(response.data);
    });
  };

  function checkIfTheFileIsAnImage() {
    const file = inputUpdateAvatarPhoto.files[0];
    return file.type.includes('image');
  }

  async function uploadProfilePhoto(event) {
    if (!checkIfTheFileIsAnImage()) {
      setErrorMessageUploadPhoto(
        'Para alterar a foto de perfil selecione um arquivo do tipo imagem',
      );
      return;
    }

    setErrorMessageUploadPhoto('');
    setShowLoadModal(true);

    const formData = new FormData();
    formData.append('avatar', inputUpdateAvatarPhoto.files[0]);

    await api
      .patch(`users/${user.id}/avatar`, formData, {
        headers: {
          'Content-Type': `multipart/form-data;`,
        },
      })
      .then((response) => {
        dispatch({
          type: 'listz-web/user/get-user-profile-photo',
          payload: response.data,
        });
        setShowLoadModal(false);
        const newAvatarURL = response.data.avatar_url;
        setUserAvatarUrl(newAvatarURL);
      })
      .catch((error) => handleError(error, 'uploadPhoto'));
  }

  const updateUserPersonalData = async (event) => {
    event.preventDefault();

    if (!userData.name || !userData.username || !userData.email) {
      setErrorMessagePersonalData('Digite os seus dados corretamente');
      return;
    }

    if (!nameValidation(userData.name)) {
      setErrorMessagePersonalData('Digite seu nome corretamente');
      return;
    }

    if (!emailValidation(userData.email)) {
      setErrorMessagePersonalData('E-mail inválido');
      return;
    }

    setErrorMessagePersonalData('');
    setShowLoadModal(true);

    await api
      .put(`/users/${user.id}`, {
        name: userData.name,
        username: userData.username,
        email: userData.email,
      })
      .then((response) => {
        setShowLoadModal(false);
        dispatch({
          type: 'listz-web/user/get-user-profile',
          payload: response.data,
        });
        setUserData({
          username: response.data.username,
          email: response.data.email,
          name: response.data.individual_user.name,
        });
      })
      .catch((error) => handleError(error, 'personalData'));
  };

  const updateBankInformations = async (event) => {
    event.preventDefault();

    if (!userBankData.agency || !userBankData.account || !userBankData.cpf) {
      setErrorMessageBankForm('Digite os seus dados corretamente');
      return;
    }

    if (!cpfValidation(userBankData.cpf)) {
      setErrorMessageBankForm('CPF inválido');
      return;
    }

    setErrorMessageBankForm('');
    setShowLoadModal(true);

    let bankCode;
    if (userBankData.bank_code) {
      bankCode = userBankData.bank_code;
    } else {
      bankCode = bankCodes[0].code;
    }

    if (userData.hasBankInformations) {
      await api
        .put(`/banks/${userBankData.id}`, {
          agency: userBankData.agency,
          account: userBankData.account,
          cpf: userBankData.cpf,
          bank_code: bankCode,
        })
        .then((response) => {
          setShowLoadModal(false);
        })
        .catch((error) => handleError(error, 'bankData'));
    } else {
      await api
        .post(`/banks`, {
          agency: userBankData.agency,
          account: userBankData.account,
          cpf: userBankData.cpf,
          bank_code: bankCode,
        })
        .then((response) => {
          fetchUserInformations();
          setShowLoadModal(false);
        })
        .catch((error) => handleError(error, 'bankData'));
    }
  };

  const fetchUserInformations = async () => {
    await api.get(`/users/${user.id}`).then((response) => {
      dispatch({
        type: 'listz-web/user/get-user-profile',
        payload: response.data,
      });
      setUserData({
        username: response.data.username,
        email: response.data.email,
        name: response.data.individual_user.name,
        hasBankInformations: response.data.bank,
      });
      setUserAvatarUrl(response.data.avatar_url);
      if (response.data.bank) {
        setUserBankData({
          ...userBankData,
          id: response.data.bank.id,
          agency: response.data.bank.agency,
          account: response.data.bank.account,
          cpf: response.data.bank.cpf,
          formattedCpf: cpfMask(response.data.bank.cpf),
          bank_code: response.data.bank.bank_code.code,
        });
      }
    });
  };

  const handleError = async (error, type) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    setShowLoadModal(false);
    if (type === 'uploadPhoto') {
      setErrorMessageUploadPhoto(message);
    } else if (type === 'personalData') {
      setErrorMessagePersonalData(message);
    } else if (type === 'bankData') {
      setErrorMessageBankForm(message);
    }
  };

  useEffect(() => {
    fetchUserInformations();
    fetchBankCodes();
  }, []);

  const renderAvatarImg = () => {
    if (userAvatarUrl) {
      return <ProfilePhoto src={userAvatarUrl} alt="Foto de perfil" />;
    }
  };

  const renderErrorMessageUploadPhoto = () => {
    if (errorMessageUploadPhoto) {
      return <Tooltip>{errorMessageUploadPhoto}</Tooltip>;
    }
  };

  const renderErrorMessagePersonalData = () => {
    if (errorMessagePersonalData) {
      return <Tooltip>{errorMessagePersonalData}</Tooltip>;
    }
  };

  const renderErrorMessageBankForm = () => {
    if (errorMessageBankForm) {
      return <Tooltip>{errorMessageBankForm}</Tooltip>;
    }
  };

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal />;
    }
  };

  return (
    <Container>
      <Header />
      <Content>
        <Row>
          {renderAvatarImg()}
          <Column>
            <Name>{user.name}</Name>
            <UpdateProfilePhoto onClick={() => inputUpdateAvatarPhoto.click()}>
              Alterar foto de Perfil
              <input
                type="file"
                accept="image/*"
                ref={(input) => (inputUpdateAvatarPhoto = input)}
                onInput={(event) => uploadProfilePhoto(event)}
              />
            </UpdateProfilePhoto>
            {renderErrorMessageUploadPhoto()}
            <MenuProfile />
          </Column>
        </Row>
        <Form>
          <PersonalInformation
            onSubmit={(event) => updateUserPersonalData(event)}
          >
            <Label>
              Nome
              <Input
                value={userData.name}
                onInput={(event) =>
                  setUserData({ ...userData, name: event.target.value })
                }
                iconRight={pencil}
              />
            </Label>
            <Label>
              Username
              <Input
                value={userData.username}
                onInput={(event) =>
                  setUserData({ ...userData, username: event.target.value })
                }
                iconRight={pencil}
              />
            </Label>
            <Label>
              E-mail
              <Input
                value={userData.email}
                onInput={(event) =>
                  setUserData({ ...userData, email: event.target.value })
                }
                iconRight={pencil}
              />
            </Label>
            {renderErrorMessagePersonalData()}
            <Button type="submit" text="Salvar" />
          </PersonalInformation>
          <BankData onSubmit={(event) => updateBankInformations(event)}>
            <p>Seus dados para saque</p>
            <Row>
              <Combobox
                bankCodes={bankCodes}
                userBankData={userBankData}
                setUserBankData={setUserBankData}
              />
              {/* <Input
                value={userBankData.bank_code}
                onInput={(event) =>
                  setUserBankData({
                    ...userBankData,
                    bank_code: event.target.value,
                  })
                }
                placeholder="BANCO"
                iconRight={pencil}
              /> */}
              <Input
                value={userBankData.agency}
                onInput={(event) => {
                  if (event.target.value.length < 5) {
                    setUserBankData({
                      ...userBankData,
                      agency: event.target.value,
                    });
                  }
                }}
                placeholder="AGÊNCIA"
                iconRight={pencil}
              />
            </Row>
            <Row>
              <Input
                value={userBankData.account}
                onInput={(event) => {
                  if (event.target.value.length < 12) {
                    setUserBankData({
                      ...userBankData,
                      account: event.target.value,
                    });
                  }
                }}
                placeholder="CONTA"
                iconRight={pencil}
              />
              <Input
                value={userBankData.formattedCpf}
                onInput={(event) =>
                  setUserBankData({
                    ...userBankData,
                    cpf: removeFormatCPF(event.target.value),
                    formattedCpf: cpfMask(event.target.value),
                  })
                }
                placeholder="CPF"
                iconRight={pencil}
              />
            </Row>
            {renderErrorMessageBankForm()}
            <Button type="submit" text="Salvar" />
          </BankData>
        </Form>
      </Content>
      <Footer />
      {renderLoadModal()}
    </Container>
  );
};

export default EditProfile;
