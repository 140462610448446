import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #edf1fc;

  @media (max-width: 29.25rem) {
    padding: 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1336px;
  width: 100%;

  @media (max-width: 64.75rem) {
    flex-direction: column-reverse;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  > div > p {
    font-family: Izmir-Bold;
    color: #2f78fe;
    font-size: 3.5rem;
  }

  > div > img {
    height: 60px;
    margin-left: 10px;
  }

  > button {
    margin-top: 20px;
  }

  @media (max-width: 78.75rem) {
    width: 590px;
  }

  @media (max-width: 71.75rem) {
    width: 480px;
  }

  @media (max-width: 64.75rem) {
    align-items: center;
    margin-top: 20px;
  }

  @media (max-width: 33.75rem) {
    width: 380px;
  }

  @media (max-width: 29.25rem) {
    width: 300px;
  }

  @media (max-width: 320px) {
    > div {
      flex-direction: column;
      align-items: center;
      width: 80%;
      text-align: center;

      > img {
        height: 40px;
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 320px) {
    > div {
      width: 120px;
    }
  }
`;

export const LinkPage = styled(Link)`
  text-decoration: none;

  @media (max-width: 1039px) {
    width: 100%;
    margin: 20px auto;

    > button {
      width: 80%;
      margin: 20px auto;
    }
  }
`;
