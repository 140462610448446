import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 150px;
  height: 50px;
  border-radius: 40px;
  margin: 0 auto;
  box-shadow: 0px 0px 6px 0px lightgrey;
  cursor: pointer;
  margin-left: auto !important;
  margin-right: auto !important;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    font-family: 'Izmir-Medium';
    font-size: 2rem;
    color: #4a4747;
    margin-left: 10px;
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #06e406;
  color: #fff;
  font-size: 4rem;
`;
