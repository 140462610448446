import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  > img:nth-of-type(1) {
    position: absolute;
    z-index: -1;
    top: 140px;
    left: -90px;
    height: 430px;
  }

  > img:nth-of-type(2) {
    position: absolute;
    z-index: -1;
    top: 140px;
    right: -90px;
    height: 430px;
  }

  @media (max-width: 66.25rem) {
    > img:nth-of-type(1) {
      top: 180px;
      height: 340px;
    }

    > img:nth-of-type(2) {
      top: 180px;
      height: 340px;
    }
  }

  @media (max-width: 47.5rem) {
    > img:nth-of-type(1) {
      top: 230px;
      left: -60px;
      height: 240px;
    }

    > img:nth-of-type(2) {
      top: 230px;
      right: -60px;
      height: 240px;
    }
  }

  @media (max-width: 32.5rem) {
    > img:nth-of-type(1) {
      display: none;
    }

    > img:nth-of-type(2) {
      display: none;
    }
  }
`;

export const Content = styled.div`
  max-width: 460px;
  width: 100%;
  padding: 40px 20px;

  > h1 {
    font-family: 'Izmir-Medium';
    font-size: 2.5rem;
    color: #838383;
    text-align: center;
  }
`;

export const Divider = styled.div`
  height: 2px;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #c9c9c9;
  margin-top: 10px;
  margin-bottom: 40px;
`;
