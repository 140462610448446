import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;

  > img:nth-of-type(1) {
    position: absolute;
    height: 60px;
    top: 0px;
    left: 6vw;
  }

  > img:nth-of-type(2) {
    position: absolute;
    height: 800px;
    left: 40px;
    bottom: 0px;
    z-index: -1;
  }

  > img:nth-of-type(3) {
    position: absolute;
    top: -120px;
    right: -60px;
  }

  @media (max-width: 91.25rem) {
    > img:nth-of-type(2) {
      height: 600px;
    }
  }

  @media (max-width: 68.75rem) {
    > img:nth-of-type(2) {
      display: none;
    }
  }

  @media (max-width: 68.75rem) {
    > img:nth-of-type(1) {
      left: 50%;
      transform: translateX(-50%);
    }

    > img:nth-of-type(3) {
      display: none;
    }
  }
`;

export const Form = styled.form`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 430px;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px grey;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    > h1 {
      font-family: Izmir-Bold;
      color: #2f78fe;
      font-size: 4rem;
    }

    > p {
      font-family: Izmir-Regular;
      color: #000000;
      font-size: 2.4rem;
    }

    > img {
      position: absolute;
      height: 20px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 4vh;
    }
  }

  @media (max-width: 83.125rem) {
    right: 20px;
  }

  @media (max-width: 68.75rem) {
    right: 0px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    max-width: 530px;
    width: 100%;
    box-shadow: 0px 0px 0px 0px;
    margin-top: 20px;
  }

  @media (max-width: 33.125rem) {
    width: 100%;
  }

  @media (max-width: 1100px) {
    text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  position: absolute;
  left: 460px;
  top: 480px;

  > div {
    height: auto;
  }

  @media (max-width: 91.25rem) {
    left: 380px;
    top: 280px;
  }

  @media (max-width: 80rem) {
    left: 200px;
  }

  @media (max-width: 68.75rem) {
    display: none;
  }
`;

export const Tooltip = styled.div`
  font-family: 'Izmir-Bold';
  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: -10px;
  color: #f00;
  text-align: center;
`;
