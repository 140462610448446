import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import api from '../../services/api';
import { useSelector } from 'react-redux';

import Header from '../../components/Header';
import AddNewList from './components/AddNewList';
import ListOfLists from './components/ListOfLists';
import ButtonCreateList from './components/ButtonCreateList';
import Footer from '../../components/Footer';
import { toast } from 'react-toastify';

import background1 from '../../assets/chooseTheList/background1.png';
import background2 from '../../assets/chooseTheList/background2.png';

import UserNotLoggedModalWrap from '../../components/UserNotLoggedModalWrap';
import UserNotLoggedMessage from '../../components/UserNotLoggedModalWrap/UserNotLoggedMessage';

import { Container, Content, Divider, Spinner } from './styles';

const ChooseTheList = () => {
  const user = useSelector((state) => state.User);
  const { id } = useParams();
  const [lists, setLists] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [loading, setLoading] = useState(false);

  async function fetchList() {
    setLoading(true);
    await api
      .get(`/lists?category_id=${id}`)
      .then((res) => {
        setLoading(false);
        setLists(removeUsersList(res.data.lists));
      })
      .catch((error) => {
        handleError(error);
      });
  }

  const handleError = async (error) => {
    setLoading(false);
    toast.error(error.response?.data?.message || error.toString());
  };

  useEffect(() => {
    fetchList();
  }, [id]);

  function checkUserLoggedModal() {
    if (isModal) {
      return (
        <UserNotLoggedModalWrap toggleModal={() => setIsModal(false)}>
          <UserNotLoggedMessage />
        </UserNotLoggedModalWrap>
      );
    }
  }

  const removeUsersList = (list) => {
    const adminList = list.filter(({ is_admin }) => is_admin === true);
    return adminList;
  };

  const renderContent = () => {
    if (loading) {
      return <Spinner />;
    }

    return (
      <>
        <ListOfLists data={lists} fetchList={fetchList} />
        <ButtonCreateList user={user} setIsModal={setIsModal} />
      </>
    );
  };

  return (
    <Container>
      <img src={background1} alt="Detalhe de fundo" />
      <Header />
      <AddNewList user={user} setIsModal={setIsModal} />
      <Content>
        <h1>Listas da Categoria</h1>
        <Divider />
        {renderContent()}
      </Content>
      <Footer />
      <img src={background2} alt="Detalhe de fundo" />
      {checkUserLoggedModal()}
    </Container>
  );
};

export default ChooseTheList;
