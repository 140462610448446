import * as Types from './types';

const INITIAL_STATE = {
  id: '',
  username: '',
  name: '',
  avatar_url: '',
  accessToken: '',
  refreshToken: '',
  cart: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case Types.GET_USER_DATA:
      return {
        id: payload.user.id,
        username: payload.user.username,
        name: payload.user.individual_user.name,
        avatar_url: payload.user.avatar_url,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        cart: [],
      };

    case Types.GET_USER_PROFILE_PHOTO:
      return {
        ...state,
        avatar_url: payload.avatar_url,
      };

    case Types.GET_NEW_TOKEN:
      return {
        ...state,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
      };

    case Types.GET_USER_PROFILE:
      return {
        ...state,
        username: payload.username,
        name: payload.individual_user.name,
        avatar_url: payload.avatar_url,
      };

    case Types.LOGOUT:
      return INITIAL_STATE;

    case Types.ADD_PRODUCT_CART:
      const itemToAdd = state.cart.find(
        (product) => product.external_id === payload.external_id,
      );

      if (itemToAdd) {
        return {
          ...state,
          cart: [...state.cart, { ...payload }],
        };
      }

      return {
        ...state,
      };

    case Types.ADD_LIST_CART:
      return {
        ...payload,
      };

    case Types.REMOVE_PRODUCT_CART:
      const stateWithoutRemove = state.cart.filter(
        (product) => product.external_id !== payload.external_id,
      );
      return {
        ...state,
        cart: stateWithoutRemove,
      };
    default:
      return state;
  }
};
