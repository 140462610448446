import Header from '../../components/Header';
import Footer from '../../components/Footer';

import logo from '../../assets/about/logo.png';
import shopList from '../../assets/about/shop-list.png';
import logoTransparent from '../../assets/about/logo-transparent.png';

import { Container, Content, Row, Column, TitleContainer } from './styles';

const TermsOfUseAndPrivacy = () => {
  return (
    <>
      <Container>
        <Header />
        <Content>
          <TitleContainer>
            <img src={logoTransparent} alt="Listz" />
            <div>
              <img src={logo} alt="Logo Listz" />
              <h1>Termos de Uso e Privacidade</h1>
            </div>
            <img src={logoTransparent} alt="Listz" />
          </TitleContainer>
          <Row>
            <Column>
              <p>
                1. Introdução. Este documento tem por objetivo esclarecer a
                POLÍTICA DE PRIVACIDADE, daqui por diante referida apenas como
                “Política”, e como o LISTZ trata informações e dados pessoais
                coletados no âmbito da prestação de seus serviços por meio do
                seu aplicativo e/ou plataforma web, ambos aqui referidos como
                “Portal”. Em caso de dúvida sobre o conteúdo da nossa Política
                de Privacidade, entre em contato com nosso suporte para demais
                esclarecimentos. 2. Definições Gerais 2.1. Usuário. Para fins
                desta Política de Privacidade, Usuário refere-se à pessoa
                física, capaz de praticar atos da vida civil ou devidamente
                representada ou assistida, conforme o caso, que faz uso de nosso
                Portal. 2.2. Dados Pessoais. Dados Pessoais são informações que
                identificam ou podem identificar o Usuário. 2.3. Dados Pessoais
                Sensíveis. São Dados Pessoais relacionados à origem racial ou
                étnica, convicção religiosa, opinião política, filiação a
                sindicato ou a organização de caráter religioso, filosófico ou
                político, dado referente à saúde ou à vida sexual, dado genético
                ou biométrico, conforme estipulado pela Lei nº 13.709/18. 2.4.
                Tratamento. Tratamento de Dados Pessoais significa toda operação
                que pode ser realizada com Dados Pessoais, como como as que se
                referem a coleta, produção, recepção, classificação, utilização,
                acesso, reprodução, transmissão, distribuição, processamento,
                arquivamento, armazenamento, eliminação, avaliação ou controle
                da informação, modificação, comunicação, transferência, difusão
                ou extração. 2.5. Legislação Aplicável. Atuamos de acordo com a
                legislação brasileira, atendendo, especialmente com a legislação
                de proteção de dados pessoais aplicável. 2.6. Encarregado de
                Proteção de Dados Pessoais. É a pessoa responsável por atuar, em
                nome do LISTZ, como canal de comunicação com a Autoridade
                Nacional de Proteção de Dados e com o Usuário, na qualidade de
                titular de Dado Pessoal. 2.7. Sites de Terceiros. Esclarecemos
                que o “Portal” possui links para efetivação das compras em sites
                de terceiros online de terceiros, e a utilização de tais sites e
                serviços está sujeita a termos de uso e política de privacidade
                de tais terceiros. Esta Política de Privacidade não trata das
                práticas de privacidade e proteção de dados realizada por
                terceiros. A inclusão de um link em nosso Portal não implica o
                endosso do site de terceiros vinculado ou de seus produtos e
                serviços, sendo fornecido apenas para sua conveniência. Se
                decidir acessar qualquer um dos sites de terceiros vinculados a
                nosso Portal, estará sujeito aos termos e condições de uso
                desses sites de terceiros. 3. Quais dados nós Tratamos e como
                são utilizados? O LISTZ trata dados Dados Pessoais dos Usuários
                (A) inseridos voluntariamente pelos próprios usuários, (B)
                coletados quando o Usuário utiliza o Portal e usufrui dos nossos
                serviços, (C) quando o Usuário realiza uma compra ofertada em
                nosso Portal e precisamos fazer a confirmação acerca do cadastro
                ativo de Usuário e efetuar o cashback na conta do Usuário. 3.1.
                Dados cadastrais. Tratamos os seguintes Dados Pessoais para
                realizar o cadastro do Usuário em nosso Portal: e-mail, CPF e/ou
                celular. 3.2. Dados de Compras. O Anunciante enviará
                voluntariamente ao LISTZ no ato de cadastrar-se no “Portal” para
                utilização dos serviços (se o LISTZ não identificar o Titular do
                Dado Pessoal como Usuário ativo, os dados recebidos serão
                descartados de nosso banco de dados imediatamente). Caso Você
                seja um Usuário ativo, terá direito ao Cashback e o LISTZ
                Tratará os seguintes dados para gerenciar o seu Cashback: nome e
                histórico de compra em nossos parceiros Anunciantes. 3.3. Dados
                de Resgate de Cashback. Tratamos os seguintes Dados Pessoais
                para realizar pagamento do resgate do saldo de Cashback dos
                Usuários: dados bancários (banco, agência, número da conta
                bancária e chave do PIX), os quais são coletados no momento do
                cadastro ou da solicitação de resgate. Seus dados bancários não
                são compartilhados sob qualquer hipótese com terceiros. 3.4.
                Dados de prevenção à fraude. Poderemos Tratar os seguintes Dados
                Pessoais para podermos prevenir fraudes: informações do
                dispositivo, CPF, e-mail, telefone celular, endereço IP de
                acesso aos produtos e serviços LISTZ, foto do Usuário segurando
                seu documento de identidade para que o LISTZ possa confirmar sua
                identidade. 3.5. Dados de compras realizadas e sites de compras.
                Tratamos os seguintes Dados Pessoais para podermos registrar as
                compras do Usuário e gerenciar os Cashbacks que o Usuário tem
                direito: Anunciante da compra, data e horário, número do pedido,
                valor gasto e produto adquirido. Essas informações serão
                compartilhadas por terceiros onde tenha sido efetivada a compra
                com o LISTZ, e são necessárias para a identificação do seu
                Cashback. Esses dados também poderão ser utilizados para
                identificar hábitos de consumo e proporcionar melhores
                experiências de compras para o Usuário a partir da
                personalização de ofertas exclusivas, respeitadas sempre as
                disposições legais. Usuário, note que o LISTZ não tem a intenção
                de Tratar Dados Pessoais Sensíveis, porém é importante a ciência
                que os produtos adquiridos, inclusive em farmácias, são listados
                nos relatórios que são enviadas ao nosso sistema para as
                finalidades de cálculo do Cashback, o que foge do controle do
                LISTZ. O LISTZ não utilizará essas informações para nenhum outro
                propósito que não seja disponibilizar o Cashback ao Usuário. Se
                você não deseja que o LISTZ receba a informação acerca de
                qualquer produto que consta na nota fiscal, pedimos que não use
                o LISTZ para referida compra. 3.6. Dados de extrato. Tratamos os
                seguintes Dados Pessoais para exibir o extrato da conta LISTZ:
                valor de Cashback recebido e histórico de resgates. 3.7. Dados
                de marketing. Tratamos os seguintes Dados Pessoais para definir
                origem do cadastro do Usuário ou das compras realizadas no
                Portal, o que possibilita que o LISTZ otimize custos com
                campanhas, investimentos entre as plataformas oferecidas (site,
                app e Extensão LISTZ) e realize marketing direcionado a Você com
                base em suas características e informações: gênero, data de
                nascimento, estado civil, profissão, categorias ou produtos
                comprados, termos buscados no LISTZ, lista de ofertas usadas
                pelo Usuário, endereço, avaliação de loja, data de cadastro no
                LISTZ, data de última visita, informações do dispositivo, origem
                de cadastro (site desktop, site mobile, app IOS, app Android
                etc.), interações com e-mails. 3.8. Comunicação com o Usuário.
                Sempre que precisarmos entrar em contato com o Usuário, ou
                quando considerarmos que temos alguma oferta que pode ser de
                interesse do Usuário considerados os propósitos da oferta, uso
                de dados do Usuário e razoabilidade do contato, poderemos
                utilizar as informações de contato que nos foram enviadas (por
                exemplo, por meio de notificações por e-mail, SMS, Push, entre
                outros mecanismos, com conteúdo de natureza informativa ou
                promocional estritamente relacionadas aos serviços LISTZ). Para
                tanto, Trataremos os seguintes Dados Pessoais: nome e sobrenome
                do Usuário, e-mail, telefone celular. 3.16.1 O LISTZ poderá, a
                seu exclusivo critério, enviar brindes e materiais promocionais
                para o endereço informado no ato do cadastro do Usuário. Caso o
                usuário não queira receber, o mesmo poderá solicitar ao e-mail
                de suporte. 4. Compartilhamento de informações do Usuário Em
                complemento ao indicado no item 3 acima, o LISTZ poderá
                compartilhar os Dados Pessoais dos Usuários com as pessoas
                indicadas abaixo, sempre somente na medida que se fizer
                necessária para que as finalidades sejam atingidas. 4.1.
                Anunciantes. Para que o Anunciante possa identificar o Usuário
                em caso de reclamações sobre a utilização de nossos serviços, em
                especial sobre disponibilização e resgate de Cashback. 4.2.
                Prestadores de Serviços. Para execução dos serviços contratados,
                incluindo, (a) prestadores de serviços técnicos que operam a
                infraestrutura técnica de que necessitamos para fornecer os
                serviços, em particular fornecedores que alojam, guardam, gerem
                e mantêm o Portal funcionando, 4.3. Autoridades. Poderemos
                compartilhar os Dados Pessoais dos Usuários com autoridades para
                (i) cumprir com decisões judiciais ou administrativas, (ii)
                exercício regular de direitos do LISTZ, inclusive apresentando
                documento em processos, (iii) cumprimento de obrigação legal ou
                regulatória. 4.4. Terceiro. Para viabilizar venda, parcial ou
                total, do negócio ou de nossos ativos ou ações (inclusive em
                conexão com falência ou processo semelhante), ou como parte de
                qualquer reorganização ou reestruturação do negócio, fusão,
                cisão ou incorporação, de modo que poderemos compartilhar as
                informações dos Usuários com terceiros que façam parte dos
                negócios, tomando as medidas necessárias para garantir que os
                direitos de privacidade. 5. Segurança das Informações 5.1. O
                LISTZ adota mecanismos de segurança adotados pelo mercado que
                visam assegurar a privacidade, autenticidade e inviolabilidade
                das informações. 5.2. Todos os Dados Pessoais Tratados pelo
                LISTZ são armazenados em um banco de dados reservado e com
                acesso restrito a alguns funcionários habilitados cujas funções
                requeiram acesso e utilização nos termos desta Política ou para
                fins de segurança, todos obrigados, por contrato, a manter a
                confidencialidade das informações e a não utiliza-las
                inadequadamente. 5.3. Nosso banco de dados é equipado com
                mecanismos de autenticação de acesso aos registros, permitindo a
                catalogação detalhada desses acessos. Assim, é possível realizar
                a verificação posterior, indicando o momento, a duração, o
                arquivo e a identidade do funcionário autorizado que realizou o
                referido acesso aos registros. 5.4. Apesar desse mecanismo
                garantir a inviolabilidade dos dados coletados, é importante que
                o Usuário realize procedimentos básicos de segurança em seu
                celular e/ou computador, por meio da utilização de ferramentas
                como antivírus, além de não fornecer ou informar sua senha a
                quaisquer terceiros. 6. Por quanto tempo os dados são
                armazenados? 6.1. Mantemos os Dados Pessoais somente pelo tempo
                que for necessário para atingirmos as finalidades para as quais
                os coletamos, inclusive para fins de cumprimento de quaisquer
                obrigações legais, contratuais, de prestação de contas ou
                requisição de autoridades competentes. 6.2. Para determinarmos o
                período de retenção adequado para os Dados Pessoais,
                consideramos a quantidade, a natureza e a sensibilidade dos
                Dados Pessoais, o risco potencial de danos decorrentes do uso
                não autorizado ou da divulgação de seus Dados Pessoais, a
                finalidade de Tratamento dos Dados Pessoais e se podemos
                alcançar tais propósitos através de outros meios, observando os
                requisitos legais aplicáveis. 6.3. Caso determinados dados
                deixem de ser necessários para o alcance de determinada
                finalidade, serão excluídos ou sujeitos à anonimização. 7.
                Transferência internacional de dados 7.1. O LISTZ armazena seu
                banco de dados em sistemas de nuvem hospedados no Brasil e
                outros países. Dessa forma, o LISTZ adotará medidas legais que
                assegurarão que os Dados Pessoais estejam sujeitos a medidas que
                assegurem nível de proteção equivalente ao assegurado por esta
                Política e pela legislação aplicável. 8. Direitos dos titulares
                de dados pessoais, perguntas e esclarecimentos 8.1. Você poderá
                tirar dúvidas ou exercer seus direitos inerentes à qualidade de
                titular de Dados Pessoais (incluindo, sem limitação, o direito
                de acesso, correção e atualização, portabilidade, revogação de
                consentimentos, oposição a tratamentos, exclusão, bloqueio,
                revisão de decisões e suspensão de comunicações) contatando-nos
                por meio do e-mail de suporte. 9. Condições Gerais 9.1.
                Resolução de Controvérsias. Fica eleito o foro da Comarca de São
                Paulo/SP, com renúncia de todos os outros, por mais
                privilegiados que sejam, caso seja necessário dirimir qualquer
                dúvida ou controvérsia decorrente deste contrato que não tenham
                sido solucionadas por meio do canal de atendimento do LISTZ.
                Isto significa que, caso seja necessário discutirmos algo na
                Justiça, as ações devem ser propostas em São Paulo/SP. 9.2.
                Alterações desta Política de Privacidade: De tempos em tempos, o
                LISTZ poderá atualizar e modificar a presente Política de
                Privacidade. A alteração será vinculante após a divulgação de
                nossa Política em nosso site, mas também poderemos enviar para o
                seu e-mail. Você será informado toda vez que a Política de
                Privacidade sofrer alguma alteração relevante. Ao continuar a
                usar nossos Serviços após uma alteração na Política de
                Privacidade, entendemos que você está de acordo com as novas
                condições. 9.2.1. Nas hipóteses em que as alterações a esta
                Política de Privacidade resultarem em mudanças nas práticas de
                tratamento de dados pessoais que dependam do seu consentimento,
                solicitaremos o seu consentimento com os novos termos da
                Política de Privacidade em relação ao tratamento de dados e
                finalidades indicados. Documento válido à partir de 24 de março
                de 2021.
              </p>
            </Column>
          </Row>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default TermsOfUseAndPrivacy;
