import { useHistory } from 'react-router-dom';

import logo from '../../assets/footer/logo.png';
import logoTransparent from '../../assets/footer/logo-transparent.png';

import { Container, Content, Row, Column, Bottom } from './styles';

const Footer = () => {
  const history = useHistory();

  return (
    <Container>
      <Content>
        <Column>
          <p onClick={() => history.push('/sobre')}>Sobre nós</p>
          <p>Saiba mais sobre nossa história</p>
        </Column>
        <Column>
          <p onClick={() => history.push('/parceiros')}>Nossos Parceiros</p>
          <p>Trabalhamos com os melhores. Conheça nossos parceiros</p>
        </Column>
        <Column>
          <p onClick={() => history.push('/contato')}>Fale conosco </p>
          <p>Se precisar pode contar com a gente</p>
        </Column>
        <Row>
          <Bottom>
            <p onClick={() => history.push('/termos-de-uso-e-privacidade')}>
              Termos de Uso e Privacidade
            </p>
          </Bottom>
          <img src={logo} alt="Logo do Listz" />
        </Row>
      </Content>
      <img src={logoTransparent} alt="Listz" />
    </Container>
  );
};

export default Footer;
