import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  background-color: #fff;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #2f78fe;
  padding: 20px;

  height: 200px;

  > img:nth-of-type(1) {
    position: absolute;
    left: 0px;
  }

  > img:nth-of-type(2) {
    position: absolute;
    right: 0px;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      height: 30px;
    }

    > h1 {
      font-family: 'Izmir-Medium';
      font-size: 3.5rem;
      color: #fff;
      margin-left: 10px;
    }
  }

  @media (max-width: 46.625rem) {
    > img {
      display: none;
    }
  }

  @media (max-width: 450px) {
    > div {
      flex-direction: column-reverse;

      > img {
        height: 30px;
        margin-top: 10px;
      }

      > h1 {
        text-align: center;
      }
    }
  }

  @media (max-width: 32.5rem) {
    height: 120px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;

  > img {
    object-fit: contain;
    height: 400px;
  }

  @media (max-width: 70rem) {
    > img {
      height: 300px;
    }
  }

  @media (max-width: 64rem) {
    > img {
      height: 260px;
    }
  }

  @media (max-width: 61.25rem) {
    > img {
      display: none;
    }
  }

  @media (max-width: 47.5rem) {
    padding: 40px 40px;
  }

  @media (max-width: 22.5rem) {
    padding: 10px 10px;
  }
`;

export const Column = styled.div`
  margin-left: 80px;

  > p:nth-of-type(1) {
    font-family: 'Izmir-Bold';
    font-size: 3rem;
    color: #585252;
    width: 90%;
    text-align: justify;
    margin: 0 auto;
  }

  > p:nth-of-type(2) {
    font-family: 'Izmir-Regular';
    font-size: 2.4rem;
    color: #202125;
    width: 700px;
    margin-top: 20px;
  }

  @media (max-width: 80rem) {
    margin-left: 30px;

    > p:nth-of-type(2) {
      width: 600px;
    }
  }

  @media (max-width: 61.25rem) {
    margin-left: 0px;

    > p:nth-of-type(2) {
      width: 700px;
    }
  }

  @media (max-width: 47.5rem) {
    > p:nth-of-type(2) {
      width: 100%;
    }
  }
`;
