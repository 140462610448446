import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
`;

export const ProfilePhoto = styled.img`
  height: 200px;
  width: 200px;
  border-radius: 6px;

  @media (max-width: 55.625rem) {
    margin-right: 0px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 20px 80px 20px;
  max-width: 1336px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 55.625rem) {
    > div:nth-of-type(1) {
      align-items: center;
      flex-direction: column;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;

  > img {
    margin-right: 40px;
  }
`;

export const Column = styled.div``;

export const Name = styled.p`
  font-family: 'Izmir-Bold';
  font-size: 3.5rem;
  color: #0059ff;
`;

export const UpdateProfilePhoto = styled.div`
  font-family: 'Izmir-Regular';
  font-size: 2.5rem;
  color: #707070;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: -20px;
  width: fit-content;
  > input[type='file'] {
    display: none;
  }

  @media (max-width: 640px) {
    margin-top: 0px;
  }
`;

export const PersonalInformation = styled.form`
  > label > div {
    width: 380px;
    height: 60px;
  }

  button {
    margin-top: 20px;
    width: 100%;
  }

  @media (max-width: 71.875rem) {
    > label > div {
      width: 300px;
      height: 60px;

      > input {
        margin-left: 0px;
      }
    }
  }
`;

export const Label = styled.label`
  display: block;
  font-family: 'Izmir-Medium';
  color: #838383;
  font-size: 2.5rem;
  margin-top: 20px;
`;

export const BankData = styled.form`
  margin-left: 80px;
  margin-top: 90px;

  > p {
    font-family: 'Izmir-Bold';
    font-size: 3.5rem;
    color: #615e5e;
    margin-bottom: 20px;
  }

  > div > div {
    > input {
      margin-left: 0px;
    }
  }

  > div:nth-of-type(1) > div:nth-of-type(1) {
    width: 380px;
    margin-right: 20px;
    margin-top: 0px;
  }

  > div:nth-of-type(2) {
    margin-top: 20px;

    > div:nth-of-type(1) {
      margin-right: 20px;
    }
  }

  > button {
    margin: 20px auto 0px auto;
  }

  @media (max-width: 76.25rem) {
    margin-left: 20px;
  }

  @media (max-width: 71.875rem) {
    > div:nth-of-type(1) > div:nth-of-type(1) {
      width: 340px;
      margin-right: 20px;
    }
  }

  @media (max-width: 30rem) {
    > div:nth-of-type(1) > div:nth-of-type(1) {
      width: 280px;
      margin-right: 20px;
    }

    > div {
      flex-direction: column;

      > div {
        margin-top: 20px;
        width: 280px;
      }
    }
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 55.625rem) {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
`;

export const Tooltip = styled.div`
  font-family: 'Izmir-Bold';
  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: -10px;
  color: #f00;
  text-align: center;
`;
