import React, { useState, useEffect } from 'react';
import TextTruncate from 'react-text-truncate';
import { useHistory } from 'react-router-dom';

import api from '../../../../../services/api';

import Button from '../../../../../components/Button';
import LoadModal from '../../../../../components/LoadModal';

import clothe1 from '../../../../../assets/listCardVertical/clothe1.png';

// import whatsappIcon from '../../../assets/listCardHorizontal/whatsapp-icon.png';
// import facebookIcon from '../../../assets/listCardHorizontal/facebook-icon.png';
// import shareIcon from '../../../assets/listCardHorizontal/share-icon.png';

import {
  Container,
  Clothe,
  SelectedFavoriteHeart,
  AddButton,
  AddBtn,
  Counter,
  ShareButton,
  ButtonShareOptions,
  RemoveButton,
} from './styles';

const Card = ({ data, userList, checkUserLoggedCard }) => {
  const history = useHistory();
  const [showButtonShareOptions, setShowButtonShareOptions] = useState(false);
  const [clickAddButton, setClickAddButton] = useState(false);
  const [showLoadModal, setShowLoadModal] = useState(false);

  function renderFavoriteHeart() {
    if (false === true) {
      return <SelectedFavoriteHeart />;
    }
  }

  function renderCounter() {
    if (false == true) {
      return <Counter>10</Counter>;
    }
  }

  function renderButton() {
    if (false == true) {
      return (
        <ShareButton onClick={() => setShowButtonShareOptions((prev) => !prev)}>
          Compartilhar
        </ShareButton>
      );
    }
  }

  // useEffect(() => {
  //   console.log(data);
  // }, []);

  // function renderButtonShareOptions() {
  //   if (showButtonShareOptions) {
  //     return (
  //       <ButtonShareOptions>
  //         <button>
  //           <img src={whatsappIcon} alt="Ícone do WhatsApp" />
  //         </button>
  //         <button>
  //           <img src={facebookIcon} alt="Ícone do Facebook" />
  //         </button>
  //         <button>
  //           <img src={shareIcon} alt="Ícone de compartilhar" />
  //         </button>
  //       </ButtonShareOptions>
  //     );
  //   }
  // }

  function renderClickAddButton() {
    if (clickAddButton) {
      return (
        <RemoveButton>
          <p>-</p>
          <span>Carrinho</span>
        </RemoveButton>
      );
    }
  }

  function renderTextAddButton() {
    if (clickAddButton) {
      return (
        <AddBtn>
          <p>+</p>
          <span>Add Lista</span>
        </AddBtn>
      );
    }
  }

  async function handleAddList() {
    setShowLoadModal(true);
    await api
      .post(`/lists/${data.id}/add`)
      .then((res) => {
        setShowLoadModal(false);
        history.push(`/minhas-listas`);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }
    setShowLoadModal(false);
  };

  const renderAddButton = () => {
    let userHasAdded;
    if (userList.length) {
      userHasAdded = userList.some((each) => each.id === data.id);
    }

    if (userHasAdded) {
      return (
        <AddButton userHasAdded={userHasAdded} disabled>
          <div>+</div>
        </AddButton>
      );
    }

    return (
      <AddButton
        userHasAdded={userHasAdded}
        onClick={() => checkUserLoggedCard(handleAddList)}
      >
        <div>+</div>
      </AddButton>
    );
  };

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal />;
    }
  };

  return (
    <Container>
      <Clothe>
        <div>
          {/* <p>Lista</p> */}
          <p onClick={() => history.push(`lista/${data.id}`)}>{data.name}</p>
          <TextTruncate
            line={2}
            element="p"
            truncateText="…"
            text={data.description}
          />
        </div>
        {renderFavoriteHeart()}
        {renderCounter()}
        {renderAddButton()}
        {renderButton()}
        {/* {renderButtonShareOptions()} */}
        {renderTextAddButton()}
        {renderClickAddButton()}
      </Clothe>
      {renderLoadModal()}
    </Container>
  );
};

export default Card;
