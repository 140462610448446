import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import api from '../../services/api';

import Header from '../../components/Header';
import MenuProfile from '../../components/MenuProfile';
import Search from './components/Search';
import MyFavoriteProducts from './components/MyFavoriteProducts';
import MyFavoriteLists from './components/MyFavoriteLists';
import Footer from '../../components/Footer';

import userPhoto from '../../assets/profile/user-photo.png';

import {
  Container,
  Content,
  Row,
  Column,
  Name,
  UpdateProfilePhoto,
} from './styles';

const Favorites = () => {
  const user = useSelector((state) => state.User);
  const [combobox, setCombobox] = useState('Products');
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [favoriteLists, setFavoriteLists] = useState([]);

  async function handleFetchFavoriteProduct() {
    await api
      .get(`/users/${user.id}/products/favorited?limit=100000`)
      .then((res) => {
        setFavoriteProducts(res.data.results);
      });
  }

  async function handleFetchFavoriteList() {
    await api
      .get(`/users/${user.id}/lists/favorited?limit=100000`)
      .then((res) => {
        setFavoriteLists(res.data.results);
      });
  }

  function renderList() {
    if (combobox === 'Products') {
      return <MyFavoriteProducts data={favoriteProducts} />;
    }
    return <MyFavoriteLists data={favoriteLists} />;
  }

  useEffect(() => {
    handleFetchFavoriteProduct();
    handleFetchFavoriteList();
  }, []);

  return (
    <Container>
      <Header />
      {/* <Content>
        <Row>
          <img src={userPhoto} alt="Foto de Perfil" />
          <Column>
            <Name>Cecília Oliveira</Name>
            <UpdateProfilePhoto>Alterar foto de Perfil</UpdateProfilePhoto>
            <MenuProfile />
          </Column>
        </Row>
      </Content> */}
      <Search setCombobox={setCombobox} />
      <Content>
        <p>Favoritos</p>
        {renderList()}
      </Content>
      <Footer />
    </Container>
  );
};

export default Favorites;
