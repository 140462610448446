import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import api from '../../services/api';

import LoadModal from '../LoadModal';

import { FavoriteIcon } from './styles';

import UserNotLoggedModalWrap from '../../components/UserNotLoggedModalWrap';
import UserNotLoggedMessage from '../../components/UserNotLoggedModalWrap/UserNotLoggedMessage';

const Favorite = ({ data, type }) => {
  const user = useSelector((state) => state.User);
  const [isFavorite, setIsFavorite] = useState();
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal />;
    }
  };

  async function addFavorite() {
    setShowLoadModal(true);

    let id = '';

    if (type === 'lists') {
      id = data.id;
    } else if (type === 'products') {
      id = data.external_id;
    }

    await api
      .put(`/users/${user.id}/${type}/${id}/favorite`)
      .then((res) => {
        setIsFavorite(true);
        setShowLoadModal(false);
      })
      .catch((err) => {
        setShowLoadModal(false);
      });
  }

  async function deleteFavorite() {
    setShowLoadModal(true);
    let id = '';

    if (type === 'lists') {
      id = data.id;
    } else if (type === 'products') {
      id = data.external_id;
    }

    await api
      .delete(`/users/${user.id}/${type}/${id}/favorite`)
      .then(() => {
        setIsFavorite(false);
        setShowLoadModal(false);
      })
      .catch((err) => {
        setShowLoadModal(false);
      });
  }

  const handleClick = () => {
    if (isFavorite) {
      deleteFavorite();
    } else {
      addFavorite();
    }
  };

  const checkUserLogged = () => {
    if (user.accessToken) {
      handleClick();
    } else {
      setIsModal(true);
    }
  };

  useEffect(() => {
    setIsFavorite(data?.is_favorite);
  }, [data]);

  function checkUserLoggedModal() {
    if (isModal) {
      return (
        <UserNotLoggedModalWrap toggleModal={() => setIsModal(false)}>
          <UserNotLoggedMessage />
        </UserNotLoggedModalWrap>
      );
    }
  }

  return (
    <>
      <FavoriteIcon onClick={() => checkUserLogged()} isFavorite={isFavorite} />
      {renderLoadModal()}
      {checkUserLoggedModal()}
    </>
  );
};

export default Favorite;
