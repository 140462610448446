import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .container__card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;

    width: 100%;
    max-width: 230px;

    span {
      height: 50px;
    }

    .card-wrapper__button-wrapper {
      position: relative;
      width: 80%;

      margin: 0 auto;

      @keyframes tooltip {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 1;
        }
        90% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }

      .button__tooltip {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        z-index: 2;
        bottom: 110%;
        left: 0;
        right: 0;

        min-height: 25px;
        padding: 5px 10px;

        font-size: 1.5rem;
        font-family: 'Izmir-Bold';

        filter: drop-shadow(0 3px 6px rgb(0 0 0 /50%));
        background: #fff;
        border-radius: 5px 5px 5px 0;
        animation: tooltip 3s forwards;
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 99%;

          transform: translateX(-50%);

          width: 0;
          height: 0;

          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-top: 7px solid #fff;
          clear: both;
        }
      }
    }
  }
`;

export const ShareButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 50px;

  border-radius: 6px;
  background-color: #2f78fe;
  font-size: 2rem;
  font-family: 'Izmir-Bold';
  color: #fff;
  border: 0;
  outline: 0;
`;
