import styled from 'styled-components';

export const Modal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  > section {
    align-self: end;
    font-size: 20px;
    margin: 20px 0 20px 61px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-left: 61px;

  > img {
    margin-right: 15px;
  }

  > div {
    font-size: 20px;
    width: 49%;
    color: #2F78FE;
    font-weight: bold;
  }
`;

export const CardInformationContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;

  > img {
    position: absolute;
    left: 35px;
  }
`;

export const Button = styled.button`
  align-items: center;
  background-color: #2f78fe;
  border-radius: 10px;
  border-width: 0px;
  outline: none;
  padding: 10px;
  color: #fff;
  font-size: 20px;
  margin-top: 20px;
  width: 65%;
`;
