import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  height: 480px;
  width: 300px;
  padding: 20px 20px 0px 20px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px lightgrey;
`;

export const Title = styled.p`
  font-family: 'Izmir-Bold';
  font-size: 2.5rem;
  color: #1e6dff;
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Form = styled.form`
  label {
    font-family: 'Izmir-Bold';
    font-size: 1.5rem;
    color: #4a4747;
  }

  > div {
    height: 40px;
    width: 160px;

    > img {
      height: 20px;
    }

    > input {
      margin-left: 0px;
      font-size: 1.6rem;
    }
  }

  input[type='radio'] {
    margin-right: 10px;
  }
`;

export const Tooltip = styled.div`
  font-family: 'Izmir-Bold';
  font-size: 1.6rem;
  margin-top: -10px;
  margin-bottom: -10px;
  color: #f00;
  text-align: center;
`;
