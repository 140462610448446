import Header from '../../components/Header';
import Footer from '../../components/Footer';

import backgroundImg from '../../assets/partners/background-img.png';
import logoTransparent from '../../assets/about/logo-transparent.png';

import { Container, Content, Row, Column, TitleContainer } from './styles';

const Partners = () => {
  return (
    <Container>
      <Header />
      <Content>
        <TitleContainer>
          <img src={logoTransparent} alt="Listz" />
          <div>
            <h1>Nossos parceiros</h1>
          </div>
          <img src={logoTransparent} alt="Listz" />
        </TitleContainer>
        <Row>
          <img src={backgroundImg} />
          <Column>
            <p>Trabalhamos com parcerias de primeira!</p>
            <p>
              Trabalhamos com o melhor varejista online para disponibilizar os
              melhores produtos, preços, prazos e de entrega, e muitas vezes
              frete grátis, principalmente para os assinantes Amazon PRIME.
            </p>
          </Column>
        </Row>
      </Content>
      <Footer />
    </Container>
  );
};

export default Partners;
