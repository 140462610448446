import Header from '../../components/Header';
import Footer from '../../components/Footer';

import logo from '../../assets/about/logo.png';
import shopList from '../../assets/about/shop-list.png';
import logoTransparent from '../../assets/about/logo-transparent.png';

import {
  Container,
  Content,
  Row,
  Column,
  TitleContainer,
  Disclaimer,
} from './styles';

const About = () => {
  return (
    <>
      <Container>
        <Header />
        <Content>
          <TitleContainer>
            <img src={logoTransparent} alt="Listz" />
            <div>
              <img src={logo} alt="Logo Listz" />
              <h1>Sobre nós</h1>
            </div>
            <img src={logoTransparent} alt="Listz" />
          </TitleContainer>
          <Row>
            <img src={shopList} alt="Lista de Compras" />
            <Column>
              <p>Sua plataforma de Listas de compras</p>
              <p>
                Uma nova experiência de compra digital interativa, onde você
                pode criar suas listas personalizadas e compartilhar com seus
                amigos.
              </p>
              <p>Como funciona?</p>
              <p>
                Como participante do Programa de Associados da Amazon, somos
                remunerados pelas compras qualificadas efetuadas. <br />
                <br />
                Parte da remuneração recebida pelo LISTZ volta para você como
                cahsback, e muito em breve poderá voltar como milhas no seu
                programa de fidelidade preferido!
              </p>
              <br />
              <br />
              <Disclaimer>
                DISCLAIMER: O LISTZ ajuda você a escolher os melhores produtos,
                organizar e compartilhar suas listas de compras, mas as compras
                são realizadas diretamente com a Amazon. Quaisquer necessidades
                de troca, cancelamento, devolução, problemas com entregas ou
                demais aspectos relacionados às compras devem ser tratadas
                diretamente através de sua conta no site
                https://www.amazon.com.br/
              </Disclaimer>
            </Column>
          </Row>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default About;
