import CardInformation from '../../../../components/CardInformation';
import Button from '../../../../components/Button';

import smilingIcon from '../../../../assets/home/smilingIcon.png';
import offer from '../../../../assets/cardInformation/offer.png';
import wallet from '../../../../assets/cardInformation/wallet.png';

import {
  Container,
  Content,
  Column,
  Row,
  ContainerCards,
  LinkPage,
} from './styles';

const SignUpToReceiveTreats = () => {
  return (
    <Container>
      <Content>
        <Column>
          <Row>
            <p>Cadastre-se para receber nossos mimos!</p>
            <img src={smilingIcon} alt="Ícone Sorrindo" />
          </Row>
          <LinkPage to="criar-conta">
            <Button text="Cadastre-se agora!" />
          </LinkPage>
        </Column>
        <ContainerCards>
          <CardInformation
            containerStyle={{
              backgroundColor: '#E2ECFF',
              textAlign: 'center',
              justifyContent: 'center',
            }}
            icon={offer}
            title="Ofertas especiais"
            description="Tenha aceso a ofertas especiais e produtos selecionados com os melhores preços."
          />
          <CardInformation
            containerStyle={{
              marginLeft: 20,
              backgroundColor: '#E0FFE0',
              textAlign: 'center',
              justifyContent: 'center',
            }}
            icon={wallet}
            title="CashBack"
            description="Compre e receba uma parte do seu dinheiro de volta."
          />
        </ContainerCards>
      </Content>
    </Container>
  );
};

export default SignUpToReceiveTreats;
