import { Container, Row } from './styles';

const Quantity = ({ quantity, setQuantity }) => {
  const handleQuantity = (operator) => {
    if (operator === '+') {
      setQuantity(quantity + 1);
    } else if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <Container>
      <p>Quantidade</p>
      <Row quantity={quantity}>
        <button onClick={() => handleQuantity('-')}>-</button>
        <div>{quantity}</div>
        <button onClick={() => handleQuantity('+')}>+</button>
      </Row>
    </Container>
  );
};

export default Quantity;
