import React, { useState } from 'react';

import { Container } from './styles';

const Item = ({ children }) => {
  const [focus, setFocus] = useState(false);

  return (
    <Container onClick={() => setFocus(!focus)} focus={focus}>
      {children}
    </Container>
  );
};

export default Item;
