import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  max-width: 1366px;
  width: 100%;
  padding: 20px;

  > p {
    font-family: 'Izmir-Bold';
    font-size: 3.5rem;
    color: #0059ff;
    margin: 10px 0px;
  }
`;

export const Row = styled.div`
  display: flex;

  > img {
    height: 200px;
    width: 200px;
    margin-right: 40px;
  }

  @media (max-width: 55.625rem) {
    align-items: center;
    flex-direction: column;

    img {
      margin-right: 0px;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const Column = styled.div``;

export const Name = styled.p`
  font-family: 'Izmir-Bold';
  font-size: 3.5rem;
  color: #0059ff;
`;

export const UpdateProfilePhoto = styled.p`
  font-family: 'Izmir-Regular';
  font-size: 2.5rem;
  color: #707070;
`;
