import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: #2f78fe;
`;

export const Content = styled.div`
  display: flex;
  padding: 20px;
  max-width: 1336px;
  width: 100%;
  margin: 0 auto;

  > div > p {
    font-family: 'Izmir-Bold';
    font-size: 2rem;
    color: #fff;
  }

  @media (max-width: 27.5rem) {
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > div > p {
      text-align: center;
    }
  }
`;
