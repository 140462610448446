import { Container, Image, TextInput, IconRight } from './styles';

const Input = ({
  icon,
  alt,
  type,
  placeholder,
  containerStyle,
  containerIconStyle,
  iconRight,
  disabled,
  ...props
}) => {
  function renderIconLeft() {
    if (icon !== undefined) {
      return <Image style={containerIconStyle} src={icon} alt={alt} />;
    }
  }

  function renderIconRight() {
    if (iconRight !== undefined) {
      return <IconRight style={containerIconStyle} src={iconRight} alt={alt} />;
    }
  }

  return (
    <Container style={containerStyle}>
      {renderIconLeft()}
      <TextInput placeholder={placeholder} type={type} disabled={disabled} {...props} />
      {renderIconRight()}
    </Container>
  );
};

export default Input;
