import Header from '../../components/Header';
import { useSelector } from 'react-redux';
import Footer from '../../components/Footer';
import ListsMadeForYou from './components/ListsMadeForYou';
//import Suggestions from './components/Suggestions';
import Description from './components/Description';
import SignUpToReceiveTreats from './components/SignUpToReceiveTreats';
import Lists from './components/Lists';

import { Container, Content } from './styles';

const Home = () => {
  const user = useSelector((state) => state.User);

  const renderSignUpToReceiveTreats = () => {
    if (user.accessToken) {
      return;
    } else {
      return <SignUpToReceiveTreats />
    }
  }

  return (
    <Container>
      <Header />
      <Content>
        <Lists />
        <Description />
        {renderSignUpToReceiveTreats()}
        {/*<Suggestions />*/}
      </Content>
      <Footer />
    </Container>
  );
};

export default Home;
