import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  width: 100%;
`;

export const Content = styled.div`
  > button {
    margin: 0 auto;
    max-width: 400px;
    margin-bottom: 40px;
  }
`;

export const MessageEmptyCart = styled.p`
  font-family: 'Izmir-Bold';
  font-size: 4rem;
  margin: 20px auto 40px auto;
  text-align: center;
  color: #2f78fe;
`;
