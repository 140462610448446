import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.menu`
  display: ${(props) => (props.showFilter ? 'flex' : 'none')};
  justify-content: flex-start;
  flex-wrap: wrap;
  position: absolute;
  width: 720px;
  padding: 30px 60px 10px 60px;
  background-color: #fff;
  top: 125px;
  left: 50%;
  transition: all 1s;
  transform: translateX(-50%);
  box-shadow: 0px 0px 6px 0px lightgrey;

  > div:nth-of-type(1) {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  @media (max-width: 55.625rem) {
    width: 600px;
  }

  @media (max-width: 42.5rem) {
    width: 90vw;
    padding: 60px 20px 10px 20px;
  }
`;

export const LinkPage = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
