import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';

import api from '../../../../services/api';

import './carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Card from './Card';
import ButtonCreateList from './ButtonCreateList';
import CreateListModal from '../../../../components/CreateListModal';
import UserNotLoggedModalWrap from '../../../../components/UserNotLoggedModalWrap';
import UserNotLoggedMessage from '../../../../components/UserNotLoggedModalWrap/UserNotLoggedMessage';

import logoTransparent from '../../../../assets/cart/logo-transparent.png';

import { Container, Content } from './styles';

const sliderSettings = {
  className: 'center',
  centerMode: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  speed: 500,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

const Lists = () => {
  const user = useSelector((state) => state.User);
  const [lists, setLists] = useState([]);
  const [userList, setUserList] = useState([]);
  const [showCreateListModal, setShowCreateListModal] = useState(false);
  const [isModal, setIsModal] = useState(false);

  async function fetchLists() {
    await api
      .get('/lists/highlights')
      .then((res) => {
        setLists(res.data.lists);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function fetchUserList() {
    if (!user.id.trim()) return;

    await api
      .get(`/users/${user.id}/lists`)
      .then((res) => {
        setUserList(res.data.lists);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }
  };

  useEffect(() => {
    fetchLists();
    fetchUserList();
  }, []);

  const renderListCards = () => {
    let cards = lists.map((item) => (
      <Card
        data={item}
        userList={userList}
        setUserList={setUserList}
        checkUserLoggedCard={checkUserLoggedCard}
      />
    ));

    if (lists.length <= 3) {
      cards = [...cards, ...cards, ...cards, ...cards];
    }

    return cards;
  };

  const checkUserLoggedCard = (handleAddList) => {
    if (user.accessToken) {
      handleAddList();
    } else {
      setIsModal(true);
    }
  };

  const checkUserLogged = () => {
    if (user.accessToken) {
      setShowCreateListModal(true);
    } else {
      setIsModal(true);
    }
  };

  function checkUserLoggedModal() {
    if (isModal) {
      return (
        <UserNotLoggedModalWrap toggleModal={() => setIsModal(false)}>
          <UserNotLoggedMessage />
        </UserNotLoggedModalWrap>
      );
    }
  }

  const renderCreateListModal = () => {
    if (showCreateListModal) {
      return <CreateListModal setVisible={setShowCreateListModal} />;
    }
  };

  return (
    <Container>
      <img src={logoTransparent} alt="Logo transparent do Listz" />
      <Content>
        <p>Listas feitas para você</p>
        <Slider {...sliderSettings}>{renderListCards()}</Slider>
        <ButtonCreateList onClick={() => checkUserLogged()} />
      </Content>
      <img src={logoTransparent} alt="Logo transparent do Listz" />
      {renderCreateListModal()}
      {checkUserLoggedModal()}
    </Container>
  );
};

export default Lists;
