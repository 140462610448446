import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

import IzmirBlack from '../assets/fonts/Izmir-Black.otf';
import IzmirBold from '../assets/fonts/Izmir-Bold.otf';
import IzmirRegular from '../assets/fonts/Izmir-Regular.otf';
import IzmirMedium from '../assets/fonts/Izmir-Medium.otf';
import PoppinsMedium from '../assets/fonts/Poppins-Medium.ttf';
import PoppinsRegular from '../assets/fonts/Poppins-Regular.ttf';
import IzmirLight from '../assets/fonts/Izmir-Light.otf';

export default createGlobalStyle`

  @font-face {
    font-family: Izmir-Black;
    src: local('Izmir-Black'), url(${IzmirBlack});
  }

  @font-face {
    font-family: Izmir-Bold;
    src: local('Izmir-Bold'), url(${IzmirBold});
  }

  @font-face {
    font-family: Izmir-Regular;
    src: local('Izmir-Regular'), url(${IzmirRegular});
  }

  @font-face {
    font-family: Izmir-Medium;
    src: local('Izmir-Medium'), url(${IzmirMedium});
  }

  @font-face {
    font-family: Poppins-Medium;
    src: local('Poppins-Medium'), url(${PoppinsMedium});
  }

  @font-face {
    font-family: Poppins-Regular;
    src: local('Poppins-Regular'), url(${PoppinsRegular});
  }

  @font-face {
    font-family: Izmir-Light;
    src: local('Izmir-Light'), url(${IzmirLight});
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 8px;
  }

  body{
    font-family: 'Ubuntu', sans-serif;
  }

  /*@media (max-width: 97.94rem){
    html {
      font-size: 8px;
    }
  }*/

  @media (max-width: 50rem){
    html {
      font-size: 7px;
    }
  }

  @media (max-width: 26.25rem){
    html {
      font-size: 6px;
    }
  }

  @media (max-width: 15rem){
    html {
      font-size: 4px;
    }
  }


`;
