function nameValidation(name) {
  if (
    name.includes('1') ||
    name.includes('2') ||
    name.includes('3') ||
    name.includes('4') ||
    name.includes('5') ||
    name.includes('6') ||
    name.includes('7') ||
    name.includes('8') ||
    name.includes('9') ||
    name.includes('10')
  ) {
    return false;
  }
  return true;
}

export default nameValidation;
