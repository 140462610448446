import { Container, Row } from './styles';

const Button = ({ containerStyle, icon, text }) => {
  return (
    <Container style={containerStyle}>
      <Row>
        <img src={icon} alt={text} />
        <div>
          <div>Download</div>
          <div>{text}</div>
        </div>
      </Row>
    </Container>
  );
};

export default Button;
