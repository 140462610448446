import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
`;

export const Content = styled.div`
  max-width: 1336px;
  width: 100%;
  padding: 80px 20px;
`;

export const Row = styled.div`
  display: flex;

  > img {
    height: 200px;
    width: 200px;
    margin-right: 40px;
  }

  @media (max-width: 55.625rem) {
    align-items: center;
    flex-direction: column;

    img {
      margin-right: 0px;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const Column = styled.div`
  > div:nth-of-type(2) {
    margin-top: 20px;
  }

  .withdrawInput {
    font-size: 2.4rem;
    font-family: 'Izmir-Regular';
    color: #c5c5c5;
    width: 100%;
    height: 50px;
    background-color: #f6f6f6;
    border-radius: 10px;
    margin: 0px 0px;
    padding: 0px 20px;
    padding-right: 48px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 19%);
    border: 0;
    outline: none;
  }
`;

export const Name = styled.p`
  font-family: 'Izmir-Bold';
  font-size: 3.5rem;
  color: #0059ff;
`;

export const UpdateProfilePhoto = styled.p`
  font-family: 'Izmir-Regular';
  font-size: 2.5rem;
  color: #707070;
`;

export const Form = styled.form`
  > h1 {
    font-family: 'Izmir-Bold';
    font-size: 4rem;
    color: #1e6dff;
    margin-top: 40px;
  }

  > div {
    display: flex;
  }

  > section {
    font-family: 'Izmir-Regular';
    font-size: 2.5rem;
    color: #1c1c1c;
    width: 240px;

    > p {
      margin-top: 10px;
    }

    > input {
      width: 240px;
    }
  }

  > p {
    font-family: 'Izmir-Bold';
    font-size: 2.4rem;
    color: #9fa0a2;
    margin-top: 60px;
    margin-bottom: 20px;
  }

  > div:nth-of-type(1) {
    > div:nth-of-type(1) {
      margin-right: 40px;
    }

    > div {
      width: 240px;
      p {
        font-family: 'Izmir-Regular';
        font-size: 2.5rem;
        color: #1c1c1c;
      }
      span {
      }
    }
  }

  > div:nth-of-type(2) {
    > div:nth-of-type(2) {
      margin-left: 40px;
    }
  }

  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    width: 519px;

    > button {
      margin-top: 40px;
      width: 519px;
    }

    > span {
      margin-top: 10px;
      color: red;
      font-size: 20px;
      align-self: center;
    }

    > p {
      margin-top: 10px;
      font-size: 20px;
      align-self: center;
    }
  }

  @media (max-width: 56.625rem) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > button {
      margin-top: 40px;
      width: 527px;
    }

    > div:nth-of-type(1) {
      > div {
        width: 220px;
      }
    }

    > section {
      width: 220px;
    }
  }

  @media (max-width: 37.5rem) {
    > div:nth-of-type(1) {
      flex-direction: column;
      text-align: center;

      > div:nth-of-type(1) {
        margin-right: 0px;
      }
    }

    > div:nth-of-type(2) {
      > div:nth-of-type(2) {
        margin-top: 20px;
        margin-left: 0px;
      }
      flex-direction: column;
    }
  }

  @media (max-width: 56.625rem) {
    > div:nth-of-type(2) {
      width: 100%;
      > button {
        width: 100%;
      }
    }
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    font-size: 2.95rem;
    font-family: Izmir-Bold;
    color: #00a30b;
    width: 400px;
    margin-bottom: 14px;
    text-align: center;
  }

  > h3 {
    font-size: 2.25rem;
    margin-top: 20px;
  }

  > p {
    font-size: 1.37rem;
    font-family: Izmir-Medium;
    color: #0000ff;
    margin-bottom: 18.4px;
  }

  > a {
    color: #06e406;
    font-size: 1.37rem;
    font-family: Izmir-Medium;
    margin-top: 18.4px;
  }
`;
