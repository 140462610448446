import styled from 'styled-components';

export const Container = styled.section`
  background: rgba(000, 000, 000, 0.3);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2001;
  > div {
    padding: 20px 10px 20px 10px;
    background: #F2F7FF;
    opacity: initial;
    position: relative;
    .closeModal {
      border: none;
      background: transparent;
    }
  }
  @media (max-width: 768px) {
    > div {
      padding: 38.8px 40px 60.4px;
    }
  }

  @media (max-width: 450px) {
    > div {
      padding: 38.8px 8px 60.4px;
    }
  }
`;
