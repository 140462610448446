import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../../../services/api';
import { LoadingOutlined } from '@ant-design/icons';

import Size from './components/Size';
import Quantity from './components/Quantity';
import Button from '../../../../../components/Button';
import FavoriteIcon from '../../../../../components/Favorite';

import clothe1 from '../../../../../assets/listCardVertical/clothe1.png';

import {
  Container,
  Clothe,
  FavoriteContainer,
  AddButton,
  Informations,
  Price,
  Row,
  Column,
  Image,
} from './styles';

const Card = ({ product, removeProductLoading, setRemoveProductLoading }) => {
  const user = useSelector((state) => state.User);

  const dispatch = useDispatch();
  const history = useHistory();
  const [size, setSize] = useState('');
  const [quantity, setQuantity] = useState(1);

  const formatPrice = () => {
    const price = parseInt(product?.unit_price).toFixed(2).replace('.', ',');

    return `R$ ${price}`;
  };

  const handleRemoveProductCart = async () => {
    setRemoveProductLoading(true);

    dispatch({
      type: 'listz-web/user/remove-product-cart',
      payload: product,
    });

    user.accessToken && removeProduct();
  };

  const removeProduct = async () => {
    try {
      const response = api.delete(`/items/${product.id}`);

      if (response) {
        toast.success('Produto removido!');
      }
    } catch (error) {
      handleError(error?.data?.message || error.toString());
    } finally {
      setRemoveProductLoading(false);
    }
  };

  const handleError = (error) => {
    toast.error(error);
  };

  const renderPrice = () => {
    if (!product?.is_available) {
      return (
        <Price>
          <p>Produto indisponível</p>
        </Price>
      );
    }

    if (product.unit_price) {
      return (
        <Price>
          <label>Por apenas:</label>
          <p>{formatPrice(product.unit_price)}</p>
        </Price>
      );
    }
  };

  return (
    <Container>
      <Informations>
        <p onClick={() => history.push(`/produto/${product.external_id}`)}>
          {product.name}
        </p>
        {/* <p>Modelo - FXR</p>
        <p>
          Quente e confortável, esta jaqueta te ajuda a aproveitar o ski nas
          melhores condições.
        </p> */}
        <Row>
          <Column>
            {renderPrice()}
            <Button
              text="Ver produto"
              onClick={() => history.push(`/produto/${product.external_id}`)}
            />
            <Button
              text="Remover do carrinho"
              onClick={() => handleRemoveProductCart()}
            />
          </Column>
          <Column>
            {/* <Size size={size} setSize={setSize} /> */}
            <Quantity quantity={product.quantity} setQuantity={setQuantity} />
          </Column>
        </Row>
      </Informations>
      <Clothe>
        {/* <FavoriteContainer>
          <FavoriteIcon data={product} type="products" />
        </FavoriteContainer> */}
        <Image
          onClick={() => history.push(`/produto/${product.external_id}`)}
          src={product.image_url}
        />
        {/* <AddButton>
          <p>+</p>
        </AddButton> */}
      </Clothe>
    </Container>
  );
};

export default Card;
