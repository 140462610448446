import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: ${(props) => (props.showSideMenu ? 0 : -300)}px;
  transition: left 1s;
  width: 300px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px lightgrey;

  @media (max-width: 41.25rem) {
    width: 220px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  height: 120px;
  background-color: #edf1fc;

  > div {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  > div > div {
    display: flex;
    flex-direction: column;

    > a:nth-of-type(1) {
      font-family: 'Izmir-Bold';
      font-size: 2.5rem;
      color: #6a6f77;
    }

    > p:nth-of-type(1) {
      font-family: 'Izmir-Bold';
      font-size: 2.5rem;
      color: #6a6f77;
    }

    > p:nth-of-type(2) {
      font-family: 'Izmir-Bold';
      font-size: 2rem;
      color: #0059ff;
    }
  }
`;

export const ProfilePhoto = styled.img`
  height: 60px;
  border-radius: 6px;
  margin-right: 10px;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
`;

export const Row = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px;
  text-decoration: none;

  margin-left: 0px;

  img {
    width: 30px;
  }

  div {
    font-family: 'Izmir-Medium';
    font-size: 2rem;
    color: #000000;
    margin-left: 20px;
  }

  @media (max-width: 41.25rem) {
    padding: 10px 20px;

    img {
      height: 40px;
    }
  }

  @media (max-width: 720px) {
    img {
      height: 30px;
    }
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #c9c9c9;
  margin: 10px 0px;

  @media (max-width: 41.25rem) {
    margin: 10px 0px;
  }
`;

export const LinkPage = styled(Link)`
  color: inherit;
  text-decoration: none;
`;
