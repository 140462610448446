import { Container, Row, Button } from './styles';
import { Link } from 'react-router-dom';

const MenuProfile = () => {
  return (
    <Container>
      <Row>
        <Link to="/minhas-listas">
          <Button>Listas</Button>
        </Link>
        <Link to="/cashback">
          <Button>Cashback</Button>
        </Link>
        <Link to="/favoritos">
          <Button>Favoritos</Button>
        </Link>
      </Row>
    </Container>
  );
};

export default MenuProfile;
