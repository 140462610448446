import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Divider = styled.div`
  margin: 80px 0px;
  width: 100%;
  height: 1px;
  background-color: #c9c9c9;
  opacity: 0.38;

  @media (max-width: 90rem) {
    margin: 50px 0px;
  }

  @media (max-width: 25.875rem) {
    margin: 0px 0px;
  }
`;
