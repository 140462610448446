import styled from 'styled-components';
import { FaHeart } from 'react-icons/fa';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;

  @media (max-width: 30rem) {
    flex-direction: column;

    > div:nth-of-type(2) {
      margin-top: 40px;
    }
  }
`;

export const ClotheCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 300px;
  width: 240px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 12px 0px lightgrey;

  @media (max-width: 90rem) {
    height: 270px;
    width: 210px;
  }

  @media (max-width: 41.25rem) {
    height: 220px;
    width: 180px;
  }
`;

export const ClotheImg = styled.img`
  max-height: 183px;
  max-width: 183px;
`;

export const FavoriteContainer = styled.div`
  > svg {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 4rem;

    @media (max-width: 15rem) {
      right: 10px;
    }
  }
`;

export const AddButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -20px;
  right: -20px;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #b1b1b1;

  > p {
    color: #fff;
    font-size: 8rem;
  }

  @media (max-width: 90rem) {
    height: 90px;
    width: 90px;
    bottom: -10px;
    right: -10px;
  }

  @media (max-width: 50rem) {
    height: 80px;
    width: 80px;
    bottom: -10px;
    right: -10px;
  }

  @media (max-width: 25.875rem) {
    height: 60px;
    width: 60px;
    bottom: -4px;
    right: -4px;
  }

  @media (max-width: 20rem) {
    height: 50px;
    width: 50px;
  }

  @media (max-width: 15rem) {
    height: 40px;
    width: 40px;
  }
`;

export const Informations = styled.div`
  width: 840px;
  margin-left: 120px;
  > p:nth-of-type(1) {
    font-family: 'Izmir-Bold';
    color: #1e6dff;
    font-size: 3.4rem;
    cursor: pointer;
  }

  > p:nth-of-type(2) {
    font-family: 'Izmir-Bold';
    color: #000000;
    font-size: 2.4rem;
  }

  > p:nth-of-type(3) {
    font-family: 'Izmir-Regular';
    color: #000000;
    font-size: 2.4rem;
  }

  @media (max-width: 90rem) {
    margin-left: 60px;
    width: 440px;
  }

  @media (max-width: 50rem) {
    margin-left: 50px;
    width: 360px;
  }

  @media (max-width: 41.25rem) {
    margin-left: 40px;
    width: 280px;
  }

  @media (max-width: 33.75rem) {
    margin-left: 40px;
    width: 210px;
  }

  @media (max-width: 30rem) {
    width: 100%;
  }
`;

export const Price = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;

  > label {
    font-family: 'Poppins-Regular';
    color: #807f7f;
    font-size: 1.8rem;
  }

  > p {
    font-family: 'Izmir-Bold';
    color: #4a4747;
    font-size: 3.6rem;
  }

  @media (max-width: 25.875rem) {
    margin-top: 20px;
  }
`;

export const OfferIcon = styled.img`
  position: absolute;
  top: -10px;
  height: 80px;

  @media (max-width: 41.25rem) {
    height: 60px;
  }
`;

export const TextOffer = styled.div`
  width: 60px;
  height: 20px;
  background-color: #06e406;
  color: #fff;
  font-family: 'Izmir-Bold';
  font-size: 1.5rem;
  text-align: center;
`;

export const PriceOffer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;

  > span:nth-of-type(1) {
    font-family: 'Izmir-Regular';
    font-size: 1.5rem;
    color: #807f7f;
  }

  > div:nth-of-type(1) {
    > span:nth-of-type(1) {
      font-family: 'Poppins-Regular';
      font-size: 1.5rem;
      color: #807f7f;
    }

    > span:nth-of-type(2) {
      font-family: 'Izmir-Bold';
      font-size: 3.5rem;
      color: #2f78fe;
      margin-left: 10px;
    }
  }

  @media (max-width: 25.875rem) {
    margin-top: 20px;
  }
`;
