import { Container } from './styles';

const Card = ({ containerStyle, icon, title, description }) => {
  return (
    <Container style={containerStyle}>
      <img src={icon} />
      <p>{title}</p>
      <p>{description}</p>
    </Container>
  );
};

export default Card;
