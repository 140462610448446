import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100px;
  background-color: #edf1fc;
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  > span {
    font-family: 'Izmir-Medium';
    font-size: 2.5rem;
    color: #fff;
    background-color: #06e406;
    border-radius: 20px;
    width: 180px;
    text-align: center;
    padding: 6px;
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #06e406;

  color: #fff;
  font-size: 4rem;

  /* @media (max-width: 68.5rem) {
    height: 70px;
    width: 70px;
  }

  @media (max-width: 34rem) {
    height: 60px;
    width: 60px;
  } */
`;
