import React, { useEffect, useState, useRef } from 'react';

import { Container } from './styles';

const Description = ({ description }) => {
  const [showDescription, setShowDescription] = useState(false);
  const [descriptionDivHeight, setdescriptionDivHeight] = useState();

  const ref = useRef(null);

  useEffect(() => {
    ref.current && setdescriptionDivHeight(ref?.current?.clientHeight);
  }, [description]);

  const listDescriptionWrapper = () => {
    return descriptionDivHeight > 60
      ? ' description-wrapper description-wrapper--large'
      : 'description-wrapper';
  };

  const colapseOrExpand = () => {
    return showDescription ? 'description--expand' : 'description';
  };
  return (
    <Container className="description">
      <span className={listDescriptionWrapper()} ref={ref}>
        <span className={colapseOrExpand()}>{description && description}</span>

        <span
          className="description__see-more"
          onClick={() => setShowDescription(!showDescription)}
        >
          {!showDescription ? 'ver mais...' : 'ver menos...'}
        </span>
      </span>
    </Container>
  );
};

export default Description;
