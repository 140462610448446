/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import api from '../../services/api';

import Title from './components/Title';
import ListCards from './components/ListCards';

import Header from '../../components/Header';
import Button from '../../components/Button';
import LoadModal from '../../components/LoadModal';
import Footer from '../../components/Footer';

import { Container, Content, MessageEmptyCart } from './styles';
import { toast } from 'react-toastify';

const Cart = () => {
  const user = useSelector((state) => state.User);
  const [productsCart, setProductsCart] = useState([]);
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [addProductLoading, setAddProductLoading] = useState(false);
  const [removeProductLoading, setRemoveProductLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    !user.accessToken && getProductsFromLocalStorage();
    user.accessToken && getCartProducts();
  }, [removeProductLoading]);

  const getProductsFromLocalStorage = () => {
    const products = user.cart;

    setProductsCart(products);
  };

  const getCartProducts = async () => {
    setCartLoading(true);

    try {
      const response = await api.get(`/items`);

      if (response) {
        setProductsCart(response.data.results);
      }
    } catch (error) {
      handleError(error.response?.data?.message || error.toString());
    } finally {
      setCartLoading(false);
    }
  };

  const handleError = (error) => {
    toast.error(error);
  };

  async function handleSubmitCart() {
    if (productsCart.length) {
      const products = productsCart.map((product) => ({
        name: product.name,
        external_id: product.external_id,
        quantity: product.quantity,
        unit_price: product.unit_price,
        item_url: product.item_url,
      }));

      setShowLoadModal(true);

      setAddProductLoading(true);
      await api
        .post('/carts', { products })
        .then((res) => {
          setShowLoadModal(false);
          setAddProductLoading(false);
          window.open(res.data.link);
        })
        .catch((error) => {
          handleError(error.response?.data?.message || error.toString());
          setShowLoadModal(false);
          setAddProductLoading(false);
        });
    }
  }

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal />;
    }
  };

  const renderButtonFinishCart = () => {
    if (productsCart.length) {
      return (
        <Button text="Finalizar compra" onClick={() => handleSubmitCart()} />
      );
    }
  };

  const renderMessageEmptyCart = () => {
    if (!productsCart.length) {
      return (
        <MessageEmptyCart>
          {!cartLoading
            ? 'Você não possui nenhum item no carrinho'
            : 'Carregando...'}
        </MessageEmptyCart>
      );
    }
  };

  return (
    <Container>
      <Header />
      <Content>
        <Title />
        <ListCards
          listProducts={productsCart}
          removeProductLoading={removeProductLoading}
          setRemoveProductLoading={setRemoveProductLoading}
        />
        {renderButtonFinishCart()}
        {renderMessageEmptyCart()}
      </Content>
      <Footer />
      {renderLoadModal()}
    </Container>
  );
};

export default Cart;
