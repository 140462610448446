import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import api from '../../../../services/api';

import Card from './components/Card';
import LoadModal from '../../../../components/LoadModal';

import logoTransparent from '../../../../assets/cart/logo-transparent.png';
import backBtn from '../../../../assets/listDetails/backBtn.png';

import UserNotLoggedModalWrap from '../../../../components/UserNotLoggedModalWrap';
import UserNotLoggedMessage from '../../../../components/UserNotLoggedModalWrap/UserNotLoggedMessage';
import EditListModal from '../../../../components/EditListModal';

import { Container, Content, Button } from './styles';

const Details = ({ fetchList, data }) => {
  const user = useSelector((state) => state.User);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [showEditListModal, setShowEditListModal] = useState(false);

  const handleAddListToCart = async () => {
    const productArr = data.products.map((product) => ({
      name: product.title,
      external_id: product.external_id,
      quantity: 1,
      unit_price: product.price,
      item_url: product.item_url,
      image_url: product.image_url,
      is_available: product.is_available,
    }));

    setShowLoadModal(true);

    await api
      .post('/items', { products: productArr })
      .then((res) => {
        setShowLoadModal(false);
        toast.success('Produtos da lista adicionados ao carrinho!');
      })
      .catch((err) => {
        setShowLoadModal(false);
        handleError(err);
      });
  };

  const handleRemoveList = async () => {
    setShowLoadModal(true);
    await api
      .delete(`/lists/${data.id}`)
      .then((res) => {
        setShowLoadModal(false);
        history.push('/minhas-listas');
      })
      .catch((error) => handleError(error));
  };

  const handleError = async (error) => {
    toast.error(error.response?.data?.message || error.toString());
    setShowLoadModal(false);
  };

  const renderRemoveButton = () => {
    if (data.user_id === user.id) {
      return (
        <Button text="Remover Lista" onClick={() => checkUserLoggedRemove()} />
      );
    }
  };

  const handleEditList = async () => {
    setShowEditListModal(true);
  };

  const renderEditButton = () => {
    if (data.user_id === user.id) {
      return (
        <Button text="Editar Lista" onClick={() => checkUserLoggedEdit()} />
      );
    }
  };

  function renderEditListModal() {
    if (showEditListModal) {
      return (
        <EditListModal
          fetchList={fetchList}
          selectedList={data}
          setVisible={setShowEditListModal}
        />
      );
    }
  }

  const checkUserLoggedBuy = () => {
    if (user.accessToken) {
      return;
    } else {
      setIsModal(true);
    }
  };

  const checkUserLoggedEdit = () => {
    if (user.accessToken) {
      handleEditList();
    } else {
      setIsModal(true);
    }
  };

  const checkUserLoggedRemove = () => {
    if (user.accessToken) {
      handleRemoveList();
    } else {
      setIsModal(true);
    }
  };

  const checkUserLoggedFavorite = () => {
    if (user.accessToken) {
      return;
    } else {
      setIsModal(true);
    }
  };

  function checkUserLoggedModal() {
    if (isModal) {
      return (
        <UserNotLoggedModalWrap toggleModal={() => setIsModal(false)}>
          <UserNotLoggedMessage />
        </UserNotLoggedModalWrap>
      );
    }
  }

  const renderNumberProducts = () => {
    let numberProducts = data.products.length;

    if (numberProducts > 1) {
      return <p>Esta lista contém {numberProducts} itens</p>;
    } else if (numberProducts === 1) {
      return <p>Esta lista contém {numberProducts} item</p>;
    } else {
      return <p>Esta lista não contém nenhum item</p>;
    }
  };

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal />;
    }
  };

  return (
    <Container>
      <div className="backBtnContainer" onClick={() => history.goBack()}>
        <img
          style={{ cursor: 'pointer' }}
          src={backBtn}
          alt="Botão de Voltar"
        />
      </div>
      <img src={logoTransparent} alt="Logo transparent do Listz" />
      <Content>
        <Card data={data} checkUserLoggedFavorite={checkUserLoggedFavorite} />
        <div>
          {renderNumberProducts()}
          <div>
            <Button
              text="Comprar Lista"
              onClick={() => handleAddListToCart()}
            />
            {renderRemoveButton()}
            {renderEditButton()}
          </div>
        </div>
      </Content>
      <img src={logoTransparent} alt="Logo transparent do Listz" />
      {renderLoadModal()}
      {renderEditListModal()}
      {checkUserLoggedModal()}
    </Container>
  );
};

export default Details;
