import { useHistory } from 'react-router-dom';

import { Container, Counter } from './styles';

import Logo from '../../../../../../assets/header/logo@2x.png'

const Card = ({ data }) => {
  const history = useHistory();

  return (
    <Container>
      <span onClick={() => history.push(`/lista/${data.id}`)}>{data.name}</span>
      <div>
        <img src={data.image ? data.image_url : Logo} alt="imagem da lista"/>
      </div>
      {/* <img src={book} alt="Livro" /> */}
      <Counter>{data.products.length}</Counter>
    </Container>
  );
};

export default Card;
