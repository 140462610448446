import styled from 'styled-components';
import { default as ButtonGeneric } from '../../../../components/Button';

export const Container = styled.div`
  padding: 40px 0px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 27.5rem) {
    flex-direction: column-reverse;
  }
`;

export const Informations = styled.div`
  max-width: 400px;
  width: 100%;
  margin-right: 40px;

  > p:nth-of-type(1) {
    font-family: 'Izmir-Bold';
    font-size: 3.5rem;
    color: #000;
    cursor: pointer;
  }

  > p {
    font-family: 'Izmir-Medium';
    font-size: 2.5rem;
    color: #707070;
  }

  @media (max-width: 440px) {
    text-align: center;
  }

  @media (max-width: 33.75rem) {
    margin-right: 0px;
  }

  @media (max-width: 458px) {
    width: 100%;
  }
`;

export const Button = styled(ButtonGeneric)`
  @media (max-width: 27.5rem) {
    margin: 20px auto;
  }
`;
