import React from 'react';

import { Container } from './styles';
import close from '../../assets/notLogged/close.png';

const UserNotLoggedModalWrap = ({ children, toggleModal }) => {
  return (
    <Container>
      <div>
        <button type="button" className="closeModal" onClick={toggleModal}>
          <img src={close} />
        </button>
        {children}
      </div>
    </Container>
  );
};

export default UserNotLoggedModalWrap;
