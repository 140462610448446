import styled from 'styled-components';

export const Container = styled.span`
  &.description {
    .description-wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .description-wrapper--large {
      .description__see-more {
        display: flex;
      }
    }

    .description {
      overflow: hidden;

      max-height: 70px !important;
      transition: all 0.5s ease-in-out;
    }

    .description--expand {
      max-height: 999999px !important;
    }

    .description__see-more {
      display: none;
      justify-content: center;
      align-items: center;

      width: 90px;
      min-height: 30px;

      margin: 5px 5px 0 auto;
      padding: 2px 5px;

      color: #fff;
      font-size: 12px;

      cursor: pointer;
      background-color: #2f78fe;
      border-radius: 5px;
      box-shadow: 0 3px 6px rgb(0 0 0 /16%);
    }
  }
`;
