import styled from 'styled-components';
import { FaHeart } from 'react-icons/fa';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  min-height: 152px;
  background-color: #fff;
  border-radius: 10px;

  > div {
    /* width: 160px; */
    > p:nth-of-type(1) {
      font-family: 'Izmir-Bold';
      font-size: 1.5rem;
    }

    > h1 {
      font-family: 'Izmir-Bold';
      font-size: 3rem;
      color: #0059ff;
    }

    > p:nth-of-type(2) {
      font-family: 'Izmir-Medium';
      font-size: 1.5rem;
      color: #707070;
    }
  }

  > img {
    position: absolute;
    height: 140px;
    right: 20px;
    bottom: 0;
  }

  .description-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .description-wrapper--large {
    .description__see-more {
      display: flex;
    }
  }

  .description {
    overflow: hidden;

    max-height: 70px !important;
    transition: all 0.5s ease-in-out;
  }

  .description--expand {
    max-height: 999999px !important;
  }

  .description__see-more {
    display: none;
    justify-content: center;
    align-items: center;

    width: 90px;
    min-height: 30px;

    margin: 5px 5px 0 auto;
    padding: 2px 5px;

    color: #fff;
    font-size: 12px;

    cursor: pointer;
    background-color: #2f78fe;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgb(0 0 0 /16%);
  }

  @media (max-width: 540px) {
    width: 260px;
  }
`;

export const FavoriteContainer = styled.div`
  > svg {
    position: absolute;
    top: 8px;
    right: 8px;
  }
`;
