import { useState, useRef, useEffect } from 'react';

import FavoriteIcon from '../../../../../../components/Favorite';

import { Container, FavoriteContainer } from './styles';

const Card = ({ data, checkUserLoggedFavorite }) => {
  const [showDescription, setShowDescription] = useState(false);
  const [descriptionDivHeight, setdescriptionDivHeight] = useState();

  const ref = useRef(null);

  useEffect(() => {
    ref.current && setdescriptionDivHeight(ref?.current?.clientHeight);
  }, [data.description]);

  const listDescriptionWrapper = () => {
    return descriptionDivHeight > 60
      ? ' description-wrapper description-wrapper--large'
      : 'description-wrapper';
  };

  const colapseOrExpand = () => {
    return showDescription ? 'description--expand' : 'description';
  };

  return (
    <Container>
      <div>
        <p>Lista</p>
        <h1>{data.name}</h1>
        <div className={listDescriptionWrapper()} ref={ref}>
          <div className={colapseOrExpand()}>{data && data.description}</div>

          <div
            className="description__see-more"
            onClick={() => setShowDescription(!showDescription)}
          >
            {!showDescription ? 'ver mais...' : 'ver menos...'}
          </div>
        </div>
      </div>
      <FavoriteContainer>
        <FavoriteIcon data={data} type="lists" />
      </FavoriteContainer>
    </Container>
  );
};

export default Card;
