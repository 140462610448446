import { Select as SelectAntd } from 'antd';

import { Container } from './styles';

const Combobox = ({ setCombobox }) => {
  const { Option } = SelectAntd;

  return (
    <Container>
      <SelectAntd
        defaultValue="Products"
        bordered={false}
        onChange={(value) => setCombobox(value)}
      >
        <Option value="Products">Meus produtos favoritos</Option>
        <Option value="Lists">Minhas listas favoritas</Option>
      </SelectAntd>
    </Container>
  );
};

export default Combobox;
