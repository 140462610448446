import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../../../../../services/api';

import FavoriteIcon from '../../../../../../components/Favorite';

import clothe1 from '../../../../../../assets/listCardVertical/clothe1.png';
import whatsappIcon from '../../../../../../assets/listCardHorizontal/whatsapp-icon.png';
import facebookIcon from '../../../../../../assets/listCardHorizontal/facebook-icon.png';
import shareIcon from '../../../../../../assets/listCardHorizontal/share-icon.png';
import Logo from '../../../../../../assets/header/logo@2x.png';

import {
  Container,
  Clothe,
  AddButton,
  RemoveButton,
  AddBtn,
  Counter,
  ShareButton,
  ButtonShareOptions,
  FavoriteContainer,
} from './styles';

const Card = ({
  counter,
  showButton,
  setShowCreateListModal,
  data,
  userHasAdded,
  listId,
  fetchRebuildList,
  checkUserLoggedAddList,
  checkUserLoggedFavorite,
  listDetails,
}) => {
  const history = useHistory();
  const [showButtonShareOptions, setShowButtonShareOptions] = useState(false);

  function renderCounter() {
    if (counter !== undefined) {
      return <Counter>{counter}</Counter>;
    }
  }

  function renderButton() {
    if (showButton !== undefined) {
      return (
        <ShareButton onClick={() => setShowButtonShareOptions((prev) => !prev)}>
          Compartilhar
        </ShareButton>
      );
    }
  }

  function renderButtonShareOptions() {
    if (showButtonShareOptions) {
      return (
        <ButtonShareOptions>
          <button>
            <img src={whatsappIcon} alt="Ícone do WhatsApp" />
          </button>
          <button>
            <img src={facebookIcon} alt="Ícone do Facebook" />
          </button>
          <button>
            <img src={shareIcon} alt="Ícone de compartilhar" />
          </button>
        </ButtonShareOptions>
      );
    }
  }

  return (
    <Container>
      <Clothe>
        <FavoriteContainer>
          <FavoriteIcon data={data} type="products" />
        </FavoriteContainer>

        {renderCounter()}

        <img
          onClick={() => history.push(`/produto/${data.external_id}`)}
          src={listDetails.image_url || Logo}
        />

        {renderButton()}
        {renderButtonShareOptions()}
      </Clothe>
    </Container>
  );
};

export default Card;
