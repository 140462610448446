import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 140px;
  width: 120px;
  border-width: 2px;
  border-style: solid;
  border-color: #06e406;
  border-radius: 10px;
  padding: 6px;

  > span:nth-of-type(1) {
    display: block;
    font-family: 'Izmir-Bold';
    font-size: 2rem;
    color: #707070;
  }

  > span:nth-of-type(2) {
    font-family: 'Poppins-Regular';
    font-size: 1.5rem;
    color: #7a7777;
  }

  > img {
    position: absolute;
    right: -30px;
    bottom: -20px;
    height: 90px;
  }
`;

export const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #fff;
  color: #1e6dff;
  font-size: 2.5rem;
  width: 28px;
  height: 28px;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  border-color: #1e6dff;
`;
