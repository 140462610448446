import React from 'react';
import CloseIconImg from '../../assets/modalWrap/close-icon.png';

import { Container } from './styles';

const ModalWrap = ({ children, toggleModal }) => {
  return (
    <Container>
      <div>
        <button type="button" className="closeModal" onClick={toggleModal}>
          <img src={CloseIconImg} alt="fechar modal" />
        </button>
        {children}
      </div>
    </Container>
  );
};

export default ModalWrap;
