import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import FavoriteIcon from '../../../components/Favorite';

import Button from '../../../components/Button';

import offerIcon from '../../../assets/listCardVertical/offer-icon.png';
import clothe1 from '../../../assets/listCardVertical/clothe1.png';
import UserNotLoggedModalWrap from '../../../components/UserNotLoggedModalWrap';
import UserNotLoggedMessage from '../../../components/UserNotLoggedModalWrap/UserNotLoggedMessage';

import {
  Container,
  ClotheCard,
  ClotheImg,
  FavoriteContainer,
  AddButton,
  Informations,
  Price,
  OfferIcon,
  TextOffer,
  PriceOffer,
  Divider,
} from './styles';
import { findRenderedDOMComponentWithTag } from 'react-dom/test-utils';

const Card = ({ offer, product, lastBookElementRef }) => {
  const user = useSelector((state) => state.User);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState(false);

  const handleAddProductToCart = async () => {
    // dispatch({
    //   type: 'listz-web/user/add-product-cart',
    //   payload: product,
    // });

    // history.push('/carrinho');

    const handleError = async (error) => {
      let message;
      if (error.response?.data?.message) {
        message = error.response.data.message;
      } else {
        message = error.toString();
      }

      toast.error(message);
    };

    const productArr = {
      name: product.title,
      external_id: product.external_id,
      quantity: 1,
      unit_price: product.price,
      item_url: product.item_url,
      image_url: product.image_url,
      is_available: product.is_available,
    }

    await api
    .post(`/items`, { products: [productArr]})
    .then((res) => {
      toast.success('Item adicionado ao carrinho!');
    })
    .catch((err) => {
      handleError(err);
    });
  };

  function renderIconOffer() {
    if (offer) {
      return <OfferIcon src={offerIcon} />;
    }
  }

  function renderTextOffer() {
    if (offer) {
      return <TextOffer>Oferta</TextOffer>;
    }
  }

  const formatPrice = () => {
    if (product?.price) {
      const price = product.price.toFixed(2).replace('.', ',');

      return `R$ ${price}`;
    }
  };

  function renderPrice() {
    if (!product?.is_available) {
      return (
        <Price>
          <p>Produto indisponível</p>
        </Price>
      );
    }

    return (
      <Price>
        <label>Por apenas:</label>
        <p>{formatPrice()}</p>
      </Price>
    );
  }

  const renderTitle = () => {
    if (product?.title) {
      return (
        <p
          ref={lastBookElementRef}
          onClick={() => history.push(`/produto/${product.external_id}`)}
        >
          {product.title}
        </p>
      );
    }
  };

  const renderImg = () => {
    if (product?.image_url) {
      return (
        <ClotheImg
          src={product.image_url}
          onClick={() => history.push(`/produto/${product.external_id}`)}
        />
      );
    }
  };

  const renderAddCartButton = () => {
    if (product?.is_available) {
      return (
        <Button
          text="Adicionar ao carrinho"
          onClick={() => handleAddProductToCart()}
        />
      );
    }
  };

  function checkUserLoggedModal() {
    if (isModal) {
      return (
        <UserNotLoggedModalWrap toggleModal={() => setIsModal(false)}>
          <UserNotLoggedMessage />
        </UserNotLoggedModalWrap>
      );
    }
  }

  const checkUserLoggedAddList = (external_id) => {
    if (user.accessToken) {
      history.push(`/escolha-uma-lista/${external_id}`);
    } else {
      setIsModal(true);
    }
  };

  const renderAddListButton = () => {
    return (
      <Button
        onClick={() => checkUserLoggedAddList(product.external_id)}
        text="Adicionar a lista"
        style={{marginTop: '10px'}}
      />
    );
};

  return (
    <>
      <Container>
        <ClotheCard>
          {renderIconOffer()}
          <FavoriteContainer>
            <FavoriteIcon data={product} type="products" />
          </FavoriteContainer>
          {renderImg()}
          {/* <AddButton>
          <p>+</p>
        </AddButton> */}
        </ClotheCard>
        <Informations>
          {/* {renderTextOffer()} */}
          {renderTitle()}
          {/* <p>Modelo - FXR</p>
        <p>
          Quente e confortável, esta jaqueta te ajuda a aproveitar o ski nas
          melhores condições.
        </p> */}
          {renderPrice()}
          {renderAddCartButton()}
          {renderAddListButton()}
        </Informations>
      </Container>
      {checkUserLoggedModal()}
      <Divider />
    </>
  );
};

export default Card;
