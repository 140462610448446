import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../../../../components/Button';
import FavoriteIcon from '../../../../../components/Favorite';

import clothe1 from '../../../../../assets/listCardVertical/clothe1.png';
import whatsappIcon from '../../../../../assets/listCardHorizontal/whatsapp-icon.png';
import facebookIcon from '../../../../../assets/listCardHorizontal/facebook-icon.png';
import shareIcon from '../../../../../assets/listCardHorizontal/share-icon.png';

import {
  Container,
  Clothe,
  FavoriteContainer,
  AddButton,
  Counter,
  ShareButton,
  ButtonShareOptions,
} from './styles';

const Card = ({ counter, showButton, data }) => {
  const history = useHistory();
  const [showButtonShareOptions, setShowButtonShareOptions] = useState(false);

  function renderCounter() {
    if (counter !== undefined) {
      return <Counter>{counter}</Counter>;
    }
  }

  function renderButton() {
    if (showButton !== undefined) {
      return (
        <ShareButton onClick={() => setShowButtonShareOptions((prev) => !prev)}>
          Compartilhar
        </ShareButton>
      );
    }
  }

  function renderButtonShareOptions() {
    if (showButtonShareOptions) {
      return (
        <ButtonShareOptions>
          <button>
            <img src={whatsappIcon} alt="Ícone do WhatsApp" />
          </button>
          <button>
            <img src={facebookIcon} alt="Ícone do Facebook" />
          </button>
          <button>
            <img src={shareIcon} alt="Ícone de compartilhar" />
          </button>
        </ButtonShareOptions>
      );
    }
  }

  return (
    <Container>
      <Clothe>
        <div>
          <p>Lista</p>
          <p onClick={() => history.push(`/lista/${data.list.id}`)}>
            {data.list.name}
          </p>
          {/* <p>falta dados do back end</p> */}
          <p>{data.list.description}</p>
        </div>
        <FavoriteContainer>
          <FavoriteIcon data={data.list} type="lists" />
        </FavoriteContainer>
        {renderCounter()}
        {/* <img onClick={() => history.push('/lista-detalhes')} src={clothe1} /> */}
        {/* <AddButton>
          <p>+</p>
        </AddButton> */}
        {renderButton()}
        {renderButtonShareOptions()}
      </Clothe>
    </Container>
  );
};

export default Card;
