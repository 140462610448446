import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  width: 120px;
  min-height: 150px;
  height: fit-content;

  border-width: 2px;
  border-style: solid;
  border-color: #06e406;
  border-radius: 10px;
  padding: 6px 6px 0 6px;

  > span:nth-of-type(1) {
    display: block;
    padding: 10px 0;

    font-family: 'Izmir-Bold';
    font-size: 1.7rem;
    color: #707070;
    word-break: break-word;
  }

  > span:nth-of-type(2) {
    font-family: 'Poppins-Regular';
    font-size: 1.5rem;
    color: #7a7777;
  }

  & > div:first-of-type {
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100px;
    /* height: 100px; */
    margin: 0 auto;

    & > img {
      width: 100%;
      height: 75px;
      object-fit: cover;
      border-radius: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #fff;
  color: #1e6dff;
  font-size: 2.5rem;
  width: 28px;
  height: 28px;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  border-color: #1e6dff;
`;
