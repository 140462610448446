import Header from '../../components/Header';
import ListCardVertical from '../../components/ListCardVertical';
import Footer from '../../components/Footer';

import { Container, Content } from './styles';

const Offers = () => {
  return (
    <Container>
      <Header />
      <Content>
        <ListCardVertical offer />
      </Content>
      <Footer />
    </Container>
  );
};

export default Offers;
