import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 58.75rem) {
    width: 300px;
  }

  @media (max-width: 22.5rem) {
    width: 240px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > img {
    height: 40px;
  }

  > div {
    margin-left: 20px;
  }

  > div > div {
    color: #fff;
  }

  > div > div:nth-of-type(1) {
    font-family: 'Izmir-Light';
    font-size: 2rem;
  }

  > div > div:nth-of-type(2) {
    font-family: 'Izmir-Regular';
    font-size: 3rem;
  }
`;
