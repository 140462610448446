import close from '../../../../assets/header/close.png';

import Item from './components/Item';

import { Container, LinkPage } from './styles';

const FilterBox = ({ showFilter, setShowFilter, data }) => {
  return (
    <Container showFilter={showFilter}>
      <div>
        <LinkPage>
          <img
            src={close}
            alt="Ícone de fechar"
            onClick={() => setShowFilter(false)}
          />
        </LinkPage>
      </div>
      {data.map((filter) => (
        <Item>{filter}</Item>
      ))}
    </Container>
  );
};

export default FilterBox;
