import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 440px;
  height: 44px;
  background-color: #f5f5f5;
  border-radius: 10px;
  margin: 0px 0px;
  padding-right: 20px;
  box-shadow: 0px 0px 6px 0px lightgrey;

  @media (max-width: 72.75rem) {
    width: 340px;
  }

  @media (max-width: 33.75rem) {
    width: 280px;
    height: 50px;
  }

  @media (max-width: 29.25rem) {
    width: 230px;
    height: 42px;
  }

  @media (max-width: 26.5rem) {
    width: 200px;
    height: 42px;
  }

  @media (max-width: 21.25rem) {
    width: 160px;
    height: 38px;

    > div:nth-of-type(1) {
      display: none;
    }
  }
`;

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #1511f9;
  cursor: pointer;
`;

export const Image = styled.img`
  height: 20px;
  margin: 0 auto;
`;

export const TextInput = styled.input`
  margin-left: 10px;
  width: 100%;
  height: 80%;
  font-size: 2.6rem;
  border-style: none;
  outline: none;
  background-color: #f5f5f5;
  font-family: 'Izmir-Regular';
  color: #c5c5c5;
  &::placeholder {
    color: #c5c5c5;
  }
`;
