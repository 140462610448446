import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 20px 160px 20px;
  max-width: 1336px;
  width: 100%;
  margin: 0 auto;

  > p {
    font-family: 'Izmir-Regular';
    font-size: 2.5rem;
    color: #807f7f;
    margin-bottom: 10px;
  }
`;

export const MylistsContainer = styled.div`
  width: 100%;
  background-color: #2f78fe;
`;

export const ListsContent = styled.div`
  display: flex;
  padding: 20px;
  max-width: 1336px;
  width: 100%;
  margin: 0 auto;

  > div > p {
    font-family: 'Izmir-Bold';
    font-size: 2rem;
    color: #fff;
  }

  .listOptionContainer {
    display: flex;
  }

  @media (max-width: 27.5rem) {
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > div > p {
      text-align: center;
    }
  }
`;

export const ListOption = styled.div`
  font-family: 'Izmir-Bold';
  background: #fff;
  color: #000;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  margin-right: 20px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  text-align: center;
`;

