import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../../../services/api';

import Card from './components/Card';
import Button from '../../../../components/Button';

import pencil from '../../../../assets/input/pencil.png';

import { Container, Informations, Row } from './styles';

const ListOfLists = ({ data, productExternalId }) => {
  const history = useHistory();

  async function handleAddToList(listId) {
    await api
      .post(`/lists/${listId}/products`, {
        products: [
          {
            external_id: productExternalId,
          },
        ],
      })
      .then((res) => {
        history.push(`/lista/${listId}`);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  const handleError = async (error) => {
    let message;
    if (error.response?.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    toast.error(message);
  };

  const renderLists = () => {
    const lists = data.map((item) => (
      <Row>
        <Card data={item} />
        <Informations>
          <span>{item.name}</span>
          <span>{item.description}</span>
          <Row>
            <span onClick={() => history.push(`/lista/${item.id}`)}>
              Ver lista
            </span>
            {/* <img src={pencil} alt="Ícone de editar lista" /> */}
          </Row>
          <Button
            onClick={() => handleAddToList(item.id)}
            text="Adicionar produto"
          />
        </Informations>
      </Row>
    ));

    return lists;
  };

  return <Container>{renderLists()}</Container>;
};

export default ListOfLists;
<Container></Container>;
