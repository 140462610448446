import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > p {
    font-family: 'Izmir-Medium';
    color: #707070;
    font-size: 1.8rem;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  > button {
    font-family: 'Izmir-Bold';
    color: #707070;
    font-size: 4rem;
    margin-top: 6px;
    border: 0;
    outline: 0;
    background-color: #fff;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    margin: 0px 10px;
    border-color: ${(props) => (props.quantity > 0 ? '#1E6DFF' : '#c5c5c5')};
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    font-size: 2.5rem;
    color: ${(props) => (props.quantity > 0 ? '#1E6DFF' : '#7a7777')};
  }

  @media (max-width: 32.5rem) {
    > button {
      font-size: 3rem;
    }
    > div {
      height: 34px;
      width: 34px;
    }
  }

  @media (max-width: 26.875rem) {
    font-size: 2.5rem;
    > div {
      height: 28px;
      width: 28px;
    }
  }
`;
