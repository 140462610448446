import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// import Button from '../../../../../components/Button';

// import clothe1 from '../../../../../assets/listCardVertical/clothe1.png';

// import whatsappIcon from '../../../assets/listCardHorizontal/whatsapp-icon.png';
// import facebookIcon from '../../../assets/listCardHorizontal/facebook-icon.png';
// import shareIcon from '../../../assets/listCardHorizontal/share-icon.png';

import {
  Container,
  Clothe,
  SelectedFavoriteHeart,
  AddButton,
  Counter,
  ShareButton,
  ButtonShareOptions,
} from './styles';

const Card = ({ data }) => {
  const history = useHistory();
  const [showButtonShareOptions, setShowButtonShareOptions] = useState(false);

  function renderFavoriteHeart() {
    if (false === true) {
      return <SelectedFavoriteHeart />;
    }
  }

  function renderCounter() {
    if (false == true) {
      return <Counter>10</Counter>;
    }
  }

  function renderButton() {
    if (false == true) {
      return (
        <ShareButton onClick={() => setShowButtonShareOptions((prev) => !prev)}>
          Compartilhar
        </ShareButton>
      );
    }
  }

  // useEffect(() => {
  //   console.log(data);
  // }, []);

  // function renderButtonShareOptions() {
  //   if (showButtonShareOptions) {
  //     return (
  //       <ButtonShareOptions>
  //         <button>
  //           <img src={whatsappIcon} alt="Ícone do WhatsApp" />
  //         </button>
  //         <button>
  //           <img src={facebookIcon} alt="Ícone do Facebook" />
  //         </button>
  //         <button>
  //           <img src={shareIcon} alt="Ícone de compartilhar" />
  //         </button>
  //       </ButtonShareOptions>
  //     );
  //   }
  // }

  return (
    <Container>
      <Clothe>
        <div>
          {/* <p>Lista</p> */}
          <p onClick={() => history.push(`lista/${data.id}`)}>{data.name}</p>
          <p>{data.description.length > 50 ? data.description.substring(0, 50)+ '...' : data.description}</p>
        </div>
        {renderFavoriteHeart()}
        {renderCounter()}
        {/* <AddButton>
          <p>+</p>
        </AddButton> */}
        {renderButton()}
        {/* {renderButtonShareOptions()} */}
      </Clothe>
    </Container>
  );
};

export default Card;
