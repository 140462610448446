import React, { useState } from 'react';

import CreateListModal from '../../../../components/CreateListModal';

import logoTransparent from '../../../../assets/cart/logo-transparent.png';
import cartIcon from '../../../../assets/cart/cart-icon.png';

import { Container, Content, AddButton } from './styles';

const Title = ({user, setIsModal}) => {
  const [showCreateListModal, setShowCreateListModal] = useState(false);

  function renderCreateListModal() {
    if (showCreateListModal) {
      return <CreateListModal setVisible={setShowCreateListModal} />;
    }
  }

  const checkUserLoggedCreateList= () => {
    if (user.accessToken) {
      setShowCreateListModal(true);
    } else {
      setIsModal(true);
    }
  };

  return (
    <Container>
      <img src={logoTransparent} alt="Logo transparent do Listz" />
      <Content>
        <div onClick={() => checkUserLoggedCreateList()}>
          <AddButton>+</AddButton>
          <p>Adicionar nova lista</p>
        </div>
      </Content>
      <img src={logoTransparent} alt="Logo transparent do Listz" />
      {renderCreateListModal()}
    </Container>
  );
};

export default Title;
