import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  min-height: 100vh;
  position: relative;

  > img:nth-of-type(1) {
    position: absolute;
    height: 60px;
    top: 0vh;
    left: 8vw;
    z-index: -1;
  }

  > img:nth-of-type(2) {
    position: absolute;
    height: 240px;
    left: 0px;
    bottom: 0px;
    z-index: -1;
  }

  > img:nth-of-type(3) {
    position: absolute;
    height: 600px;
    bottom: 0px;
    right: 0px;
    z-index: -1;
  }

  @media (max-width: 85.625rem) {
    > img:nth-of-type(3) {
      height: 400px;
    }
  }

  @media (max-width: 64rem) {
    display: flex;
    align-items: center;
    justify-content: center;

    > img:nth-of-type(1) {
      left: 50%;
      transform: translateX(-50%);
    }

    > img:nth-of-type(3) {
      display: none;
    }
  }

  @media (max-width: 26.25rem) {
    > img:nth-of-type(2) {
      height: 160px;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 540px;
  padding: 20px;
  flex-shrink: 0;
  z-index: 1;
  padding-top: 80px;
  margin-left: 6vw;
  justify-content: center;
  min-height: 100vh;

  h1 {
    font-size: 6rem;
    font-family: Izmir-Bold;
    color: #2f78fe;
  }

  p {
    font-family: Izmir-Regular;
    color: #000000;
    font-size: 3.4rem;
  }

  > p:nth-of-type(2) {
    /* margin-top: 2vh; */
    color: #c5c5c5;
    cursor: pointer;
  }

  @media (max-width: 64rem) {
    /* margin-top: 18vh; */
    margin-left: 0px;
  }

  @media (max-width: 32.5rem) {
    text-align: center;
    width: 100%;
  }

  @media (max-width: 22.5rem) {
    h1 {
      font-size: 4rem;
    }

    p {
      font-family: Izmir-Regular;
      color: #000000;
      font-size: 2.4rem;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  margin-top: 2vh;

  p:nth-of-type(2) {
    font-family: 'Izmir-Bold';
    color: #2f78fe;
    margin-left: 4px;
  }
`;

export const LinkPage = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const Tooltip = styled.div`
  font-family: 'Izmir-Bold';
  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: -10px;
  color: #f00;
  text-align: center;
`;
