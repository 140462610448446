import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../../../services/api';

import FavoriteIcon from '../../../../../components/Favorite';

import Button from '../../../../../components/Button';

import offerIcon from '../../../../../assets/listCardVertical/offer-icon.png';
import clothe1 from '../../../../../assets/listCardVertical/clothe1.png';

import {
  Container,
  ClotheCard,
  ClotheImg,
  FavoriteContainer,
  AddButton,
  Informations,
  Price,
  OfferIcon,
  TextOffer,
  PriceOffer,
} from './styles';
import { findRenderedDOMComponentWithTag } from 'react-dom/test-utils';

const Card = ({ offer, data }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleAddProductToCart = async () => {
    // dispatch({
    //   type: 'listz-web/user/add-product-cart',
    //   payload: data,
    // });

    // history.push('/carrinho');

    const handleError = async (error) => {
      let message;
      if (error.response?.data?.message) {
        message = error.response.data.message;
      } else {
        message = error.toString();
      }

      toast.error(message);
    };

    const productArr = {
      name: data.product.title,
      external_id: data.product.external_id,
      quantity: 1,
      unit_price: data.product.price,
      item_url: 'data.product.item_url',
      image_url: data.product.image_url,
      is_available: data.product.is_available,
    }

    await api
    .post(`/items`, { products: [productArr]})
    .then((res) => {
      toast.success('Item adicionado ao carrinho!');
    })
    .catch((err) => {
      handleError(err);
    });
  };

  function renderIconOffer() {
    if (offer) {
      return <OfferIcon src={offerIcon} />;
    }
  }

  function renderTextOffer() {
    if (offer) {
      return <TextOffer>Oferta</TextOffer>;
    }
  }

  const formatPrice = () => {
    if (data?.product?.price) {
      const price = data.product.price.toFixed(2).replace('.', ',');

      return `R$ ${price}`;
    }
  };

  function renderPrice() {
    if (!data?.product?.is_available) {
      return (
        <Price>
          <p>Produto indisponível</p>
        </Price>
      );
    }

    return (
      <Price>
        <label>Por apenas:</label>
        <p>{formatPrice()}</p>
      </Price>
    );
  }

  const renderTitle = () => {
    if (data?.product?.title) {
      return (
        <p onClick={() => history.push(`/produto/${data.external_id}`)}>
          {data.product.title}
        </p>
      );
    }
  };

  const renderImg = () => {
    if (data?.product?.image_url) {
      return (
        <ClotheImg
          src={data.product.image_url}
          onClick={() => history.push(`/produto/${data.external_id}`)}
        />
      );
    }
  };

  const renderAddCartButton = () => {
    if (data?.product?.is_available) {
      return (
        <Button
          text="Adicionar ao carrinho"
          onClick={() => handleAddProductToCart()}
        />
      );
    }
  };

  return (
    <Container>
      <ClotheCard>
        {renderIconOffer()}
        <FavoriteContainer>
          <FavoriteIcon data={data.product} type="products" />
        </FavoriteContainer>
        {renderImg()}
        {/* <AddButton>
          <p>+</p>
        </AddButton> */}
      </ClotheCard>
      <Informations>
        {/* {renderTextOffer()} */}
        {renderTitle()}
        {/* <p>Modelo - FXR</p>
        <p>
          Quente e confortável, esta jaqueta te ajuda a aproveitar o ski nas
          melhores condições.
        </p> */}
        {renderPrice()}
        {renderAddCartButton()}
      </Informations>
    </Container>
  );
};

export default Card;
