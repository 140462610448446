import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from '../../components/Header';
import ModalWrap from '../../components/ModalWrap';
import MenuProfile from '../../components/MenuProfile';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'antd';

import SuccessImg from '../../assets/modalWrap/success.png';

import IntlCurrencyInput from "react-intl-currency-input"

import api from "../../services/api";

import {
  Container,
  Content,
  Row,
  Column,
  Form,
  Modal
} from './styles';

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const Cashback = () => {
  const [withdrawValue, setWithdrawValue] = useState(0.00);
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const history = useHistory();
  const [userData, setUserData] = useState({})
  const [cashbackPending, setcashbackPending] = useState('')
  const userState = useSelector(state => state.User);

  useEffect(() => {
    async function getUserData() {
      try {
        const response = await api.get(`/users/${userState.id}`);

        setUserData(response.data);
        setcashbackPending(response.data.pending_cashback);
      } catch (error) {
        console.log('');
      }
    }
    getUserData();
  }, []);

  async function withdrawCashback(e) {
    e.preventDefault();

    if (!withdrawValue) {
      setErrorMessage('Preencha um valor de saque');
    } else {
      setShowLoadModal(true);
      setErrorMessage('');

      try {
        const usr = {
          value: withdrawValue
        }

        const response = await api.post('/cashback-solicitations', usr);
        setIsModal(true);
      } catch (error) {
        console.error('Ops! ocorreu um erro', error.response.data.message);
        setErrorMessage(error.response.data.message);
      }

      setShowLoadModal(false);
    }
  }

  function renderModal() {
    if (isModal) {
      return (
        <ModalWrap toggleModal={() => setIsModal(false)}>
          <Modal>
            <h2>Solicitação de saque enviada!</h2>
            <img src={SuccessImg} alt="sucesso" />
            <h3>O suporte irá confirmar sua solicitação</h3>
          </Modal>
        </ModalWrap>
      );
    }
  }

  const handleChangeWithDraw = (event, value, maskedValue) => {
    event.preventDefault();
    console.log('value', value);
    setWithdrawValue(value);
  };

  function moneyMask(value){
    var money = parseFloat(value).toLocaleString('pt-BR',{ style: 'currency', currency: 'BRL' });
    return money;
  }

  return (
    <Container>
      <Header />
      <Content>
        <Row>
          <Column>
            <MenuProfile />
          </Column>
        </Row>
        <Form onSubmit={withdrawCashback}>
          <h1>Cashback</h1>
          <div>
            <Column>
              <p>Saldo Disponível para saque</p>
              <IntlCurrencyInput style={{color: '#000'}} disabled className="withdrawInput" value={`${moneyMask(userData.cashback_balance)}`} currency="BRL" config={currencyConfig} />
            </Column>
            <Column>
              <div style={{ display: 'flex', fontSize: '4.2rem'}}>
                <p>Informe quanto você gostaria de sacar</p>
                <Tooltip title="Valor mínimo para saque é de R$5,00.">
                  <FaInfoCircle color='#c5c5c5' style={{margin: '10px 0 0 10px', cursor: 'pointer'}} />
                </Tooltip>
              </div>
              <IntlCurrencyInput style={{color: (withdrawValue <= 4.99 && withdrawValue !== 0.00) ? '#f00' : '#000'}} className="withdrawInput" currency="BRL" config={currencyConfig}
              onChange={handleChangeWithDraw} />
            </Column>
          </div>
          <section>
            <div style={{ display: 'flex', margin: '15.5px 0 10px 0' }}>
              <p>Cashback pendente</p>
              <Tooltip title="Este valor será adicionado em seu saldo disponível
                quando liberado por um administrador.">
                <FaInfoCircle color='#c5c5c5' style={{margin: '10px 0 0 10px', cursor: 'pointer'}} />
              </Tooltip>
            </div>
            <Input style={{color: '#000'}} value={`${moneyMask(cashbackPending) && moneyMask(cashbackPending)}`} disabled />
          </section>
          {userData.bank ?
            (
              <div>
                <Button
                  type="submit"
                  text="Solicitar saque"
                />
                {errorMessage && <span>{errorMessage}</span>}
                {showLoadModal ? <p>Carregando...</p> : ''}
              </div>
            ) :
            (
              <>
                <p>Você precisa ter um banco cadastrado para solicitar um saque</p>
                <div>
                  <Button
                    type="submit"
                    text="Cadastrar banco"
                    handleClick={() => history.push('/perfil')}
                  />
                </div>
              </>
            )}
        </Form>
        {renderModal()}
      </Content>
      <Footer />
    </Container>
  );
};

export default Cashback;
