import { Container, Text } from './styles';

const Button = ({
  type,
  handleClick,
  ...props
}) => {
  return (
    <Container style={props.containerStyle} onClick={handleClick} {...props}>
      <Text style={props.textStyle} >{props.text}</Text>
    </Container>
  );
};

export default Button;
