import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  text-decoration: none;
  color: inherit;
  padding: 10px 20px;
  margin: 0px 4px;
  margin-bottom: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.focus ? '#06E406' : '#c5c5c5')};
  border-radius: 4px;
  font-family: 'Izmir-Bold';
  font-size: 2rem;
  color: #4a4747;
`;
