import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  max-width: 1536px;
  width: 100%;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  > div:nth-of-type(2) {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 10px;
  }

  .moreCategories {
    background-color: #edf1fc;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 20px;

    &:hover {
      background-color: #f1f5ff;
    }
  }

  @media (max-width: 640px) {
    > div:nth-of-type(2) {
      background-color: #edf1fc;
      padding: 20px;
      margin: -20px;
      margin-top: 20px;
      overflow: scroll;
      flex-wrap: nowrap;
    }

    .moreCategories {
      margin-top: 30px !important;
    }
  }

  @media (max-width: 424px) {
    > div:nth-of-type(2) {
      margin: 20px 0 0 0;
    }
  }
`;

export const IconMenu = styled.img`
  height: 26px;
  cursor: pointer;

  @media (max-width: 26.5rem) {
    height: 24px;
  }
`;

export const Logo = styled.img`
  margin-top: -40px;
  height: 60px;
  cursor: pointer;

  @media (max-width: 52.5rem) {
    display: none;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  > p:nth-of-type(2) {
    text-align: left;
    font-size: 2.2rem;
    color: #407ef8;
    font-family: 'Izmir-Medium';
  }

  @media (max-width: 64rem) {
    display: none;
    > p {
      display: none;
    }
  }
`;

export const Text = styled.p`
  font-size: 2rem;
  color: #4a4747;
  font-family: 'Izmir-Bold';
`;

export const Options = styled.div`
  img {
    width: 24px;
  }

  img:nth-of-type(1) {
    margin-right: 20px;
  }

  img:nth-of-type(2) {
    margin-right: 20px;
  }

  @media (max-width: 52.5rem) {
    img:nth-of-type(1) {
      display: none;
    }

    img:nth-of-type(2) {
      margin-right: 0px;
    }

    img:nth-of-type(3) {
      display: none;
    }
  }
`;

export const Item = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;

  > div {
    font-family: 'Izmir-Bold';
    color: #4a4747;
    font-size: 2rem;
  }

  > img {
    margin-top: 1vh;
    margin-left: 1vw;
    height: 1vh;
  }

  @media (max-width: 640px) {
    padding: 12px;
    background-color: #fff;
    border-radius: 6px;
    margin-right: 6px;

    > img {
      display: none;
    }
  }
`;

export const LinkPage = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  max-height: 350px;
  width: 233px;

  > h3 {
    font-size: 2.25rem;
    margin-top: 20px;
    cursor: pointer;
  }
`;
