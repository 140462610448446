import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      margin-bottom: 50px;
    }
  }
`;

export const ContainerNotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 120px 0 120px 0;
      font-weight: bold;
    }
  }
`;
