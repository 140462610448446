import styled from 'styled-components';

export const Container = styled.div`
  > p:nth-of-type(1) {
    font-family: 'Izmir-Regular';
    font-size: 2.3rem;
    color: #4a4747;

    > span {
      font-family: 'Izmir-Bold';
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  font-size: 3rem;
  color: #1e6dff;
  width: fit-content;
  border-color: #1e6dff;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  margin: 4px;
  min-width: 40px;
  text-align: center;
  border-radius: 6px;
  padding: 4px;
`;
