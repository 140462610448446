import Card from './Card';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { Container } from './styles';

const ListCardHorizontal = ({ counter, showButton, data }) => {
  const renderLists = () => {
    const lists = data.map((list) => <Card data={list} />);
    return lists;
  };

  return <Container>{renderLists()}</Container>;
};

export default ListCardHorizontal;
