import styled from 'styled-components';
import { FaHeart } from 'react-icons/fa';

export const FavoriteIcon = styled(FaHeart)`
  color: ${(props) => (props.isFavorite ? '#06e406' : '#c5c5c5')};
  font-size: 4rem;
  cursor: pointer;
  transition: all 0.5s;
  z-index: 2;
`;
