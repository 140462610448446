import Button from './Button';

import shopList from '../../../../assets/about/shop-list.png';
import appleStore from '../../../../assets/home/apple-store.png';
import playStore from '../../../../assets/home/play-store.png';

import { Container, Content, Column, Row } from './styles';

const Description = () => {
  return (
    <Container>
      <Content>
        <img src={shopList} alt="Lista de Compras" />
        <Column>
          <p>Falou em Listas?</p>
          <p>Pensou no Listz</p>
          <p>Receba até 5% de cashback em suas compras</p>
          <p>
            Tudo pronto pra você só jogar no carrinho! Navegue por listas
            prontas, edite ou crie sua própria lista e compartilhe com quem você
            quiser!
          </p>
          <p>
            Baixe o App:
            <br />
            Disponível na loja de aplicativos do seu celular{' '}
          </p>
          <Row>
            <Button
              containerStyle={{ backgroundColor: '#07CF03' }}
              icon={appleStore}
              text="App Store"
            />
            <Button
              containerStyle={{ backgroundColor: '#4881F4' }}
              icon={playStore}
              text="Google Play"
            />
          </Row>
        </Column>
      </Content>
    </Container>
  );
};

export default Description;
