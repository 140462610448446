import Header from '../../components/Header';
import Footer from '../../components/Footer';
import MyFavoriteProducts from './components/MyFavoriteProducts';
import { useHistory } from 'react-router-dom';
import Lists from './components/Lists';
import { useSelector } from 'react-redux';

import { Container, Content } from './styles';

const Search = () => {
  const searchResults = useSelector(state => state.Search.search);

  const history = useHistory();

  if (!searchResults[0]) {
    history.push('/')
  }

  return (
    <Container>
      <Header />
      <Content>
        <Lists />
        <MyFavoriteProducts />
      </Content>
      <Footer />
    </Container>
  );
};

export default Search;
