import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  background-color: #edf1fc;
`;

export const Content = styled.div`
  max-width: 1336px;
  width: 100%;

  > p:nth-of-type(1) {
    font-family: 'Izmir-Bold';
    font-size: 3.5rem;
    color: #1e6dff;
  }

  > p:nth-of-type(2) {
    font-family: 'Izmir-Regular';
    font-size: 2.5rem;
    color: #8a8a8a;
    margin-bottom: 20px;
  }
`;
