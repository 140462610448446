import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 40px 0px;
  max-width: 836px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 32.5rem) {
    margin: 0px;
  }

  @media (max-width: 26.875rem) {
    padding: 10px;
  }
`;
