import React, { useState, useEffect } from 'react';
import { Select as SelectAntd } from 'antd';

import { Container } from './styles';

const Combobox = ({ bankCodes, userBankData, setUserBankData }) => {
  const { Option } = SelectAntd;
  const [defaultValueBankCode, setDefaultValueBankCode] = useState('');
  const [recall, setRecall] = useState(true);

  const renderOptions = () => {
    const options = bankCodes.map((bank) => (
      <Option value={bank.id + '&' + bank.code}>
        {bank.code} - {bank.name}
      </Option>
    ));

    return options;
  };

  const getBankDefault = () => {
    if (recall) {
      for (let bank of bankCodes) {
        if (bank.code === userBankData.bank_code) {
          console.log('achoou ' + bank.code);
          setUserBankData({
            ...userBankData,
            bank_code: bank.code,
          });
          setRecall(false);

          setDefaultValueBankCode(bank.code + ' - ' + bank.name);
        }
      }
    }
  };

  useEffect(() => {
    getBankDefault();
  }, [bankCodes, userBankData]);

  return (
    <Container>
      <SelectAntd
        placeholder={defaultValueBankCode}
        bordered={false}
        onChange={(value) => {
          let bankCode = value.split('&');
          setUserBankData({
            ...userBankData,
            bank_code: bankCode[1],
          });
        }}
      >
        {renderOptions()}
      </SelectAntd>
    </Container>
  );

  // return (
  //   <Container>
  //     <select
  //       onChange={(event) =>
  //         setUserBankData({
  //           ...userBankData,
  //           bank_code: event.target.value,
  //         })
  //       }
  //     >
  //       {renderOptions()}
  //     </select>
  //     <img src={arrow_down} />
  //   </Container>
  // );
};

export default Combobox;
