import { useState, useEffect } from 'react';

import Card from './Card';
import ListCardHorizontal from '../../../../components/ListCardHorizontal';

import { Container, ShareButton } from './styles';

const Lists = ({ data }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const handleShare = (id) => {
    navigator.clipboard.writeText(`https://listz.com.br/#/lista/${id}`);

    setShowTooltip(true);

    setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  };

  const renderTooltip = () => {
    return <div className="button__tooltip">Link copiado!</div>;
  };

  return (
    <Container>
      {data.map((item) => (
        <div className="container__card-wrapper" key={item.id}>
          <Card data={item} />

          {item.is_public ? (
            <div class="card-wrapper__button-wrapper">
              {showTooltip && renderTooltip()}
              <ShareButton onClick={() => handleShare(item.id)}>
                Compartilhar
              </ShareButton>
            </div>
          ) : (
            <span />
          )}
        </div>
      ))}

      {/* <ListCardHorizontal counter showButton />
      <ListCardHorizontal counter showButton /> */}
    </Container>
  );
};

export default Lists;
