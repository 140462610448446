import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { FaPen } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../../../services/api';

import Card from './components/Card';
import Button from '../../../../components/Button';

import EditListModal from '../../../../components/EditListModal';
import Description from './components/Description';

import { Container, Informations, Row } from './styles';



const ListOfLists = ({ data, fetchList }) => {
  const user = useSelector((state) => state.User);
  const localCart = user.cart;

  const dispatch = useDispatch();
  const history = useHistory();
  const [showEditListModal, setShowEditListModal] = useState(false);
  const [selectedList, setSelectedList] = useState();
  const [isModal, setIsModal] = useState(false);

  const handleAddListToCart = (rawProducts) => {
    const sanitizedProducts = rawProducts.products.map(
      ({ external_id }) => {
        const product = {
          name: rawProducts.name,
          external_id,
          quantity: 1,
          unit_price: 0,
          item_url: rawProducts.image_url,
          image_url: rawProducts.image_url,
          is_available: rawProducts.is_highlight,
        };

        return product;
      },
    );

    if (user.accessToken.length) return addToCart(sanitizedProducts);

    !user.accessToken && dispatch({ type: 'listz-web/user/logout' }); //create an empty user on local storage

    if (localCart.length) {
      editCart(sanitizedProducts);
    }

    if (!localCart.length) {
      dispatchToLocalStorage(sanitizedProducts);
    }
  };

  const editCart = (sanitizedProducts) => {
    const updateProductList = sanitizedProducts.reduce(
      (list, currentProduct) => {
        let currentProducList = list;

        const productAlreadyOnCart = currentProducList.find(
          (product) => product.external_id === currentProduct.external_id,
        );

        if (productAlreadyOnCart) {
          const productWithNewQuantity = {
            ...productAlreadyOnCart,
            quantity: currentProduct.quantity + productAlreadyOnCart.quantity,
          };

          const updatedList = currentProducList.map((item) => {
            if (item.external_id === productWithNewQuantity.external_id) {
              return productWithNewQuantity;
            }

            return item;
          });

          return (currentProducList = updatedList);
        }

        if (!productAlreadyOnCart) {
          currentProducList.push(currentProduct);
        }

        return currentProducList;
      },
      localCart,
    );

    dispatchToLocalStorage(updateProductList);
  };

  const dispatchToLocalStorage = (products) => {
    dispatch({
      type: 'listz-web/user/add-list-cart',
      payload: { ...user, cart: products },
    });

    toast.success('Lista adicionada ao carrinho com sucesso!');

    setTimeout(() => {
      history.push('/carrinho');
    }, 3000);
  };

  const addToCart = async (products) => {
    try {
      const response = await api.post(`/items`, { products });

      if (response) {
        toast.success('Item adicionado ao carrinho!');
      }
    } catch (error) {
      handleError(error.response?.data?.message || error.toString());
    }
  };

  const handleError = async (error) => {
    toast.error(error);
  };

  const renderAddCartButton = (item) => {
    if (item.products.length > 0) {
      return (
        <Button
          text="Adicionar ao carrinho"
          onClick={() => handleAddListToCart(item)}
        />
      );
    }
  };

  const handleEditList = async (item) => {
    setSelectedList(item);
    setShowEditListModal(true);
  };

  const checkUserLoggedEdit = (item) => {
    if (user.accessToken) {
      handleEditList(item);
    } else {
      setIsModal(true);
    }
  };

  function renderEditListModal() {
    if (showEditListModal) {
      return (
        <EditListModal
          fetchList={fetchList}
          selectedList={selectedList}
          setVisible={setShowEditListModal}
        />
      );
    }
  }

  const renderEditButton = (item) => {
    if (item.user_id === user.id) {
      return (
        <span onClick={() => checkUserLoggedEdit(item)}>
          Editar lista
          <FaPen size={20} />
        </span>
      );
    }
  };

  const renderLists = () => {
    const lists = data.map((item) => (
      <Row>
        <Card data={item} />
        <Informations>
          <span>{item.name}</span>
          <Description description={item.description} />
          <Row>
            <span onClick={() => history.push(`/lista/${item.id}`)}>
              Ver lista <FaEye size={20} />
            </span>
            {renderEditButton(item)}
          </Row>
          {renderAddCartButton(item)}
        </Informations>
      </Row>
    ));

    return lists;
  };

  return (
    <Container>
      {renderLists()}
      {renderEditListModal()}
    </Container>
  );
};

export default ListOfLists;
