import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../services/api';

import Input from '../../components/Input';
import Button from '../../components/Button';
import LoadModal from '../../components/LoadModal';
import MessageModal from '../../components/MessageModal';

import user from '../../assets/input/user.png';
import lock from '../../assets/input/lock.png';

import logo from '../../assets/login/logo.png';
import background1 from '../../assets/login/background1.png';
import background2 from '../../assets/login/background2.png';

import { Container, Form, Row, LinkPage, Tooltip } from './styles';

const ChangePassword = () => {
  let query = useQuery();
  const token = query.get('token');

  const dispatch = useDispatch();
  const history = useHistory();
  const [userData, setUserData] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  function comparePasswordAndPasswordConfirmation() {
    const password = userData.password;
    const confirmPassword = userData.passwordConfirmation;

    if (
      password.length > 0 &&
      confirmPassword.length > 0 &&
      password !== confirmPassword
    ) {
      return false;
    } else {
      return true;
    }
  }

  const handleResetPassword = async (event) => {
    event.preventDefault();

    if (!userData.password.trim() || !userData.passwordConfirmation.trim()) {
      setErrorMessage('Preencha sua senha corretamente');
      return;
    }

    if (!comparePasswordAndPasswordConfirmation()) {
      setErrorMessage('As senhas não coincidem');
      return;
    }

    setErrorMessage('');
    setShowLoadModal(true);

    await api
      .post('/password/reset', {
        password: userData.password,
        password_confirmation: userData.passwordConfirmation,
        token,
      })
      .then((response) => {
        setShowLoadModal(false);
        setShowMessageModal(true);

        setTimeout(function () {
          history.push('/login');
        }, 3000);
      })
      .catch((error) => handleError(error));
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    setShowLoadModal(false);
    setErrorMessage(message);
  };

  const renderErrorMessage = () => {
    if (errorMessage) {
      return <Tooltip>{errorMessage}</Tooltip>;
    }
  };

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal />;
    }
  };

  function renderMessageModal() {
    if (showMessageModal) {
      return (
        <MessageModal
          title="Senha alterada com sucesso"
          description="Redirecionaremos você para a tela de login"
          type="success"
          iconColor="green"
          setVisible={setShowMessageModal}
        />
      );
    }
  }

  return (
    <Container>
      <Form onSubmit={(event) => handleResetPassword(event)}>
        <h1>Alterar Senha</h1>
        <Input
          containerStyle={{ marginTop: '1.2vh' }}
          icon={lock}
          alt="Lock Icon"
          placeholder="Senha"
          type="password"
          onInput={(event) =>
            setUserData({ ...userData, password: event.target.value })
          }
        />
        <Input
          containerStyle={{ marginTop: '1.2vh' }}
          icon={lock}
          alt="Lock Icon"
          placeholder="Confirmar Senha"
          type="password"
          onInput={(event) =>
            setUserData({
              ...userData,
              passwordConfirmation: event.target.value,
            })
          }
        />
        {renderErrorMessage()}
        <Button
          type="submit"
          containerStyle={{ marginTop: '4vh' }}
          text="Alterar"
        />
      </Form>
      <img src={logo} className="logo" />
      <img src={background1} className="background1" />
      <img src={background2} className="background2" />
      {renderLoadModal()}
      {renderMessageModal()}
    </Container>
  );
};

export default ChangePassword;
