import styled from 'styled-components';

import ListCardVertical from '../../components/ListCardVertical';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  max-width: 1366px;
  width: 100%;
  padding: 20px;
`;
