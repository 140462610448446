import { Select as SelectAntd } from 'antd';

import arrow_down from '../../../../assets/header/arrow_down.png';

import { Container } from './styles';

const Combobox = ({ categories, setCategoryId }) => {
  const { Option } = SelectAntd;

  const renderOptions = () => {
    let categoriesOptions = categories.map((item) => (
      <Option value={item.id}>{item.name}</Option>
    ));

    categoriesOptions = [<Option value={''}></Option>, ...categoriesOptions];

    return categoriesOptions;
  };

  return (
    <Container>
      <SelectAntd bordered={false} onChange={(value) => setCategoryId(value)}>
        {renderOptions()}
      </SelectAntd>
    </Container>
  );

  // return (
  //   <Container>
  //     <select onChange={(event) => setCategoryId(event.target.value)}>
  //       <option value=""></option>
  //       {categories.map((item) => (
  //         <option value={item.id}>{item.name}</option>
  //       ))}
  //     </select>
  //     <img src={arrow_down} />
  //   </Container>
  // );
};

export default Combobox;
