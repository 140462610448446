import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  height: 400px;
  background-color: #2f78fe;

  img:nth-of-type(1) {
    position: absolute;
    left: 0;
  }

  img:nth-of-type(2) {
    position: absolute;
    right: 0;
  }
`;

export const Content = styled.div`
  max-width: 1336px;
  width: 100%;

  > p {
    font-family: 'Izmir-Medium';
    font-size: 2.5rem;
    color: #fff;
    text-align: center;
  }

  > div {
    margin-top: 20px;
  }
`;
