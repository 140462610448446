import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 100px;
  width: 80px;
  border-width: 2px;
  border-style: solid;
  border-color: #06e406;
  border-radius: 10px;
  padding-top: 4px;
  padding-left: 4px;

  > span:nth-of-type(1) {
    display: block;
    font-family: 'Izmir-Bold';
    font-size: 1.4rem;
    color: #707070;
  }

  > span:nth-of-type(2) {
    font-family: 'Poppins-Regular';
    font-size: 1rem;
    color: #7a7777;
  }

  > img {
    position: absolute;
    right: -20px;
    bottom: -40px;
    height: 80px;
  }
`;
