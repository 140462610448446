import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function useListSearch(query, pageNumber) {
  const user = useSelector((state) => state.User);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [lists, setLists] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setLists([]);
  }, [query]);

  useEffect(() => {
    async function getProducts() {
      setLoading(true);
      setError(false);
      let cancel;
      await api
        .get(`${process.env.REACT_APP_API}/search/products`, {
          params: { q: query, page: pageNumber },
          cancelToken: new axios.CancelToken((c) => (cancel = c)),
        })
        .then((res) => {
          setLists((prevLists) => {
            return [
              ...new Set([...prevLists, ...res.data.results.map((b) => b)]),
            ];
          });

          setHasMore(res.data.results.length > 0);
          setLoading(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setError(true);
          toast.error(e.response?.data?.message || error.toString());
        });
      return () => cancel();
    }
    getProducts();
  }, [query, pageNumber]);

  return { loading, error, lists, hasMore };
}
