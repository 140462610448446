import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 57.5rem) {
    flex-direction: column-reverse;
  }
`;

export const Information = styled.div`
  width: 400px;

  > p:nth-of-type(1) {
    font-family: 'Izmir-Regular';
    font-size: 2rem;
    color: #000;
    margin-top: 20px;
  }

  > p:nth-of-type(2) {
    font-family: 'Izmir-Regular';
    font-size: 2rem;
    color: #807f7f;
    margin-top: 20px;
  }

  > button {
    margin-top: 20px;
  }

  @media (max-width: 28.75rem) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const Name = styled.h1`
  font-family: 'Izmir-Bold';
  font-size: 3.5rem;
  color: #1e6dff;
`;

export const Brand = styled.h2`
  font-family: 'Izmir-Bold';
  font-size: 2.5rem;
  color: #000;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #c9c9c9;
  margin: 20px 0px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Price = styled.div`
  > p:nth-of-type(1) {
    font-family: 'Izmir-Regular';
    font-size: 2.3rem;
    color: #4a4747;
  }

  > p:nth-of-type(2) {
    font-family: 'Izmir-Bold';
    font-size: 3rem;
    color: #4a4747;
    margin-top: 10px;
  }
`;

export const Options = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 20px;
    background-color: #b1b1b1;
    font-family: 'Izmir-Bold';
    font-size: 3rem;
    color: #fff;
    margin-left: 20px;
  }

  > div:nth-of-type(2) {
    height: 50px;
    width: 50px;
    border-radius: 30px;
    font-size: 3.5rem;
    margin: -10px 10px 0px 10px;
  }

  > div:nth-of-type(1) {
    background-color: #2f78fe;
    cursor: pointer;

    > img {
      height: 16px;
    }
  }
`;

export const Clothe = styled.div`
  > div:nth-of-type(1) {
    align-items: flex-end;

    > img {
      max-height: 320px;
      max-width: 320px;
    }
  }

  @media (max-width: 57.5rem) {
    > div:nth-of-type(1) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
`;
