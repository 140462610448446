import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 40px;
  width: 240px;
  height: 40px;
  outline: 0;
  border: 0;
  background-color: #edf1fc;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-radius: 20px;

  > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    > span {
      font-family: 'Izmir-Medium';
      font-size: 2rem;
      color: #707070;
      margin-left: 10px;
    }
  }
`;

export const AddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -10px;
  left: -8px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #06e406;

  color: #fff;
  font-size: 5rem;
`;
