import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../services/api';

import Input from '../../components/Input';
import Button from '../../components/Button';
import LoadModal from '../../components/LoadModal';
import MessageModal from '../../components/MessageModal';

import user from '../../assets/input/user.png';
import lock from '../../assets/input/lock.png';

import logo from '../../assets/login/logo.png';
import background1 from '../../assets/login/background1.png';
import background2 from '../../assets/login/background2.png';

import { Container, Form, Row, LinkPage, Tooltip } from './styles';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userData, setUserData] = useState({ login: '', password: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!userData.password.trim()) {
      setErrorMessage('Preencha a senha corretamente');
      return;
    }

    setErrorMessage('');
    setShowLoadModal(true);

    await api
      .post('/sessions', userData)
      .then((response) => {
        dispatch({
          type: 'listz-web/user/get-user-data',
          payload: response.data,
        });
        history.push('/');
      })
      .catch((error) => handleError(error));
  };

  const handleForgotPassword = async () => {
    if (!userData.login.trim()) {
      setErrorMessage('Digite seu e-mail corretamente');
      return;
    }

    setErrorMessage('');
    setShowLoadModal(true);

    await api
      .post('/password/forgot', { email: userData.login })
      .then((response) => {
        setShowLoadModal(false);
        setShowMessageModal(true);
      })
      .catch((error) => handleError(error));
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    setShowLoadModal(false);
    setErrorMessage(message);
  };

  const renderMessageModal = () => {
    if (showMessageModal) {
      return (
        <MessageModal
          title="Fique atento ao seu email"
          description="Enviaremos para o seu email o link de redefinição de senha, certifique-se de procurar também na caixa de spam, caso não ache na caixa de entrada"
          setVisible={setShowMessageModal}
        />
      );
    }
  };

  const renderErrorMessage = () => {
    if (errorMessage) {
      return <Tooltip>{errorMessage}</Tooltip>;
    }
  };

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal />;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Form onSubmit={(event) => handleLogin(event)}>
        <h1>Bem-vindo(a) ao Listz</h1>
        <p>Sua plataforma de listas de compras!</p>
        <Input
          containerStyle={{ marginTop: '4vh' }}
          icon={user}
          alt="User Icon"
          placeholder="E-mail ou usuário...."
          onInput={(event) =>
            setUserData({ ...userData, login: event.target.value })
          }
        />
        <Input
          containerStyle={{ marginTop: '1.2vh' }}
          icon={lock}
          alt="Lock Icon"
          placeholder="Senha...."
          type="password"
          onInput={(event) =>
            setUserData({ ...userData, password: event.target.value })
          }
        />
        {renderErrorMessage()}
        <Button
          type="submit"
          containerStyle={{ marginTop: '4vh' }}
          text="Entrar"
        />
        <p onClick={() => handleForgotPassword()}>Esqueci minha senha</p>
        <Row>
          <p>Você é novo aqui?</p>
          <p>
            <LinkPage to="criar-conta">Cadastre-se</LinkPage>
          </p>
        </Row>
      </Form>
      <img src={logo} className="logo" />
      <img src={background1} className="background1" />
      <img src={background2} className="background2" />
      {renderLoadModal()}
      {renderMessageModal()}
    </Container>
  );
};

export default Login;
