import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 68.5rem) {
    > div:nth-of-type(1) {
      display: none;
    }
  }

  @media (max-width: 48.25rem) {
    > div:nth-of-type(2) {
      display: none;
    }
  }

  @media (max-width: 32.5rem) {
    > div:nth-of-type(3) {
      display: none;
    }
  }
`;
