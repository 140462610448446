import styled from 'styled-components';
import { default as ButtonGeneric } from '../../../../components/Button';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #2f78fe;
  padding: 20px;
  height: fit-content;

  .backBtnContainer {
    align-self: flex-start;
    position: relative;
    left: -15px;
    font-size: 30px;
  }

  @media (max-width: 710px) {
    .backBtnContainer {
      left: -10px;
    }
  }

  > img:nth-of-type(1) {
    position: absolute;
    left: 0px;
  }

  > img:nth-of-type(2) {
    position: absolute;
    right: 0px;
  }

  @media (max-width: 63.75rem) {
    > img {
      display: none;
    }
  }

  @media (max-width: 33.75rem) {
    height: fit-content;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div:nth-of-type(2) {
    margin-left: 20px;
    > p {
      font-family: 'Izmir-Medium';
      font-size: 2rem;
      color: #fff;

      max-width: 200px;
    }
  }

  @media (max-width: 440px) {
    > div:nth-of-type(2) {
      flex-direction: column;
    }
  }

  @media (max-width: 540px) {
    flex-direction: column;

    > div:nth-of-type(2) {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
`;

export const Button = styled(ButtonGeneric)`
  background-color: #06e406;
  border-radius: 20px;
  width: 180px;
  margin-top: 20px;
`;
