import React, { useState } from 'react';

import CreateListModal from '../../../../components/CreateListModal';

import { Container, AddButton } from './styles';

const ButtonCreateList = ({user, setIsModal}) => {
  const [showCreateListModal, setShowCreateListModal] = useState(false);

  function renderCreateListModal() {
    if (showCreateListModal) {
      return <CreateListModal setVisible={setShowCreateListModal} />;
    }
  }

  const checkUserLoggedCreateList= () => {
    if (user.accessToken) {
      setShowCreateListModal(true);
    } else {
      setIsModal(true);
    }
  };

  return (
    <Container>
      <div onClick={() => checkUserLoggedCreateList()}>
        <AddButton>+</AddButton>
        <span>Criar Lista</span>
      </div>
      {renderCreateListModal()}
    </Container>
  );
};

export default ButtonCreateList;
