import styled from 'styled-components';

export const Container = styled.div`
  width: 160px;

  .ant-select {
    font-family: 'Izmir-Medium';
    font-size: 1.6rem;
    color: #707070;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    outline: 0;
    width: 100%;
    background-color: #f6f6f6;
    border-radius: 6px;
    height: 40px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 19%);
  }

  .ant-select-selector {
    border: 0 !important;
    border-radius: 40px !important;
    box-shadow: 0 !important;
  }

  .ant-select-selection-item {
    margin-top: 5px;
  }

  .ant-select-arrow {
    color: #07e407;
    font-size: 1.6rem;
    width: 30px;
  }
`;
