import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import photoUserGeneric from '../../../../assets/sideMenu/photo-user.png';
import home from '../../../../assets/sideMenu/home.png';
import myLists from '../../../../assets/sideMenu/my-lists.png';
import addedLists from '../../../../assets/sideMenu/added-lists.png';
import favorites from '../../../../assets/sideMenu/favorites.png';
import cashback from '../../../../assets/sideMenu/cashback.png';
import cart from '../../../../assets/header/cart.png';
import UserNotLoggedModalWrap from '../../../../components/UserNotLoggedModalWrap';
import UserNotLoggedMessage from '../../../../components/UserNotLoggedModalWrap/UserNotLoggedMessage';

import {
  Container,
  Header,
  Options,
  Row,
  Divider,
  LinkPage,
  ProfilePhoto,
} from './styles';

const SideMenu = ({ showSideMenu, setShowSideMenu = { setShowSideMenu } }) => {
  const headerRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.User);
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (!headerRef.current.contains(e.target)) {
      setShowSideMenu(false);
      return;
    }
  };

  const renderUserName = () => {
    if (user.username) {
      return <Link to="/perfil">Olá, {user.username}</Link>;
    }
    return <p>Olá</p>;
  };

  const renderAvatarImg = () => {
    if (user.avatar_url) {
      return <ProfilePhoto src={user.avatar_url} alt="Foto de perfil" />;
    }
  };

  const renderMenuToLoginOrSignUp = () => {
    if (
      !user.accessToken.trim() ||
      !user.refreshToken.trim() ||
      !user.username.trim()
    ) {
      return (
        <p>
          <LinkPage to="login">Entre</LinkPage> ou{' '}
          <LinkPage to="criar-conta">cadastre-se</LinkPage>
        </p>
      );
    }
  };

  const renderButtonExit = () => {
    if (
      user.accessToken.trim() ||
      user.refreshToken.trim() ||
      user.username.trim()
    ) {
      return (
        <>
          <Divider />
          <Row to="/login">
            <div onClick={() => dispatch({ type: 'listz-web/user/logout' })}>
              Sair
            </div>
          </Row>
        </>
      );
    }
  };

  function checkUserLoggedModal() {
    if (isModal) {
      return (
        <UserNotLoggedModalWrap toggleModal={() => setIsModal(false)}>
          <UserNotLoggedMessage />
        </UserNotLoggedModalWrap>
      );
    }
  }

  return (
    <Container showSideMenu={showSideMenu} ref={headerRef}>
      <Header>
        <div>
          {renderAvatarImg()}
          <div>
            {renderUserName()}
            {renderMenuToLoginOrSignUp()}
          </div>
        </div>
      </Header>
      <Options>
        <Row to="/">
          <img src={home} alt="Tela Inicial" />
          <div>Ínicio</div>
        </Row>
        <Divider />
        <Row to="/minhas-listas">
          <img src={myLists} alt="Suas Listas" />
          <div>Suas listas</div>
        </Row>
        <Row to="/minhas-listas">
          <img src={addedLists} alt="Listas adicionadas" />
          <div>Listas adicionadas</div>
        </Row>
        {user.accessToken ? (
          <Row to="/favoritos">
            <img src={favorites} alt="Favoritos" />
            <div>Favoritos</div>
          </Row>
        ) : (
          <Row onClick={() => setIsModal(true)}>
            <img src={favorites} alt="Favoritos" />
            <div>Favoritos</div>
          </Row>
        )}
        <Row to="/carrinho">
          <img src={cart} alt="Carrinho" />
          <div>Carrinho</div>
        </Row>
        <Divider />
        <Row to="/cashback">
          <img src={cashback} alt="Cashback" />
          <div>Cashback</div>
        </Row>
        {/* <Row to="/offers">
          <img src={offers} alt="Ofertas" />
          <p>Ofertas</p>
        </Row>
        <Row to="/notifications">
          <img src={notifications} alt="Notificações" />
          <p>Notificações</p>
        </Row> */}
        {renderButtonExit()}
        {checkUserLoggedModal()}
      </Options>
    </Container>
  );
};

export default SideMenu;
