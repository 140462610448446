import styled from 'styled-components';

export const Container = styled.div`
  > div {
    justify-content: space-between;
  }
`;

export const Informations = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > span:nth-of-type(1) {
    display: block;
    font-family: 'Izmir-Medium';
    font-size: 2.5rem;
    color: #4a4747;
  }

  > span:nth-of-type(2) {
    font-family: 'Poppins-Regular';
    font-size: 1.5rem;
    color: #4a4747;
  }

  > div {
    align-items: center;
    > span {
      font-family: 'Poppins-Regular';
      font-size: 2rem;
      color: #005aff;
      margin: 8px 0px;
      margin-right: 8px;
    }

    > img {
      height: 14px;
    }
  }

  > button {
    width: 240px;
  }

  @media (max-width: 32.5rem) {
    > button {
      width: 140px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  margin: 20px 0px;

  span {
    cursor: pointer;
  }
`;
