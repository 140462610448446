import styled from 'styled-components';
import { FaHeart } from 'react-icons/fa';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 40px 0px;

  @media (max-width: 680px) {
    flex-direction: column-reverse;
  }
`;

export const Clothe = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 260px;
  width: 210px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 12px 0px lightgrey;

  > img {
    /* position: absolute;
    height: 160px;
    top: 60px;
    left: 20px; */
    cursor: pointer;
  }

  @media (max-width: 36.875rem) {
    /* height: 250px;
    width: 180px;
    > img {
      height: 220px;
      left: -30px;
    } */
  }

  @media (max-width: 32.5rem) {
    height: 240px;
    /* width: 170px; */
    /* > img {
      height: 200px;
      left: -20px;
    } */
  }

  @media (max-width: 29.75rem) {
    /* height: 220px; */
    /* width: 160px; */
    /* > img {
      height: 180px;
      left: -20px;
    } */
  }

  @media (max-width: 26.875rem) {
    /* width: 160px; */
    /* > img {
      height: 170px;
      top: 60px;
      left: -20px;
    } */
  }

  @media (max-width: 23.75rem) {
    /* width: 140px; */
    /* > img {
      height: 160px;
      top: 60px;
      left: -20px;
    } */
  }
`;

export const FavoriteContainer = styled.div`
  > svg {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 5rem;
  }
`;

export const AddButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -10px;
  right: -10px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: #b1b1b1;

  > p {
    color: #fff;
    font-size: 8rem;
  }

  @media (max-width: 29.75rem) {
    height: 60px;
    width: 60px;
    bottom: -8px;
    right: 0px;
  }
`;

export const Informations = styled.div`
  width: 440px;
  margin-right: 40px;

  > p:nth-of-type(1) {
    font-family: 'Izmir-Bold';
    color: #1e6dff;
    font-size: 3.4rem;
    cursor: pointer;
  }

  > p:nth-of-type(2) {
    font-family: 'Izmir-Bold';
    color: #000000;
    font-size: 2.4rem;
  }

  > p:nth-of-type(3) {
    font-family: 'Izmir-Regular';
    color: #000000;
    font-size: 2.4rem;
  }

  @media (max-width: 680px) {
    > p:nth-of-type(1) {
      text-align: center;
      margin-top: 20px;
    }
  }

  @media (max-width: 46.625rem) {
    width: 100%;
    margin-right: 0px;
  }

  /* @media (max-width: 41.875rem) {
    width: 320px;
  }

  @media (max-width: 36.875rem) {
    width: 290px;
  }

  @media (max-width: 32.5rem) {
    width: 240px;
  } */

  @media (max-width: 29.75rem) {
    /* width: 200px; */

    > p:nth-of-type(1) {
      font-size: 3.2rem;
    }

    > p:nth-of-type(2) {
      font-size: 2.2rem;
    }

    > p:nth-of-type(3) {
      font-size: 2.2rem;
    }
  }

  /* @media (max-width: 26.875rem) {
    width: 180px;
  } */
`;

export const Price = styled.div`
  margin-bottom: 20px;

  > label {
    font-family: 'Poppins-Regular';
    color: #807f7f;
    font-size: 1.8rem;
  }

  > p {
    font-family: 'Izmir-Bold';
    color: #4a4747;
    font-size: 3.6rem;
  }

  @media (max-width: 680px) {
    text-align: center;
  }

  @media (max-width: 29.75rem) {
    margin-top: 20px;
  }
`;

export const Row = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;

  > div:nth-of-type(2) {
    margin-left: 40px;
  }

  @media (max-width: 46.625rem) {
    > div:nth-of-type(2) {
      margin-left: 16px;
    }
  }

  @media (max-width: 32.5rem) {
    margin-top: 20px;
    > div:nth-of-type(2) {
      margin-left: 0px;
    }
  }

  @media (max-width: 29.75rem) {
    flex-direction: column-reverse;

    > div:nth-of-type(2) {
      display: flex;
    }
  }
`;

export const Column = styled.div`
  > div:nth-of-type(2) {
    margin-top: 20px;
  }

  > button {
    margin-top: 40px;
    width: 250px;
  }

  > button:nth-of-type(2) {
    margin-top: 20px;
  }

  @media (max-width: 680px) {
    margin: 0 auto;
    margin-bottom: 40px;
  }

  @media (max-width: 41.875rem) {
    > button {
      width: 140px;
    }
  }

  @media (max-width: 29.75rem) {
    > div:nth-of-type(2) {
      margin-top: 0px;
      margin-left: 10px;
    }

    > button {
      margin-top: 20px;
      width: 140px;
    }
  }
`;


export const Image = styled.img`
  width: 200px;
  height: auto;
`

