import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import api from '../../services/api';

import { useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Details from './components/Details';
import Item from './components/Item';
import Suggestions from '../../components/Suggestions';
import Footer from '../../components/Footer';
import LoadModal from '../../components/LoadModal';

import { Container, Content } from './styles';

const ListDetails = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.User);
  const [userList, setUserList] = useState([]);
  const [listDetails, setListDetails] = useState({ products: [] });
  const [userHasAdded, setUserHasAdded] = useState(false);
  const [showLoadModal, setShowLoadModal] = useState(false);

  async function fetchList() {
    setShowLoadModal(true);
    await api
      .get(`/lists/${id}`)
      .then((res) => {
        setListDetails(res.data);
        setShowLoadModal(false);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function fetchUserList() {
    if (user.id) {
      await api
        .get(`/users/${user.id}/lists`)
        .then((res) => {
          setShowLoadModal(false);
          setUserList(res.data.lists);

          if (res.data.lists.length) {
            const hasAdded = res.data.lists.some((each) => each.id === id);
            setUserHasAdded(hasAdded);
          }
        })
        .catch((error) => {
          setShowLoadModal(false);
          handleError(error);
        });
    }
  }

  async function handleRemoveProduct(listId, productId) {
    await api
      .delete(`lists/${listId}/products/${productId}`)
      .then(() => {
        fetchRebuildList();
      })
      .catch((error) => handleError(error));
  }

  const handleError = async (error) => {
    let message;
    if (error.response?.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    setShowLoadModal(false);
    toast.error(message);
  };

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal />;
    }
  };

  function fetchRebuildList() {
    fetchList();
    fetchUserList();
  }

  useEffect(() => {
    fetchList();
    fetchUserList();
  }, [id]);

  const renderProducts = () => {
    const products = listDetails.products.map((item) => (
      <Item
        listDetails={listDetails}
        data={item}
        userHasAdded={userHasAdded}
        listId={id}
        fetchRebuildList={fetchRebuildList}
        handleRemoveProduct={handleRemoveProduct}
      />
    ));

    return products;
  };

  return (
    <Container>
      <Header />
      <Details fetchList={fetchList} data={listDetails} />
      <Content>{renderProducts()}</Content>
      {/* <Suggestions showText={false} /> */}
      <Footer />
      {renderLoadModal()}
    </Container>
  );
};

export default ListDetails;
