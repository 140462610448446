import React from 'react';
import CardInformation from '../../CardInformation';

import offer from '../../../assets/cardInformation/offer.png';
import wallet from '../../../assets/cardInformation/wallet.png';
import notLoggedIcon from '../../../assets/notLogged/notLoggedIcon.png';
import buttonIcon from '../../../assets/notLogged/buttonIcon.png';
import { useHistory } from 'react-router-dom';

import { Modal, Header, CardInformationContainer, ButtonContainer, Button } from './styles';

const UserNotLoggedMessage = () => {
  const history = useHistory();

  return (
    <Modal>
      <Header>
        <img src={notLoggedIcon} />
        <div>
          Ops! Você ainda
          não é cadastrado
        </div>
      </Header>
      <section>
        <div>Cadastre-se agora para acessar</div>
        <div>as Ofertas especiais e também</div>
        <div>receber Cashback!</div>
      </section>
      <CardInformationContainer>
        <CardInformation
          containerStyle={{
            backgroundColor: '#E2ECFF',
            textAlign: 'center',
            justifyContent: 'center',
          }}
          icon={offer}
          title="Ofertas especiais"
          description="Tenha aceso a ofertas especiais e produtos selecionados com os melhores preços."
        />
        <CardInformation
          containerStyle={{
            marginLeft: 20,
            backgroundColor: '#E0FFE0',
            textAlign: 'center',
            justifyContent: 'center',
          }}
          icon={wallet}
          title="CashBack"
          description="Compre e receba uma parte do seu dinheiro de volta."
        />
      </CardInformationContainer>
      <ButtonContainer>
        <img src={buttonIcon} />
        <Button onClick={() => history.push('/criar-conta')}>
          Cadastre-se agora!
        </Button>
      </ButtonContainer>
      <ButtonContainer>
        <img src={buttonIcon} />
        <Button onClick={() => history.push('/login')}>
          Faça o login
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

export default UserNotLoggedMessage;
