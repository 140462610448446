import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import api from '../../services/api';

import Header from '../../components/Header';
import Search from './components/Search';
import Lists from './components/Lists';
import Footer from '../../components/Footer';

import {
  Container,
  Content,
  MylistsContainer,
  ListsContent,
  ListOption,
} from './styles';

const MyLists = () => {
  const user = useSelector((state) => state.User);

  const [listsStatus, setListsStatus] = useState('added');
  const [lists, setLists] = useState([]);

  async function fetchLists() {
    let type;

    if (listsStatus === 'created') {
      type = 'lists?onlyCreated=true';
    } else {
      type = 'lists?onlyAdded=true';
    }

    await api
      .get(`/users/${user.id}/${type}`)
      .then((res) => {
        setLists(res.data.lists);
      })
      .catch((error) => handleError(error));
  }

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }
  };

  useEffect(() => {
    fetchLists();
  }, []);

  useEffect(() => {
    fetchLists();
  }, [listsStatus]);

  const renderDescription = () => {
    if (lists.length === 0) {
      if (listsStatus === 'created') {
        return <p>Você não possui nenhuma lista criada</p>;
      }
      return <p>Você não possui nenhuma lista adicionada</p>;
    } else {
      if (listsStatus === 'created') {
        return (
          <p>
            Aqui estão <br /> as Listas criadas por você.
          </p>
        );
      }
      return (
        <p>
          Aqui estão <br /> as Listas adicionadas por você.
        </p>
      );
    }
  };

  return (
    <Container>
      <Header />
      <MylistsContainer>
        <ListsContent>
          <div>
            <p>Minhas Listas</p>
            <div className="listOptionContainer">
              <ListOption onClick={() => setListsStatus('added')}>
                Listas adicionadas
              </ListOption>
              <ListOption onClick={() => setListsStatus('created')}>
                Listas criadas
              </ListOption>
            </div>
          </div>
        </ListsContent>
      </MylistsContainer>
      <Content>
        {renderDescription()}
        <Lists data={lists} />
      </Content>
      <Footer />
    </Container>
  );
};

export default MyLists;
