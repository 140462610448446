import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  background-color: #fff;

  @media (max-width: 26.5rem) {
    padding: 20px 10px;
  }
`;
