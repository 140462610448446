import styled from 'styled-components';

export const Container = styled.div`
  > p:nth-of-type(1) {
    font-family: 'Izmir-Regular';
    font-size: 2.3rem;
    color: #4a4747;

    > span {
      font-family: 'Izmir-Bold';
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  height: 50px;
  width: fit-content;
  border-color: #1e6dff;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  margin: 4px;

  img {
    height: 48px;
  }
`;
