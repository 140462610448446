import { useState } from 'react';
import searchImg from '../../../../assets/header/search.png';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import api from '../../../../services/api';
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Container, Image, TextInput, ContainerIcon } from './styles';

import './spinner.css';

const Input = ({ containerStyle, containerIconStyle }) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  async function handleSubmitSearch() {
    if (search === '') {
      return;
    }

    setLoading(true);

    await api
      .get('/search/lists', { params: { page: 1, q: search } })
      .then((res) => {
        dispatch({
          type: 'listz-web/user/get-user-search',
          payload: [search, res.data],
        });
        setLoading(false);
        history.push('/pesquisa');
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response?.data?.message || error.toString());
      });
  }

  function handleSearchEnter(e) {
    if (e.key === 'Enter') {
      handleSubmitSearch();
    }
  }

  return (
    <>
      <Container style={containerStyle}>
        <ContainerIcon style={containerIconStyle} onClick={handleSubmitSearch}>
          {!loading ? (
            <Image src={searchImg} alt="Lupa" />
          ) : (
            <FaSpinner className="spinner" size="22" color="#fff" />
          )}
        </ContainerIcon>
        <TextInput
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={handleSearchEnter}
          placeholder=""
          type="text"
        />
      </Container>
    </>
  );
};

export default Input;
