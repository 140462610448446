import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 40px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px lightgrey;
  text-align: center;
  border-radius: 20px;
  min-width: 520px;

  > div:nth-of-type(1) {
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 10;
  }

  > span {
    margin: 10px 0px;
    color: #2f78fe;
  }

  > span:nth-of-type(1) {
    font-family: 'Izmir-Bold';
    font-size: 2.6rem;
  }

  > span:nth-of-type(2) {
    font-family: 'Izmir-Bold';
    font-size: 2.6rem;
  }

  > span:nth-of-type(3) {
    font-family: 'Izmir-Regular';
    font-size: 2.4rem;
  }

  @media (max-width: 560px) {
    min-width: 94%;
  }
`;

export const OkButton = styled.button`
  font-family: 'Aller-Bold';
  font-size: 1.8rem;
  color: #fff;
  background-color: #2f78fe;
  border-radius: 10px;
  height: 60px;
  width: 60px;
  border: 0;
  outline: 0;
  margin-left: auto;
  cursor: pointer;
`;
