import styled from 'styled-components';

export const Container = styled.div`
  width: 180px;

  .ant-select {
    border-radius: 10px;
    height: 50px;
    background-color: #f6f6f6;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    outline: 0;
    font-family: 'Izmir-Regular';
    width: 100%;
    font-size: 2rem;
    color: #c5c5c5;
    background-color: #f6f6f6;
  }

  .ant-select-selector {
    border: 0 !important;
    border-radius: 40px !important;
    box-shadow: 0 !important;
  }

  .ant-select-selection-item {
    margin-top: 10px;
  }

  .ant-select-arrow {
    color: #07e407;
    font-size: 1.6rem;
    width: 30px;
  }

  .ant-select-selection-placeholder {
    margin-top: 10px;
  }
`;
