import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #2f78fe;
  padding: 20px;

  > img {
    position: absolute;
    right: 30px;
    margin-top: -8px;
    height: 180px;
  }

  @media (max-width: 68.75rem) {
    > img {
      display: none;
    }
  }
`;

export const Content = styled.div`
  max-width: 1536px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 11vh;
  margin-right: 10vw;
  min-width: 220px;

  > img {
    height: 60px;
  }

  @media (max-width: 68.75rem) {
    margin-right: 0vw;
  }

  @media (max-width: 56.25rem) {
    margin-top: 6vh;
  }

  @media (max-width: 46.25rem) {
    min-width: 180px;
    margin-top: 2vh;

    > img {
      height: 40px;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3vh;
  width: 240px;

  > p {
    color: #fff;
  }

  > p:nth-of-type(1) {
    font-family: 'Izmir-Black';
    font-size: 2.8rem;
    cursor: pointer;
  }

  > p:nth-of-type(2) {
    font-family: 'Izmir-Regular';
    font-size: 2.4rem;
  }

  @media (max-width: 68.75rem) {
    width: 190px;
  }

  @media (max-width: 56.25rem) {
    width: 160px;
    margin-top: 1.2vh;
    > p:nth-of-type(1) {
      font-size: 2.4rem;
    }

    > p:nth-of-type(2) {
      font-size: 2rem;
    }
  }

  @media (max-width: 50rem) {
    min-width: 140px;
    margin-top: 1.2vh;
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2vw;
  width: 150px;
  cursor: pointer;

  p {
    font-family: 'Izmir-Regular';
    color: #fff;
    font-size: 2.4rem;
  }
`;
