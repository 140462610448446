import ListCardVertical from '../ListCardVertical';

import { Container } from './styles';

const MyFavoriteProducts = ({ data }) => {
  return (
    <Container>
      <ListCardVertical data={data} />
    </Container>
  );
};

export default MyFavoriteProducts;
