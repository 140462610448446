import { useHistory } from 'react-router-dom';

import { Container, List, Item } from './styles';

const Size = ({
  productInfo,
  setChosenSize,
  chosenSize,
  sizesAvailable,
  externalIdAvailable,
}) => {
  const history = useHistory();

  const handleClickItem = (item) => {
    history.push(`/produto/${item}`);
  };

  const renderListSize = () => {
    if (sizesAvailable.length > 0) {
      let listSizeItens = [];

      for (let size of sizesAvailable) {
        const index = sizesAvailable.indexOf(size);

        listSizeItens.push(
          <Item onClick={() => handleClickItem(externalIdAvailable[index])}>
            {size}
          </Item>,
        );
      }

      return <List>{listSizeItens}</List>;
    }
  };

  return (
    chosenSize !== '' && (
      <Container>
        <p>
          <span>Tamanho selecionado: {chosenSize}</span>
        </p>
        {renderListSize()}
      </Container>
    )
  );
};

export default Size;
