import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';

import './carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Card from './Card';
import ButtonCreateList from './ButtonCreateList';
import CreateListModal from '../../../../components/CreateListModal';

import logoTransparent from '../../../../assets/cart/logo-transparent.png';

import { Container, ContainerNotFound, Content } from './styles';
import { FaLessThanEqual } from 'react-icons/fa';

const sliderSettings = {
  className: 'center',
  centerMode: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  speed: 500,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
      },
    },

    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

const Lists = () => {
  const [showCreateListModal, setShowCreateListModal] = useState(false);

  const searchResults = useSelector((state) => state.Search);
  const searchList = searchResults.search[1]?.results;

  const renderListCards = () => {
    let cards = searchList?.map((item) => (
      <Card data={item} searchList={searchList} />
    ));

    if (searchResults.search[1]?.results.length <= 3) {
      cards = [...cards, ...cards, ...cards, ...cards];
    }

    return cards;
  };

  const renderCreateListModal = () => {
    if (showCreateListModal) {
      return <CreateListModal setVisible={setShowCreateListModal} />;
    }
  };

  return (
    <>
      {searchList?.length !== 0 ? (
        <Container>
          <img src={logoTransparent} alt="Logo transparent do Listz" />
          <Content>
            <p>Listas feitas para você</p>
            <Slider {...sliderSettings}>{renderListCards()}</Slider>
            <ButtonCreateList onClick={() => setShowCreateListModal(true)} />
          </Content>
          <img src={logoTransparent} alt="Logo transparent do Listz" />
          {renderCreateListModal()}
        </Container>
      ) : (
        <ContainerNotFound>
          <img src={logoTransparent} alt="Logo transparent do Listz" />
          <span>
            <h1>Nenhuma lista encontrada</h1>
          </span>
          <img src={logoTransparent} alt="Logo transparent do Listz" />
        </ContainerNotFound>
      )}
    </>
  );
};

export default Lists;
