import React, { useState } from 'react';

import CreateListModal from '../../../../components/CreateListModal';

import logoTransparent from '../../../../assets/cart/logo-transparent.png';
import cartIcon from '../../../../assets/cart/cart-icon.png';

import { Container, Content, AddButton } from './styles';

const Title = () => {
  const [showCreateListModal, setShowCreateListModal] = useState(false);

  function renderCreateListModal() {
    if (showCreateListModal) {
      return <CreateListModal setVisible={setShowCreateListModal} />;
    }
  }

  return (
    <Container>
      <img src={logoTransparent} alt="Logo transparent do Listz" />
      <Content>
        <div onClick={() => setShowCreateListModal(true)}>
          <AddButton>+</AddButton>
          <p>Adicionar nova lista</p>
        </div>
      </Content>
      <img src={logoTransparent} alt="Logo transparent do Listz" />
      {renderCreateListModal()}
    </Container>
  );
};

export default Title;
