import styled from 'styled-components';

export const Container = styled.div``;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 600px;

  @media (max-width: 40rem) {
    width: 450px;
  }

  @media (max-width: 30rem) {
    width: 340px;
  }

  @media (max-width: 23.75rem) {
    width: 300px;
  }
`;

export const Button = styled.button`
  background-color: #c5c5c5;
  width: 180px;
  height: 60px;
  border-radius: 10px;
  font-family: 'Izmir-Bold';
  font-size: 2.5rem;
  color: #fff;
  border: 0;
  margin-right: 20px;

  @media (max-width: 40rem) {
    width: 140px;
    margin-top: 20px;
    margin-right: 0px;
  }

  @media (max-width: 30rem) {
    width: 100px;
  }

  @media (max-width: 23.75rem) {
    width: 80px;
  }
`;
