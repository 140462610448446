import Header from '../../components/Header';
import Footer from '../../components/Footer';

import backgroundImg from '../../assets/contact/background-img.png';
import phone from '../../assets/contact/phone.png';
import email from '../../assets/contact/email.png';
import logoTransparent from '../../assets/about/logo-transparent.png';

import { Container, Content, Row, Column, TitleContainer } from './styles';

const Contact = () => {
  return (
    <Container>
      <Header />
      <Content>
        <TitleContainer>
          <img src={logoTransparent} alt="Listz" />
          <div>
            <h1>Fale com a gente</h1>
          </div>
          <img src={logoTransparent} alt="Listz" />
        </TitleContainer>
        <Row>
          <img src={backgroundImg} />
          <Column>
            <p>Se precisar pode contar com a gente!</p>
            <p>
              Estamos prontos para te ajudar a resolver qualquer problema, ou
              tirar suas dúvidas.
              <br />
              Queremos te ouvir! Fique à vontade para enviar sugestões, ou
              críticas.
            </p>
            {/* <div>
              <img src={phone} alt="Telefone" />
              <p>Telefone: (11) 9 9395-4401</p>
            </div> */}
            <div>
              <img src={email} alt="E-mail" />
              <p>E-mail: atendimento@listz.com.br</p>
            </div>
          </Column>
        </Row>
      </Content>
      <Footer />
    </Container>
  );
};

export default Contact;
