import { HashRouter } from 'react-router-dom';

import Route from './Route';

import Home from '../pages/Home';
import Login from '../pages/Login';
import SignUp from '../pages/SignUp';
import About from '../pages/About';
import Partners from '../pages/Partners';
import Contact from '../pages/Contact';
import Cart from '../pages/Cart';
import EditProfile from '../pages/EditProfile';
import MyLists from '../pages/MyLists';
import Favorites from '../pages/Favorites';
import Cashback from '../pages/Cashback';
import Product from '../pages/Product';
import Search from '../pages/Search';
//import Notifications from '../pages/Notifications';
import Offers from '../pages/Offers';
import ListDetails from '../pages/ListDetails';
import ChooseTheList from '../pages/ChooseTheList';
import ChangePassword from '../pages/ChangePassword';
import ChooseList from '../pages/ChooseList';
import TermsOfUseAndPrivacy from '../pages/TermsOfUseAndPrivacy';

const Routes = () => (
  <HashRouter>
    <Route component={Home} path="/" exact isPrivateAndPublic />
    <Route component={Login} path="/login" />
    <Route component={SignUp} path="/criar-conta" />
    <Route component={About} path="/sobre" isPrivateAndPublic />
    <Route component={Partners} path="/parceiros" isPrivateAndPublic />
    <Route component={Contact} path="/contato" isPrivateAndPublic />
    <Route component={Cart} path="/carrinho" isPrivateAndPublic />
    <Route component={EditProfile} path="/perfil" isPrivate />
    <Route component={MyLists} path="/minhas-listas" isPrivate />
    <Route component={Favorites} path="/favoritos" isPrivateAndPublic />
    <Route component={Cashback} path="/cashback" isPrivate />
    {/*<Route component={Notifications} path="/notifications" isPrivate />*/}
    <Route component={Offers} path="/Offers" isPrivateAndPublic />
    <Route component={Search} path="/pesquisa" isPrivateAndPublic />
    <Route component={Product} path="/produto/:id" isPrivateAndPublic />
    <Route component={ListDetails} path="/lista/:id" isPrivateAndPublic />
    <Route
      component={TermsOfUseAndPrivacy}
      path="/termos-de-uso-e-privacidade"
      isPrivateAndPublic
    />
    <Route
      component={ChooseTheList}
      path="/listas-da-categoria/:id"
      isPrivateAndPublic
    />
    <Route
      component={ChooseList}
      path="/escolha-uma-lista/:id"
      isPrivateAndPublic
    />
    <Route component={ChangePassword} path="/alterar-senha" />
  </HashRouter>
);

export default Routes;
