import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../services/api';

import Header from '../../components/Header';
import AddNewList from './components/AddNewList';
import ListOfLists from './components/ListOfLists';
import ButtonCreateList from './components/ButtonCreateList';
import Footer from '../../components/Footer';

import background1 from '../../assets/chooseTheList/background1.png';
import background2 from '../../assets/chooseTheList/background2.png';

import { Container, Content, Divider } from './styles';

const ChooseTheList = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.User);
  const [lists, setLists] = useState([]);

  async function fetchUserList() {
    await api
      .get(`/users/${user.id}/lists`)
      .then((res) => {
        setLists(res.data.lists);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  const handleError = async (error) => {
    let message;
    if (error.response?.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    toast.error(message);
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  return (
    <Container>
      <img src={background1} alt="Detalhe de fundo" />
      <Header />
      <AddNewList />
      <Content>
        <h1>Escolha uma lista sua para adicionar o produto</h1>
        <Divider />
        <ListOfLists data={lists} productExternalId={id} />
        <ButtonCreateList />
      </Content>
      <Footer />
      <img src={background2} alt="Detalhe de fundo" />
    </Container>
  );
};

export default ChooseTheList;
