import logoTransparent from '../../../../assets/cart/logo-transparent.png';
import cartIcon from '../../../../assets/cart/cart-icon.png';

import { Container, Content } from './styles';

const Title = () => {
  return (
    <Container>
      <img src={logoTransparent} alt="Logo transparent do Listz" />
      <Content>
        <img src={cartIcon} alt="Ícone do Carrinho" />
        <p>Meu Carrinho</p>
      </Content>
      <img src={logoTransparent} alt="Logo transparent do Listz" />
    </Container>
  );
};

export default Title;
