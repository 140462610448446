import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  height: 200px;
  width: 200px;

  box-shadow: 0px 0px 6px 0px lightgrey;

  img {
    height: 60px;
    margin: 0 auto;
  }

  p:nth-of-type(1) {
    font-family: Izmir-Bold;
    font-size: 2.2rem;
    margin-top: 10px;
  }

  p:nth-of-type(2) {
    font-family: Izmir-Regular;
    font-size: 1.6rem;
    margin-top: 4px;
  }

  @media (max-width: 33.75rem) {
    height: 260px;
    width: 200px;
  }

  @media (max-width: 29.25rem) {
    padding: 10px;
    height: 240px;
    width: 170px;
  }

  @media (max-width: 29.25rem) {
    padding: 10px;
    height: 240px;
    width: 170px;
  }

  @media (max-width: 24.5rem) {
    padding: 8px;
    height: 220px;
    width: 150px;
  }
`;
