import Card from './Card';

import { Container } from './styles';

const ListCardVertical = ({ listProducts, setRemoveProductLoading, removeProductLoading }) => {
  const renderProducts = () => {
    return listProducts?.map((product) => <Card product={product} removeProductLoading={removeProductLoading} setRemoveProductLoading={setRemoveProductLoading} />);
  };

  return <Container>{renderProducts()}</Container>;
};

export default ListCardVertical;
