import styled from 'styled-components';

export const Container = styled.div`
  width: 220px;

  .ant-select {
    border-radius: 6px;
    background-color: #fff;
    width: 100%;
    height: 40px;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    outline: 0;
    font-family: 'Izmir-Regular';
    font-size: 1.6rem;
  }

  .ant-select-selector {
    border: 0 !important;
    border-radius: 40px !important;
    box-shadow: 0 !important;
  }

  .ant-select-selection-item {
    margin-top: 5px;
  }

  .ant-select-arrow {
    color: #07e407;
    font-size: 1.6rem;
    width: 30px;
  }
`;
